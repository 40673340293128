import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  Download,
  Edit,
  Key,
  MinusSquare,
  Plus,
  Search,
  Trash,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { filterType } from "../redux/actions/businessActions";
import { getCargos } from "../redux/actions/positionsChargesActions";
import {
  addMoreUsers,
  deleteUser,
  exportUsers,
  getUsers,
  restorePassword,
  setSelectedUser,
} from "../redux/actions/usersActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { PositionChargesState } from "../redux/reducers/positionChargesReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { IUsersState } from "../redux/reducers/usersReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const orderByUsers = ["Nombre", "Fecha", "Cargo"];

interface filterProps {
  onFilter: (value: filterType) => void;
  onOrder: React.Dispatch<any>;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteSubject, setDeleteSubject] = useState<any>(undefined);
  const [order, setOrder] = useState<any>("Fecha");

  const handleDeleteUser = (user: any) => {
    setOpenDelete(false);
    dispatch(deleteUser(user));
  };

  const handleGoToEditUser = (user: any) => {
    dispatch(setSelectedUser(user));
    history.push(`/editar/${user.id}`);
  };

  const handleGoCreateUser = () => {
    history.push(`/crear`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (e, newLimit) => {
        if (newLimit > users.length) {
          dispatch(getUsers(orderBy(), newLimit, filtros, user?.business));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (users.length < totalDocs && newPage > oldPage) {
          dispatch(addMoreUsers(orderBy(), limit, filtros, user?.business));
        }
      },
    });

  const orderBy = () => {
    const hasDate = filtros.find((ff) => ff.field === "FechaIngreso");
    let orderString = "FechaIngreso";
    if (order === "Nombre") {
      orderString = "Nombre_lower";
    } else if (order === "Cargo") {
      orderString = order;
    }
    const sort = hasDate ? "FechaIngreso" : orderString;

    return sort;
  };

  useEffect(() => {
    if (!user || !order) return;
    if (setPage && page) setPage(0);
    dispatch(getUsers(orderBy(), limit, filtros, user.business));
  }, [filtros, order]);

  const { isLoading, totalDocs, users } = useSelector<RootState, IUsersState>(
    (state) => state.usersReducer
  );
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  useEffect(() => {
    dispatch(getCargos(9999));
  }, []);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  const handleExport = () => {
    if (!user) return;
    dispatch(exportUsers(orderBy(), filtros, user.business));
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
            <Button
              startIcon={<Plus />}
              style={{ backgroundColor: "#42529b", color: "#fff" }}
              variant="contained"
              onClick={handleGoCreateUser}
            >
              Nuevo Usuario
            </Button>
          </>
        }
      />
      <Card mb={6}>
        {/* FILTRO INICIO */}
        <FilterSection onFilter={handleFilter} onOrder={setOrder} />
        {/* FILTRO FINAL */}

        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha Creación</TableCell>
                  <TableCell>Cargo</TableCell>
                  <TableCell>Area</TableCell>
                  {user?.business ? null : (
                    <TableCell>{`Empresa\nExterna`}</TableCell>
                  )}
                  <TableCell>Estado</TableCell>
                  <TableCell align="left">Progreso</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  users
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>
                            {data?.Nombre + " " + data?.Apellido}
                          </TableCell>
                          <TableCell>
                            {data?.FechaIngreso
                              ? timesStampFormattedsimple(
                                  data?.FechaIngreso.toDate()
                                )
                              : ""}
                          </TableCell>
                          <TableCell>{data?.Cargo}</TableCell>
                          <TableCell>{data?.Area}</TableCell>
                          {user?.business ? null : (
                            <TableCell>
                              {data?.EmpresaExterna ? "Si 🔸" : "No"}
                            </TableCell>
                          )}
                          {/* <TableCell>{data?.Estado || "No Aplica"}</TableCell> */}
                          <TableCell>
                            <Chip
                              style={{
                                backgroundColor: data?.Aprobado
                                  ? "#a8dab5"
                                  : "#ffe6cc",
                              }}
                              label={data?.Aprobado ? "Aprobado" : "No Aplica"}
                            />
                          </TableCell>
                          <TableCell align={data?.Progreso ? "left" : "center"}>
                            {data?.Progreso || " - "}
                          </TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="flex-start">
                              <Tooltip title="Cambiar contraseña">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    dispatch(restorePassword(data))
                                  }
                                >
                                  <Key />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  disabled={
                                    data?.EmpresaExterna
                                      ? user?.business
                                        ? false
                                        : user?.superUser
                                        ? false
                                        : true
                                      : false
                                  }
                                  onClick={() => {
                                    handleGoToEditUser(data);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>

                              {data?.AdminEmpresa && !user?.superUser ? null : (
                                <Tooltip title="Eliminar">
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setDeleteSubject(data);
                                      setOpenDelete(true);
                                    }}
                                  >
                                    <Trash color="red" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar al usuario?"
          onConfirm={() => handleDeleteUser(deleteSubject)}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter, onOrder }) => {
  const [openFilter, setOpenFilters] = useState(false);
  //
  const [nombre, setNombre] = useState("");
  const [cargo, setCargo] = useState<any>(null);
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "Nombre_lower",
        operator: "==",
        value: cleanString(nombre),
      });

    if (cargo) {
      filter.push({
        field: "Cargo",
        operator: "==",
        value: cargo.Nombre,
      });
    }

    if (dateStart)
      filter.push({
        field: "FechaIngreso",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaIngreso",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    setCargo(null);
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };

  const { Cargos } = useSelector<RootState, PositionChargesState>(
    (state) => state.positionChargesReducer
  );

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
        <Grid item>
          <Autocomplete
            id="orderby"
            size="small"
            options={orderByUsers}
            // defaultValue="Fecha"
            style={{ width: 170 }}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                onOrder(newValue);
              } else {
                onOrder("Fecha");
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Ordenar por" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="Cargo"
              value={cargo}
              options={Cargos}
              size="small"
              fullWidth
              getOptionLabel={(option) => option.Nombre}
              filterOptions={(option) =>
                option.filter((opt: any) => opt.Activado)
              }
              onChange={(event: any, newValue: any | null) => {
                setCargo(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Cargo" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Users = () => (
  <React.Fragment>
    <Helmet title="Usuarios" />
    <Typography variant="h3" gutterBottom display="inline">
      Usuarios Registrados
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Listado</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
