import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { BsnsModules } from "../models/business_modules";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/reducers/rootReducer";

interface Props {
  children: any;
}

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<Props> = ({ children }) => {
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const location = useLocation();

  localStorage.setItem("lastPath", location.pathname);
  if (!user) {
    return <Redirect to="/auth/sign-in" />;
  }

  if (user?.business) {
    const urlPath = String(location.pathname).toLowerCase();
    const modules = user?.businessModules || [];
    if (urlPath.includes("incidentes") || urlPath.includes("incidente")) {
      if (!modules.includes(BsnsModules.GI)) return <Redirect to="/areas" />;
    } else if (
      urlPath.includes("listadechequeo") ||
      urlPath.includes("listasdechequeo")
    ) {
      if (!modules.includes(BsnsModules.LCH)) return <Redirect to="/areas" />;
    } else if (
      urlPath.includes("analisisriesgo") ||
      urlPath.includes("analisisriesgos")
    ) {
      if (!modules.includes(BsnsModules.AR)) return <Redirect to="/areas" />;
    } else if (
      urlPath.includes("gestionactividades") ||
      urlPath.includes("gestionactividad")
    ) {
      if (!modules.includes(BsnsModules.GA)) return <Redirect to="/areas" />;
    } else if (urlPath.includes("gpsroutes") || urlPath.includes("gpsroute")) {
      if (!modules.includes(BsnsModules.GPS)) return <Redirect to="/areas" />;
    } else if (
      urlPath.includes("formularioscovid") ||
      urlPath.includes("formulariocovid")
    ) {
      if (!modules.includes(BsnsModules.FC)) return <Redirect to="/areas" />;
    }
  }

  return children;
};

export default AuthGuard;
