import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as rutUtils from "rut.js";
import styled from "styled-components/macro";
import * as yup from "yup";
import { getAreasAll } from "../../redux/actions/areasActions";
import { getCargos } from "../../redux/actions/positionsChargesActions";
import { createUser } from "../../redux/actions/usersActions";
import { AreasState } from "../../redux/reducers/areasReducer";
import { IUsersState } from "../../redux/reducers/usersReducer";
import { PositionChargesState } from "../../redux/reducers/positionChargesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { timesStampFormattedsimple } from "../../utils/utils";
import { UiReducerState } from "../../redux/reducers/uiReducer";
import { SnackState } from "../../models/snack-state";
import { User } from "react-feather";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type AvatarImage = File | undefined | { Url: string; Uri: string };

const ContentCard = () => {
  const [valCargo, setValCargo] = useState<any>(null);
  const [valueArea, setArea] = useState<any>(null);

  const { businessCargos: allCargos } = useSelector<
    RootState,
    PositionChargesState
  >((state) => state.positionChargesReducer);
  // const { businessCargos } = useSelector<RootState, PositionChargesState>(
  //   (state) => state.positionChargesReducer
  // );
  const { areasList } = useSelector<RootState, AreasState>(
    (state) => state.areasReducer
  );
  const { isLoading } = useSelector<RootState, IUsersState>(
    (state) => state.usersReducer
  );
  const {
    snack: { snackState },
  } = useSelector<RootState, UiReducerState>((state) => state.uiReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  const [waitingUser, setWaiting] = useState(false);

  const { handleSubmit, values, handleChange, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        Activo: true,
        Aprobado: false,
        Nombre: "",
        Apellido: "",
        Email: "",
        Contrasenia: "",
        Rut: "",
        Avatar: undefined as AvatarImage,
        // Edad: "",
        Telefono: "",
        FechaIngreso: new Date(),
        Area: "",
        AreaId: "",
        Cargo: "",
        TipoPermisos: "",
      } as any,
      onSubmit: (values) => {
        values.Telefono = "+569" + values.Telefono;
        dispatch(createUser({ ...values, EmpresaExterna: false }));
        //
        setWaiting(true);
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
        Apellido: yup.string().required("Apellido requerido"),
        Rut: yup
          .string()
          .min(11)
          .required("Rut es requerido")
          .test({
            name: "Rut",
            message: "Rut no válido",
            test: (value) => {
              if (!value) return false;
              return rutUtils.validate(value);
            },
          }),
        // Edad: yup
        //   .number()
        //   .positive("Debe ser edad válida")
        //   .required("Edad es requerida"),
        Telefono: yup
          .number()
          .required("Es requerido")
          .test("len", "Número no válido", (val: any) => {
            try {
              return val.toString().length === 8;
            } catch {
              return false;
            }
          })
          .required("Número es requerido"),
        Email: yup
          .string()
          .email("Email no válido")
          .required("Email requerido"),
        Contrasenia: yup.string().required("Contraseña requerida"),
        Area: yup.string().required("Área requerida"),
        Cargo: yup.string().required("Cargo requerido"),
        // TipoPermisos: yup.string().required("Permisos es requerido"),
      }),
    });

  useEffect(() => {
    if (!isLoading && waitingUser && snackState === SnackState.SUCCESS) {
      setWaiting(false);
      history.push(`/usuarios/listado`);
    }
  }, [waitingUser, isLoading, snackState]);

  useEffect(() => {
    // if (allCargos.length === 0) {
    dispatch(getCargos());
    // }
    dispatch(getAreasAll());
  }, []);

  const findNameIn = (arrList: any[], name: string) =>
    arrList.find((item) => item.Nombre === name);

  useEffect(() => {
    if (allCargos.length > 0) {
      if (!values.Cargo) return;
      const finded = findNameIn(allCargos, values.Cargo);
      if (finded && typeof finded !== "undefined") {
        setValCargo(finded);
      }
    }
  }, [values.Cargo, allCargos.length]);

  useEffect(() => {
    if (areasList.length > 0) {
      if (!values.Area && !values.AreaId) return;
      const finded = findNameIn(areasList, values.Area);
      if (finded && typeof finded !== "undefined") {
        setArea(finded);
      }
    }
  }, [values.Area, areasList.length]);

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const img = event.target.files[0];
      setFieldValue("Avatar", img);
      // if (event.target.files[0].size > 543718) {
      //   alert("Archivo demasiado pesado. Debe ser menor a 500kb");
      // } else {
      //   setImage(event.target.files[0]);
      // }
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container justify="center" spacing={3}>
            <Grid container item xs={12} sm={12} spacing={3} justify="center">
              <Grid item xs={12} sm={12}>
                <Box display="flex" justifyContent="center">
                  <input
                    style={{
                      display: "none",
                    }}
                    onChange={handleChangeImage}
                    id="contained-button-file"
                    accept="image/*"
                    type="file"
                  />
                  <Paper
                    elevation={0}
                    variant="outlined"
                    style={{
                      height: 140,
                      width: 140,
                      borderRadius: 140,
                      alignSelf: "center",
                      overflow: "hidden",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                      htmlFor="contained-button-file"
                    >
                      {values.Avatar ? (
                        <img
                          src={
                            values.Avatar instanceof File
                              ? URL.createObjectURL(values.Avatar)
                              : values.Avatar.Url
                          }
                          style={{
                            height: 140,
                            width: 140,
                            objectFit: "cover",
                          }}
                          alt={"UploadImage"}
                        />
                      ) : (
                        <>
                          <User size={30} />
                          Seleccionar Imagen
                        </>
                      )}
                    </label>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={8} spacing={3}>
              <Grid item sm={12}>
                <Typography variant="h6" gutterBottom>
                  Información personal
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Nombre"
                  label="Nombre"
                  value={values.Nombre}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Nombre && Boolean(errors.Nombre)}
                  helperText={touched.Nombre && errors.Nombre}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Apellido"
                  label="Apellido"
                  value={values.Apellido}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Apellido && Boolean(errors.Apellido)}
                  helperText={touched.Apellido && errors.Apellido}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <TextField
                  id="Edad"
                  label="Edad"
                  type="number"
                  value={values.Edad}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Edad && Boolean(errors.Edad)}
                  helperText={touched.Edad && errors.Edad}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Rut"
                  label="Rut"
                  value={values.Rut}
                  onChange={(e) => {
                    e.target.value = rutUtils.format(e.target.value);
                    handleChange(e);
                  }}
                  variant="outlined"
                  fullWidth
                  error={touched.Rut && Boolean(errors.Rut)}
                  helperText={touched.Rut && errors.Rut}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Telefono"
                  label="Teléfono"
                  value={values.Telefono}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Telefono && Boolean(errors.Telefono)}
                  helperText={touched.Telefono && errors.Telefono}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+569</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6" gutterBottom>
                  Datos cuenta
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="Email"
                  label="Email"
                  type="email"
                  value={values.Email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Email && Boolean(errors.Email)}
                  helperText={touched.Email && errors.Email}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="Contrasenia"
                  label="Contraseña"
                  name="Contrasenia"
                  value={values.Contrasenia}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  error={touched.Contrasenia && Boolean(errors.Contrasenia)}
                  helperText={touched.Contrasenia && errors.Contrasenia}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid item sm={12} container spacing={3}>
                <Grid item sm={12}>
                  <Typography variant="h6" gutterBottom>
                    Datos organizacionales
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="Area"
                    value={valueArea}
                    options={areasList}
                    getOptionLabel={(option) => option.Nombre}
                    onChange={(_: any, newValue: any | null) => {
                      if (newValue) {
                        setFieldValue("Area", newValue.Nombre);
                        setFieldValue("AreaId", newValue.id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Area"
                        variant="outlined"
                        error={touched.Area && Boolean(errors.Area)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="Cargo"
                    value={valCargo}
                    options={allCargos}
                    getOptionLabel={(option) => option.Nombre}
                    filterOptions={(option) =>
                      option.filter((opt: any) => opt.Activado)
                    }
                    onChange={(_: any, newValue: any | null) => {
                      if (newValue) {
                        setFieldValue("Cargo", newValue.Nombre);
                        setFieldValue(
                          "TipoPermisos",
                          newValue.TipoPermisos || "Usuario"
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cargo"
                        variant="outlined"
                        error={touched.Cargo && Boolean(errors.Cargo)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="FechaIngreso"
                    type="text"
                    label="Fecha Ingreso"
                    value={timesStampFormattedsimple(values.FechaIngreso)}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end" sm={12}>
            <Button variant="contained" type="submit" color="primary">
              Guardar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export const NewUser = React.memo(() => {
  return (
    <React.Fragment>
      <Helmet title="Nuevo Usuario" />
      <Typography variant="h3" gutterBottom display="inline">
        Nuevo Usuario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/usuarios/listado">
          Listado
        </Link>
        <Typography>Crear usuario</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
