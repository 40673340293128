import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface ICovidFormsState {
  state: FormState;
  covidForms: any[];
  error?: string;
  totalDocs: number;
  lastDoc: any;
  add: {
    error?: string;
    state: FormState;
  };
  edit: {
    state: FormState;
    selectedForm?: any;
    error?: string;
  };
  delete: {
    state: FormState;
  };
}

const initialState = {
  state: FormState.Initial,
  lastDoc: undefined,
  covidForms: [],
  add: {
    state: FormState.Initial,
  },
  delete: {
    state: FormState.Initial,
  },
  totalDocs: 0,
  edit: { selectedForm: [], state: FormState.Initial },
} as ICovidFormsState;

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case types.COVID_FORM_INITIAL:
      return initialState;
    case types.COVID_FORM_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        covidForms: action.payload.covidForms,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.COVID_FORM_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.COVID_FORM_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.COVID_FORM_ADD_IS_SUBMITTING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.COVID_FORM_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.COVID_FORM_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.COVID_FORM_REMOVE_SUBMITTING:
      return {
        ...state,
        delete: { state: FormState.Submitting },
        error: undefined,
      };
    case types.COVID_FORM_REMOVE_SUCCES:
      return {
        ...state,
        delete: { state: FormState.Success },
        covidForms: state.covidForms.filter((x) => x.id !== action.payload.id),
      };
    case types.COVID_FORM_REMOVE_FAILURE:
      return {
        ...state,
        delete: { state: FormState.Failure },
        error: action.payload,
      };
    case types.COVID_FORM_CLONE:
      return {
        ...state,
        edit: {
          selectedForm: action.payload,
        },
      };
    case types.COVID_FORM_ANSWERS_IS_SUBMITTING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.COVID_FORM_ANSWERS_SUCCESS:
      return {
        ...state,
        edit: {
          selectedForm: action.payload,
          state: FormState.Success,
        },
      };
    case types.COVID_FORM_ANSWERS_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export default reducer;
