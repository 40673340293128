import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyDsNkzBAowJmXcY7C1ynwfwIHiYlQJLpWI",
  authDomain: "biolantanidos.firebaseapp.com",
  projectId: "biolantanidos",
  storageBucket: "biolantanidos.appspot.com",
  messagingSenderId: "538636364015",
  appId: "1:538636364015:web:9121cc68880704f8eb131a",
  measurementId: "G-12E8CZMXN8",
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
