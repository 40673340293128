import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/reducers/rootReducer";

interface Props {
  data?: any;
  open: boolean;
  onClose: () => void;
  onAccept: (values: any) => void;
  title?: string;
  type?: string;
}

export const NewEditCharge: React.FC<Props> = React.memo(
  ({ data, open, onAccept, onClose, title = "", type = "new" }) => {
    const tipoPermisosOptions: any[] = [
      { Nombre: "Administración", Tipo: "Admin" },
      { Nombre: "Supervisión", Tipo: "Jefe" },
      { Nombre: "Terreno", Tipo: "Usuario" },
    ];

    const { user } = useSelector<RootState, IAuthState>(
      (state) => state.authReducer
    );

    const [valPermiso, setValPermiso] = useState<string | null>(null);

    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      setValues,

      resetForm,
    } = useFormik({
      initialValues: {
        Nombre: "",
        Descripcion: "",
        Activado: true,
        TipoPermisos: "Usuario",
      },
      onSubmit: (values) => {
        onAccept(values);
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
        Descripcion: yup.string().required("Descripción requerida"),
      }),
    });

    useEffect(() => {
      if (type === "edit") {
        if (data) {
          setValues({
            Nombre: data?.Nombre,
            Activado: data?.Activado,
            Descripcion: data?.Descripcion,
            TipoPermisos: data?.TipoPermisos,
          });
        }
      } else if (type === "new") {
        resetForm();
      }
    }, [data, type, resetForm, setValues]);

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              onClose();
            }
          }}
          aria-labelledby="add-and-update-dialog-title"
        >
          <DialogTitle id="add-and-update-dialog-title">
            {type === "new" ? "Añadir Cargo" : "Editar Cargo"}
          </DialogTitle>

          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={3} justify="space-between">
                <Grid item sm={12} xs={12}>
                  <TextField
                    autoFocus
                    id="Nombre"
                    label="Nombre"
                    type="text"
                    value={values?.Nombre}
                    name="Nombre"
                    onChange={handleChange}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    id="Descripcion"
                    label="Descripción"
                    type="text"
                    multiline
                    rows={4}
                    value={values?.Descripcion}
                    name="Descripcion"
                    onChange={handleChange}
                    error={touched.Descripcion && Boolean(errors.Descripcion)}
                    helperText={touched.Descripcion && errors.Descripcion}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="combo-box-permisos"
                    value={
                      values.TipoPermisos
                        ? tipoPermisosOptions.find(
                            (x) => x.Tipo === values?.TipoPermisos
                          )
                        : valPermiso
                    }
                    options={tipoPermisosOptions}
                    getOptionLabel={(option) => option.Nombre}
                    getOptionDisabled={(option) =>
                      user?.tipoUsuario !== "Admin"
                        ? option.Tipo === "Admin"
                        : false
                    }
                    onChange={(event: any, newValue: any | null) => {
                      setValPermiso(newValue);
                      if (newValue) {
                        values.TipoPermisos = newValue.Tipo;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Permisos"
                        variant="outlined"
                        error={
                          touched.TipoPermisos && Boolean(errors.TipoPermisos)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  {type === "edit" ? (
                    <Grid item sm={12} xs={12}>
                      <Switch
                        id="Activado"
                        checked={values.Activado}
                        onChange={handleChange}
                        color="primary"
                        name="Activado"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
);
