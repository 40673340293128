import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ArrowUpCircle, ChevronDown, Clipboard, FilePlus } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import * as rutUtils from "rut.js";
import styled from "styled-components/macro";
import * as yup from "yup";
import { FormState } from "../../models/form_state";
import { SnackState } from "../../models/snack-state";
import {
  getEmpresa,
  setBusiness,
  setUpdateState,
  updateEmpresa,
  updateEmpresaDocuments,
} from "../../redux/actions/businessActions";
import { openSnack } from "../../redux/actions/uiActions";
import { sendUserEmail } from "../../redux/actions/usersActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { BusinessState } from "../../redux/reducers/businessReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { cleanString } from "../../utils/utils";
import { a11yProps, TabPanel } from "../Users/DetailTabs";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCardInfo = ({ businessId }: any) => {
  const dispatch = useDispatch();

  const {
    update: { state },
    selected,
  } = useSelector<RootState, BusinessState>((state) => state.businessReducer);
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const [motivoRechazo, setMotivoRechazo] = useState("");
  const [modulos, setModulos] = useState<any>({
    GestionIncidentes: false,
    ListasChequeo: false,
    AnalisisRiesgos: false,
    GestionActividades: false,
    GPS: false,
    FormularioCovid: false,
  });

  const handleChangeModulo = (m: keyof typeof modulos, value: boolean) => {
    const newM = { ...modulos };
    newM[m] = value;
    setModulos(newM);
  };

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: {
      NombreEmpresa: "",
      RutEmpresa: "",
      EmailEmpresa: "",
      // MutualidadEmpresa: "",
      // TelefonoEmpresa: "",
      // RepresentanteLegal: {
      //   Rut: "",
      //   Telefono: "",
      //   Email: "",
      // },
      // AdministradorContrato: {
      //   Rut: "",
      //   Telefono: "",
      //   Email: "",
      // },
      Activo: false,
      password: "",
      verifyPassword: "",
      Nombre: "",
      Apellido: "",
      Rut: "",
      Email: "",
      Telefono: "",
    } as any,
    onSubmit: (values) => handleUpdate(values),
    validationSchema: yup.object({
      NombreEmpresa: yup.string().required("Nombre requerido"),
      RutEmpresa: yup
        .string()
        .min(11)
        .required("Rut es requerido")
        .test({
          name: "Rut",
          message: "Rut no válido",
          test: (value) => {
            if (!value) return false;
            return rutUtils.validate(value);
          },
        }),
      EmailEmpresa: yup
        .string()
        .email("Email no válido")
        .required("Email requerido"),
      // MutualidadEmpresa: yup.string().required("Mutualidad es requerida"),
      // TelefonoEmpresa: yup
      //   .number()
      //   .required("Es requerido")
      //   .test("len", "Número no válido", (val: any) => {
      //     try {
      //       return val.toString().length === 8;
      //     } catch {
      //       return false;
      //     }
      //   })
      //   .required("Número es requerido"),
      // RepresentanteLegal: yup
      //   .object()
      //   .shape({
      //     Rut: yup
      //       .string()
      //       .min(11)
      //       .required("Requerido!")
      //       .test({
      //         name: "Rut",
      //         message: "Rut no válido",
      //         test: (value) => {
      //           if (!value) return false;
      //           return rutUtils.validate(value);
      //         },
      //       }),
      //     Telefono: yup
      //       .number()
      //       .min(10000000)
      //       .max(99999999)
      //       .required("Número es requerido"),
      //     Email: yup
      //       .string()
      //       .email("Email no válido")
      //       .required("Email requerido"),
      //   })
      //   .required("Es requerido"),

      // AdministradorContrato: yup
      //   .object()
      //   .shape({
      //     Rut: yup
      //       .string()
      //       .min(11)
      //       .required("Requerido!")
      //       .test({
      //         name: "Rut",
      //         message: "Rut no válido",
      //         test: (value) => {
      //           if (!value) return false;
      //           return rutUtils.validate(value);
      //         },
      //       }),
      //     Telefono: yup
      //       .number()
      //       .min(10000000)
      //       .max(99999999)
      //       .required("Número es requerido"),
      //     Email: yup
      //       .string()
      //       .email("Email no válido")
      //       .required("Email requerido"),
      //   })
      //   .required("Es requerido"),

      Nombre: yup.string().required("Nombre requerido"),
      Apellido: yup.string().required("Apellido requerido"),
      Rut: yup
        .string()
        .min(11)
        .required("Rut es requerido")
        .test({
          name: "Rut",
          message: "Rut no válido",
          test: (value) => {
            if (!value) return false;
            return rutUtils.validate(value);
          },
        }),
      Telefono: yup
        .number()
        .required("Es requerido")
        .test("len", "Número no válido", (val: any) => {
          try {
            return val.toString().length === 8;
          } catch {
            return false;
          }
        })
        .required("Número es requerido"),
      Email: yup.string().email("Email no válido").required("Email requerido"),
      password: yup
        .string()
        // .required("Contraseña es requerida")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
          "Mínimo 7 caracteres. Debe contener una letra mayúscula y un número."
        ),
      // verifyPassword: yup
      //   .string()
      //   .min(0)
      //   .max(255)
      //   .oneOf([yup.ref("password"), null], "Contraseña no coincide")
      //    .required("Este campo es requerido"),
    }),
  });

  const handleChangeCheck = (newVal: boolean, field: string) =>
    setFieldValue(field, newVal);

  const handleReject = async () => {
    if (selected) {
      const { business, user: usuario } = selected;
      const form = {
        email: business.Email,
        mensaje: motivoRechazo,
      };
      // const form = {
      //   email: "ignaciok98@gmail.com",
      //   mensaje: motivoRechazo,
      // };
      setMotivoRechazo("");
      dispatch(sendUserEmail(form));
    }
  };

  const handleUpdate = (values: any) => {
    const userForm: any = {
      Nombre: values.Nombre,
      Nombre_lower: cleanString(values.Nombre),
      Apellido: values.Apellido,
      Apellido_lower: cleanString(values.Apellido),
      Rut: values.Rut,
      Email: values.Email,
      Telefono: Number(values.Telefono),
      // Activo: values.Activo,
      // Cargo: "Administrador",
    };
    if (values.password) {
      if (values.password !== values.verifyPassword) {
        setFieldError("verifyPassword", `Contraseña no coincide`);
        return;
      }
      userForm["password"] = values.password;
    }
    const activeModules: string[] = [];
    Object.keys(modulos).map((key: any) => {
      const value = modulos[key];
      if (value) activeModules.push(key);
    });
    const businessForm = {
      Activo: values.Activo,
      Nombre: values.NombreEmpresa,
      Nombre_lower: cleanString(values.NombreEmpresa),
      Rut: values.RutEmpresa,
      Email: values.EmailEmpresa,
      // Telefono: Number(values.TelefonoEmpresa),
      // Mutualidad: values.MutualidadEmpresa,
      // RepresentanteLegal: values.RepresentanteLegal,
      // AdministradorContrato: values.AdministradorContrato,
      Modulos: activeModules,
    };

    dispatch(updateEmpresa(businessForm, userForm, businessId));
  };

  useEffect(() => {
    if (selected) {
      const { business, user: usuario } = selected;
      setValues({
        ...values,
        NombreEmpresa: business.Nombre,
        RutEmpresa: business.Rut,
        EmailEmpresa: business.Email,
        // MutualidadEmpresa: business.Mutualidad,
        // TelefonoEmpresa: Number(business.Telefono),
        // RepresentanteLegal: business.RepresentanteLegal,
        // AdministradorContrato: business.AdministradorContrato,
        Activo: business.Activo,
        Nombre: usuario.Nombre,
        Apellido: usuario.Apellido,
        Rut: usuario.Rut,
        Email: usuario.Email,
        Telefono: Number(String(usuario.Telefono).replace("+569", "")),
      });
      const businessModules = business?.Modulos || [];
      Object.keys(modulos).map((key) => {
        if (businessModules.includes(key)) modulos[key] = true;
      });
      // setModulos(business?.Modulos || []);
    }
  }, [selected]);

  return (
    <CardContent>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid container item xs={12} sm={8} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Información
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                id="NombreEmpresa"
                label="Nombre"
                value={values.NombreEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.NombreEmpresa && Boolean(errors.NombreEmpresa)}
                helperText={touched.NombreEmpresa && errors.NombreEmpresa}
                disabled={!user?.superUser}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="RutEmpresa"
                label="Rut"
                value={values.RutEmpresa}
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                variant="outlined"
                fullWidth
                error={touched.RutEmpresa && Boolean(errors.RutEmpresa)}
                helperText={touched.RutEmpresa && errors.RutEmpresa}
                disabled={!user?.superUser}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="EmailEmpresa"
                label="Email"
                type="email"
                value={values.EmailEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.EmailEmpresa && Boolean(errors.EmailEmpresa)}
                helperText={touched.EmailEmpresa && errors.EmailEmpresa}
                disabled={!user?.superUser}
              />
            </Grid>
            {/* <Grid item xs={12} sm={5}>
              <TextField
                id="TelefonoEmpresa"
                label="Teléfono"
                value={values.TelefonoEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={
                  touched.TelefonoEmpresa && Boolean(errors.TelefonoEmpresa)
                }
                helperText={touched.TelefonoEmpresa && errors.TelefonoEmpresa}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+569</InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={12}>
              <TextField
                id="MutualidadEmpresa"
                label="Mutualidad"
                value={values.MutualidadEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={
                  touched.MutualidadEmpresa && Boolean(errors.MutualidadEmpresa)
                }
                helperText={
                  touched.MutualidadEmpresa && errors.MutualidadEmpresa
                }
              />
            </Grid> */}
            {/* <Paper
              variant="elevation"
              elevation={0}
              style={{
                backgroundColor: "rgba(211,211,211, 0.18)",
                width: "100%",
                height: "auto",
                margin: "auto",
                paddingTop: 10,
                paddingLeft: 5,
                paddingRight: 5,
                marginRight: -1,
                paddingBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="RepresentanteLegal.Rut"
                    label="Rut Representante legal"
                    value={values.RepresentanteLegal?.Rut || ""}
                    onChange={(e) => {
                      e.target.value = rutUtils.format(e.target.value);
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Rut: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Rut &&
                      Boolean((errors.RepresentanteLegal as any)?.Rut)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Rut) &&
                      "Rut no válido"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="RepresentanteLegal.Telefono"
                    label="Teléfono Representante legal"
                    value={values.RepresentanteLegal?.Telefono || ""}
                    onChange={(e) => {
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Telefono: Number(e.target.value),
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Telefono &&
                      Boolean((errors.RepresentanteLegal as any)?.Telefono)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Telefono) &&
                      "Teléfono no válido"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="RepresentanteLegal.Email"
                    label="Email Representante legal"
                    value={values.RepresentanteLegal?.Email || ""}
                    onChange={(e) => {
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Email: e.target.value,
                      });
                    }}
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Email &&
                      Boolean((errors.RepresentanteLegal as any)?.Email)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Email) &&
                      "Email no válido"
                    }
                  />
                </Grid>
              </Grid>
            </Paper> */}
            {/* <Paper
              variant="elevation"
              elevation={0}
              style={{
                backgroundColor: "rgba(211,211,211, 0.18)",
                width: "100%",
                height: "auto",
                margin: "auto",
                paddingTop: 10,
                paddingLeft: 5,
                paddingRight: 5,
                marginRight: -1,
                paddingBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="AdministradorContrato.Rut"
                    label="Rut Administrador Contrato"
                    value={values.AdministradorContrato?.Rut || ""}
                    onChange={(e) => {
                      e.target.value = rutUtils.format(e.target.value);
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Rut: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Rut &&
                      Boolean((errors.AdministradorContrato as any)?.Rut)
                    }
                    helperText={
                      Boolean((errors.AdministradorContrato as any)?.Rut) &&
                      "Rut no válido"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="AdministradorContrato.Telefono"
                    label="Teléfono Administrador Contrato"
                    value={values.AdministradorContrato?.Telefono || ""}
                    onChange={(e) => {
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Telefono: Number(e.target.value),
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Telefono &&
                      Boolean((errors.AdministradorContrato as any)?.Telefono)
                    }
                    helperText={
                      Boolean(
                        (errors.AdministradorContrato as any)?.Telefono
                      ) && "Teléfono no válido"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="AdministradorContrato.Email"
                    label="Email Administrador Contrato"
                    value={values.AdministradorContrato?.Email || ""}
                    onChange={(e) => {
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Email: e.target.value,
                      });
                    }}
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Email &&
                      Boolean((errors.AdministradorContrato as any)?.Email)
                    }
                    helperText={
                      Boolean((errors.AdministradorContrato as any)?.Email) &&
                      "Email no válido"
                    }
                  />
                </Grid>
              </Grid>
            </Paper> */}
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Usuario
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nombre"
                label="Nombre"
                value={values.Nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Nombre && Boolean(errors.Nombre)}
                helperText={touched.Nombre && errors.Nombre}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Apellido"
                label="Apellido"
                value={values.Apellido}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Apellido && Boolean(errors.Apellido)}
                helperText={touched.Apellido && errors.Apellido}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Rut"
                label="Rut"
                value={values.Rut}
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                variant="outlined"
                fullWidth
                error={touched.Rut && Boolean(errors.Rut)}
                helperText={touched.Rut && errors.Rut}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Telefono"
                label="Teléfono"
                value={values.Telefono}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Telefono && Boolean(errors.Telefono)}
                helperText={touched.Telefono && errors.Telefono}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+569</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="Email"
                label="Email"
                type="email"
                value={values.Email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Email && Boolean(errors.Email)}
                helperText={touched.Email && errors.Email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                label="Contraseña"
                value={values.password}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="verifyPassword"
                label="Verificar Contraseña"
                value={values.verifyPassword}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.verifyPassword && Boolean(errors.verifyPassword)}
                helperText={touched.verifyPassword && errors.verifyPassword}
              />
            </Grid>
          </Grid>
          {user?.tipoUsuario === "Admin" ? (
            <Grid item xs={12} sm={4}>
              <Grid item sm={12} container spacing={3}>
                <Grid item sm={12}>
                  <Typography variant="h6" gutterBottom>
                    Módulos
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.GestionIncidentes}
                        onChange={(_, newVal) =>
                          handleChangeModulo("GestionIncidentes", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Gestión de Incidentes"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.ListasChequeo}
                        onChange={(_, newVal) =>
                          handleChangeModulo("ListasChequeo", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Listas de Chequeo"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.AnalisisRiesgos}
                        onChange={(_, newVal) =>
                          handleChangeModulo("AnalisisRiesgos", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Análisis de Riesgos"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.GestionActividades}
                        onChange={(_, newVal) =>
                          handleChangeModulo("GestionActividades", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Gestión de Actividades"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.GPS}
                        onChange={(_, newVal) =>
                          handleChangeModulo("GPS", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="GPS"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!user?.superUser}
                        checked={modulos.FormularioCovid}
                        onChange={(_, newVal) =>
                          handleChangeModulo("FormularioCovid", newVal)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Formulario Covid"
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} container spacing={3}>
                <Grid item sm={12}>
                  <Typography variant="h6" gutterBottom>
                    Validación
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.Activo}
                        onChange={(_, newVal) =>
                          handleChangeCheck(newVal, "Activo")
                        }
                        disabled={!user?.superUser}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Aprobado  ( Activo )"
                  />
                </Grid>
                {user?.superUser ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleReject}
                        fullWidth
                      >
                        Rechazar (Se enviará notificación por correo).
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="motivo-rechazo"
                        label="Motivo Rechazo"
                        value={motivoRechazo}
                        onChange={(e) => setMotivoRechazo(e.target.value)}
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          sm={12}
          style={{ marginTop: 10 }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={state === FormState.Submitting}
          >
            Guardar
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};

const ContentCardDocs = ({ businessId }: any) => {
  const dispatch = useDispatch();

  const {
    selected: data,
    update: { state },
  } = useSelector<RootState, BusinessState>((state) => state.businessReducer);

  const { handleSubmit, values, setValues, setFieldValue } = useFormik({
    initialValues: {} as any,
    onSubmit: (values) => {
      const currentDocsCount = Object.keys(values).length;
      const completionState = currentDocsCount + "/" + documentsList.length;
      dispatch(updateEmpresaDocuments(businessId, values, completionState));
    },
    validationSchema: yup.object({}),
  });

  useEffect(() => {
    if (data && data.business) {
      const selected = data.business;
      if (
        selected?.Documentos &&
        typeof selected.Documentos === "object" &&
        Object.keys(selected.Documentos).length > 0
      ) {
        setValues({
          ...selected.Documentos,
        });
      }
    }
  }, [data, dispatch, setValues, businessId]);

  const handleChangeFieldValue = (
    docName: string,
    txtFieldName: string,
    newE: any
  ) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    let newValue: any = {};
    if (value) {
      newValue = { ...value };
    }
    newValue[`${txtFieldName}`] = newE;
    setFieldValue(`${cleanName}`, newValue);
  };

  const handleChangeFileDoc = (
    docName: string,
    docFieldName: string,
    newE: any,
    extension: string
  ) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    let newValue: any = {};
    if (value) {
      newValue = { ...value };
    }
    const filePath = newValue[`filePath`];
    if (filePath) {
      const newFilePath =
        "Empresas/" +
        businessId +
        "/Documentos/" +
        docFieldName +
        "." +
        extension;
      const oldFilePath = filePath;
      newValue[`filePath`] = newFilePath;
      newValue[`oldFilePath`] = oldFilePath;
      newValue[`file`] = newE;
    } else {
      const filePath =
        "Empresas/" +
        businessId +
        "/Documentos/" +
        docFieldName +
        "." +
        extension;
      newValue[`filePath`] = filePath;
      newValue[`file`] = newE;
    }
    setFieldValue(`${cleanName}`, newValue);
  };

  const textFieldValHandler = (docName: string, txtFieldName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    if (!value) return undefined;
    const fieldVal: any = value[`${txtFieldName}`];
    if (!fieldVal) return undefined;
    return fieldVal;
  };

  const handleTryDownload = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    if (!value || typeof value === "undefined") {
      openError();
      return;
    }
    if (!Object.keys(value).includes("url")) {
      openError();
      return;
    }
    const url = value[`url`];

    window.open(url, "_blank");
  };

  const validateFile = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");

    const value = values[`${cleanName}`];

    if (!value || typeof value === "undefined") return true;

    if (!Object.keys(value).includes("url")) return true;

    return false;
  };

  const fileSelected = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");

    const value = values[`${cleanName}`];

    if (!value || typeof value === "undefined") return false;

    if (Object.keys(value).includes("file")) return true;

    return false;
  };

  const openError = () =>
    dispatch(openSnack("No hay Archivo", SnackState.ERROR));

  const documentsList = [
    //  docName Always required
    //  docFieldName Always required
    {
      docName: "F30-1",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "F301",
    },
    {
      docName: "Certificado Mutual",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CertificadoMutual",
    },
    {
      docName: "Procedimientos",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "Procedimientos",
    },
    {
      docName: "Miper",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "Miper",
    },
    {
      docName: "Politica de Alcohol y Drogas",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "PoliticaAlcoholDrogas",
    },
    {
      docName: "Programa anual de Prevención de Riesgos",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "ProgAnualPrevRiesgos",
    },
    {
      docName: "Recepción de reglamento de empresas contratistas",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "RecepReglamentoEmpContratistas",
    },
    {
      docName: "Certificado de Accidentabilidad emitido por la Mutualidad",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CertAccidentabilidadMutual",
    },
    {
      docName: "Copia de RIOHS",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CopiaDeRIOHS",
    },
    {
      docName: "Copia jornada extraordinaria de trabajo",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CopiaJornadaExtTrabajo",
    },
    {
      docName: "Certificado de adhesión a Mutualidad",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CertificadoAdhesionMutualidad",
    },
    {
      docName: "Listado de Vehiculos y Equipos",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "ListadoVehiculosYEquipos",
    },
    {
      docName: "Documentos de los Vehiculos y Equipos",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "DocsDeVehiculosYEquipos",
    },
    {
      docName: "Razón Social",
      textFieldLabel: "Razón Social",
      textFieldName: "razonsocial",
      docFieldName: "razonsocial",
      hideFiles: true,
    },
    {
      docName: "Teléfono",
      textFieldLabel: "Teléfono",
      textFieldName: "telefono",
      docFieldName: "telefono",
      hideFiles: true,
    },
    {
      docName: "Dirección",
      textFieldLabel: "Dirección",
      textFieldName: "direccion",
      docFieldName: "direccion",
      hideFiles: true,
    },
    {
      docName: "Representante Legal",
      textFieldLabel: "Representante Legal",
      textFieldName: "representantelegal",
      docFieldName: "representantelegal",
      hideFiles: true,
    },
    {
      docName: "Rut Representante Legal",
      textFieldLabel: "Rut Representante Legal",
      textFieldName: "rutrepresentantelegal",
      docFieldName: "rutrepresentantelegal",
      hideFiles: true,
    },
    {
      docName: "Teléfono Representante Legal",
      textFieldLabel: "Teléfono Representante Legal",
      textFieldName: "telefonorepresentantelegal",
      docFieldName: "telefonorepresentantelegal",
      hideFiles: true,
    },
    {
      docName: "Email Representante Legal",
      textFieldLabel: "Email Representante Legal",
      textFieldName: "emailrepresentantelegal",
      docFieldName: "emailrepresentantelegal",
      hideFiles: true,
    },
    {
      docName: "Administrador de Contrato",
      textFieldLabel: "Administrador de Contrato",
      textFieldName: "administradordecontrato",
      docFieldName: "administradordecontrato",
      hideFiles: true,
    },
    {
      docName: "Rut Administrador de Contrato",
      textFieldLabel: "Rut Administrador de Contrato",
      textFieldName: "rutadministradordecontrato",
      docFieldName: "rutadministradordecontrato",
      hideFiles: true,
    },
    {
      docName: "Email Administrador de Contrato",
      textFieldLabel: "Email Administrador de Contrato",
      textFieldName: "emailadministradordecontrato",
      docFieldName: "emailadministradordecontrato",
      hideFiles: true,
    },
    {
      docName: "Teléfono Administrador de Contrato",
      textFieldLabel: "Teléfono Administrador de Contrato",
      textFieldName: "telefonoadministradordecontrato",
      docFieldName: "telefonoadministradordecontrato",
      hideFiles: true,
    },
    {
      docName: "Mutualidad",
      textFieldLabel: "Mutualidad",
      textFieldName: "mutualidad",
      docFieldName: "mutualidad",
      hideFiles: true,
    },
  ];

  return (
    <CardContent>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid container item xs={12} sm={12} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Info
              </Typography>
            </Grid>
            {documentsList
              .filter((item) => item.textFieldName)
              .map((item, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      // expandIcon={<ChevronDown color="#fff" />}

                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#42529b" }}
                    >
                      <Typography style={{ color: "#fff" }}>
                        {item.docName}
                      </Typography>
                      {/* {!validateFile(item.docName) ? (
                        <CheckCircle
                          style={{ marginLeft: 10, color: "#fff" }}
                        />
                      ) : null} */}
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#f5f5f5" }}>
                      <Box style={{ width: "100%" }}>
                        {item?.textFieldName && item?.textFieldLabel && (
                          <Box order={1} my={2}>
                            <TextField
                              id={`idTextField-${index}`}
                              label={item?.textFieldLabel || ""}
                              value={
                                textFieldValHandler(
                                  item.docName,
                                  item.textFieldName
                                ) || ""
                              }
                              onChange={(e) =>
                                item?.textFieldName &&
                                handleChangeFieldValue(
                                  item.docName,
                                  item.textFieldName,
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        )}
                        {/* {item?.hideFiles ? null : (
                          <Box order={2} my={3} display="flex">
                            <Box>
                              <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                              >
                                Seleccionar Archivo
                                <input
                                  type="file"
                                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                  hidden
                                  onChange={(event) => {
                                    if (
                                      event.target.files &&
                                      event.target.files[0]
                                    ) {
                                      const file = event.target.files[0];
                                      const nameArr = file.name.split(".");
                                      const fileExt =
                                        nameArr[nameArr.length - 1];
                                      // console.log("extension >>> ", fileExt);
                                      handleChangeFileDoc(
                                        item.docName,
                                        item.docFieldName,
                                        file,
                                        fileExt
                                      );
                                    }
                                  }}
                                />
                              </Button>
                              {fileSelected(item.docName) ? (
                                <ArrowUpCircle
                                  size={18}
                                  color="#000"
                                  style={{ marginLeft: 3 }}
                                />
                              ) : null}
                            </Box>
                            <Box ml={2}>
                              <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                                disabled={validateFile(item.docName)}
                                onClick={() => handleTryDownload(item.docName)}
                              >
                                Descargar Archivo
                              </Button>
                            </Box>
                          </Box>
                        )} */}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
          <Grid container item xs={12} sm={12} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Documentación
              </Typography>
            </Grid>
            {documentsList
              .filter((item) => !item.textFieldName)
              .map((item, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ChevronDown color="#fff" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#42529b" }}
                    >
                      <Typography style={{ color: "#fff" }}>
                        {item.docName === "Miper"
                          ? item.docName +
                            " (Matríz de Identificación de Peligros y Evaluación de Riesgos)"
                          : item.docName}
                      </Typography>
                      {!validateFile(item.docName) ? (
                        <CheckCircle
                          style={{ marginLeft: 10, color: "#fff" }}
                        />
                      ) : null}
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#f5f5f5" }}>
                      <Box style={{ width: "100%" }}>
                        {item?.textFieldName && item?.textFieldLabel && (
                          <Box order={1} my={2}>
                            <TextField
                              id={`idTextField-${index}`}
                              label={item?.textFieldLabel || ""}
                              value={
                                textFieldValHandler(
                                  item.docName,
                                  item.textFieldName
                                ) || ""
                              }
                              onChange={(e) =>
                                item?.textFieldName &&
                                handleChangeFieldValue(
                                  item.docName,
                                  item.textFieldName,
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        )}
                        <Box order={2} my={3} display="flex">
                          <Box>
                            <Button
                              variant="outlined"
                              component="label"
                              color="primary"
                            >
                              Seleccionar Archivo
                              <input
                                type="file"
                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                hidden
                                onChange={(event) => {
                                  if (
                                    event.target.files &&
                                    event.target.files[0]
                                  ) {
                                    const file = event.target.files[0];
                                    const nameArr = file.name.split(".");
                                    const fileExt = nameArr[nameArr.length - 1];
                                    // console.log("extension >>> ", fileExt);
                                    handleChangeFileDoc(
                                      item.docName,
                                      item.docFieldName,
                                      file,
                                      fileExt
                                    );
                                  }
                                }}
                              />
                            </Button>
                            {fileSelected(item.docName) ? (
                              <ArrowUpCircle
                                size={18}
                                color="#000"
                                style={{ marginLeft: 3 }}
                              />
                            ) : null}
                          </Box>
                          <Box ml={2}>
                            <Button
                              variant="outlined"
                              component="label"
                              color="primary"
                              disabled={validateFile(item.docName)}
                              onClick={() => handleTryDownload(item.docName)}
                            >
                              Descargar Archivo
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          sm={12}
          style={{ marginTop: 10 }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={state === FormState.Submitting}
          >
            Guardar
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};

export const EditBusiness = React.memo(() => {
  const { businessId } = useParams<any>();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const {
    selected,
    update: { state, error },
  } = useSelector<RootState, BusinessState>((state) => state.businessReducer);
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!selected) {
      setLoading(true);
      dispatch(getEmpresa(businessId));
    } else {
      setLoading(false);
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      dispatch(setBusiness(null));
    };
  }, []);

  useEffect(() => {
    if (state === FormState.Success) {
      dispatch(openSnack("Datos actualizados con éxito", SnackState.SUCCESS));
      dispatch(getEmpresa(businessId));
    } else if (state === FormState.Failure && error) {
      dispatch(openSnack(error, SnackState.ERROR));
    }
    if (state !== FormState.Submitting && state !== FormState.Initial)
      dispatch(setUpdateState(FormState.Initial));
  }, [state]);

  return (
    <React.Fragment>
      <Helmet title="Editar Usuario" />
      <Typography variant="h3" gutterBottom display="inline">
        {user?.business ? "Mi Perfil" : "Editar Empresa"}
      </Typography>

      {user?.business ? null : (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to="/businesses">
            Listado
          </Link>
          <Typography>Editar</Typography>
        </Breadcrumbs>
      )}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="user-tabs"
              >
                <Tab label="Información" {...a11yProps(0)} />
                <Tab label="Otros" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            {loading || state === FormState.Submitting ? (
              <LinearProgress />
            ) : null}
            <TabPanel value={value} index={0}>
              <ContentCardInfo businessId={businessId} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContentCardDocs businessId={businessId} />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
