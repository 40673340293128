import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface Iincidentes {
  incidentes: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedIncident?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  update?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  incidentes: [],
  state: FormState.Initial,
  totalDocs: undefined,
  lastDoc: undefined,
  selectedIncident: null,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
  update: {
    state: FormState.Initial,
  },
} as Iincidentes;

export const incidentesReducer = (
  state = initialState,
  action: Action
): Iincidentes => {
  switch (action.type) {
    case types.INCIDENTES_GET_SUCCESS:
      return {
        state: FormState.Success,
        incidentes: action.payload.incidentes,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.INCIDENTES_GET_IS_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.INCIDENTES_GET_IS_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.INCIDENTES_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
      };
    case types.INCIDENTES_DELETE_IS_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.INCIDENTES_SET_SELECTED:
      return {
        ...state,
        selectedIncident: action.payload,
      };
    case types.INCIDENTES_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
        update: {
          state: FormState.Initial,
        },
      };
    case types.INCIDENTES_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedIncident: action.payload,
      };
    case types.INCIDENTES_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };

    /////////////////////////////////////////
    case types.INCIDENTES_UPDATE_ONE_FAILURE:
      return {
        ...state,
        update: {
          state: FormState.Failure,
        },
      };
    case types.INCIDENTES_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        update: {
          state: FormState.Success,
        },
      };
    case types.INCIDENTES_UPDATE_ONE_SUBMMITING:
      return {
        ...state,
        update: {
          state: FormState.Submitting,
        },
      };
    /////////////////////////////////////////

    case types.INCIDENTES_GET_DOCS:
      return {
        ...state,
        incidentes: action.payload,
      };
    case types.INCIDENTES_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.INCIDENTES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
