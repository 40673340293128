import firebase from "firebase";
import * as types from "../../constants";
import { firebaseConfig, firestore, storage } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { BussinessModel } from "../../models/businesses";
import { FormState } from "../../models/form_state";
import { SnackState } from "../../models/snack-state";
import { UserModel } from "../../models/user";
import {
  cleanString,
  editCount,
  exportToCsv,
  lowerFieldValidation,
} from "../../utils/utils";
import { openSnack } from "./uiActions";
import { createUserAuth, removeUserAuth, updateUserAuth } from "./usersActions";

const API_KEY = firebaseConfig.apiKey;

export type filterType = {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value: any;
}[];

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export const sortQueries = (
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  filter: filterType = [],
  dateOrder: string = "FechaCreacion",
  ignoreSort = false
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  if (filter.length === 0) return query;
  if (filter.length === 1 && cleanString(filter[0].field).includes("lower")) {
    if (!ignoreSort) {
      query = query.orderBy(dateOrder, "desc");
    }
    query = query
      .startAt(cleanString(filter[0].value))
      .endAt(cleanString(filter[0].value) + "\uf8ff");
    return query;
  }

  const indexUtil = lowerFieldValidation(filter);

  if (indexUtil !== "empty") {
    if (!ignoreSort) {
      query = query.orderBy(dateOrder, "desc");
    }
    query = query
      .startAt(cleanString(filter[indexUtil].value))
      .endAt(cleanString(filter[indexUtil].value) + "\uf8ff");

    filter.map((q, index) => {
      if (index !== indexUtil)
        query = query.where(q.field, q.operator, q.value);
    });

    return query;
  }

  filter.map((q) => {
    query = query.where(q.field, q.operator, q.value);
  });
  return query;
};

export const exportEmpresas = (
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch) => {
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }

      let query = firestore.collection("Empresas").orderBy(order, dir(order));

      const response = await sortQueries(query, filter).get();

      const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      exportToCsv(
        "empresas.csv",
        data,
        ["Nombre", "Rut", "Email", "FechaCreacion", "Modulos"],
        ["Nombre", "Rut", "Email", "Fecha de Creación", "Modulos"]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getEmpresas = (
  filter: filterType = [],
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch({ type: types.BUSINESSES_GET_IN_REQUEST });
    try {
      let totalDocs = 0;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }

      let query = firestore
        .collection("Empresas")
        .orderBy(order, dir(order))
        // .where("Activo", "==", true)
        .limit(limit);

      if (filter.length === 0) {
        const countRes = await firestore
          .collection("Contadores")
          .doc("Empresas")
          .get();
        if (countRes.exists) {
          const count = countRes.data()?.count;
          if (count) totalDocs = count;
        }
      }

      const response = await sortQueries(query, filter).get();

      const lastDoc = response.docs[response.docs.length - 1];

      const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      dispatch({
        type: types.BUSINESSES_GET_SUCCESS,
        payload: { businesses: data, totalDocs: totalDocs, lastDoc: lastDoc },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({ type: types.BUSINESSES_GET_FAILURE });
    }
  };
};

export const getMoreEmpresas = (
  filter: filterType = [],
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({ type: types.BUSINESSES_GET_IN_REQUEST });
    const {
      getList: { businesses, lastDoc },
    } = getState().businessReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }

      let query = firestore
        .collection("Empresas")
        .orderBy(order, dir(order))
        .startAfter(lastDoc)
        // .where("Activo", "==", true)
        .limit(limit);

      const response = await sortQueries(query, filter).get();

      const newLastDoc = response.docs[response.docs.length - 1];

      const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      dispatch({
        type: types.BUSINESSES_GET_MORE_SUCCESS,
        payload: {
          businesses: [...businesses, ...data],
          lastDoc: newLastDoc,
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({ type: types.BUSINESSES_GET_FAILURE });
    }
  };
};

export const addEmpresa = (formEmpresa: any, formUser: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setAddState(FormState.Submitting));
    try {
      const resSearchA = await firestore
        .collection("Empresas")
        .where("Rut", "==", formEmpresa.Rut)
        .get();

      if (resSearchA.size > 0) {
        dispatch(setAddState(FormState.Failure, "Rut de empresa en uso"));
        return;
      }

      const resSearchB = await firestore
        .collection("Usuarios")
        .where("EmpresaExterna", "==", true)
        .where("Rut", "==", formUser.Rut)
        .get();

      if (resSearchB.size > 0) {
        dispatch(setAddState(FormState.Failure, "Rut de usuario en uso"));
        return;
      }

      const credentials = {
        email: formUser.Email,
        password: formUser?.password,
      };

      delete formUser.password;

      const timeStamp = firebase.firestore.Timestamp.now();

      const { code, uid } = await createUserAuth(credentials);

      if (code === "OK" && uid) {
        const userRef = firestore.collection("Usuarios").doc(uid);

        await userRef.set({
          ...formUser,
          FechaIngreso: timeStamp,
        });

        const bsnssRef = await firestore
          .collection("Empresas")
          .add({ ...formEmpresa, FechaCreacion: timeStamp, Usuario: userRef });

        const areaRef = await firestore.collection("AreasBSNS").add({
          Descripcion: "Administración",
          FechaCreacion: timeStamp,
          Nombre: "Administración",
          NombreLower: "administracion",
          EmpresaExterna: true,
          Empresa: bsnssRef,
        });

        await firestore.collection("CargosBSNS").add({
          Descripcion: "Administración",
          FechaCreacion: timeStamp,
          Nombre: "Administrador",
          Nombre_lower: "administrador",
          EmpresaExterna: true,
          Empresa: bsnssRef,
          Activado: true,
          TipoPermisos: "Admin",
        });

        await userRef.update({
          Empresa: bsnssRef,
          Area: "Administración",
          AreaId: areaRef.id,
          AdminEmpresa: true,
          Cargo: "Administrador",
        });

        const counRef = firestore.collection("Contadores").doc("Empresas");

        await editCount(counRef, "plus");

        dispatch(setAddState(FormState.Success, "", bsnssRef.id));
      } else {
        //Error
        console.log(code);
        dispatch(setAddState(FormState.Failure, "Error de creacion user"));
      }
    } catch (error: any) {
      console.log(error);
      //Error
      dispatch(setAddState(FormState.Failure, "Hubo un problema"));
    }
  };
};

export const removeEmpresa = (id: string): AppThunk => {
  return async (dispatch) => {
    // dispatch(setUpdateState(FormState.Submitting));
    try {
      const businessRef = firestore.collection("Empresas").doc(id);

      const usersSnap = await firestore
        .collection("Usuarios")
        .where("Empresa", "==", businessRef)
        .get();

      const users: { id: string; email: string }[] = usersSnap.docs.map(
        (doc) => ({ id: doc.id, email: doc.data().Email })
      );

      let batch = firestore.batch();

      for (const user of users) {
        await removeUserAuth({ email: user.email });

        const userRef = firestore.collection("Usuarios").doc(user.id);
        batch.delete(userRef);
      }
      await batch.commit();

      await businessRef.delete();

      const counRef = firestore.collection("Contadores").doc("Empresas");

      await editCount(counRef, "minus");

      /// remove all modules info
    } catch (error: any) {
      console.log(error);
      //Error
    }
  };
};
export const updateEmpresa = (
  formEmpresa: any,
  formUser: any,
  id: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setUpdateState(FormState.Submitting));
    const { selected } = getState().businessReducer;
    try {
      if (!selected) {
        dispatch(setUpdateState(FormState.Failure, "Hubo un problema"));
        return;
      }

      const { business, user } = selected;

      const credentials: any = {};

      if (user.Email !== formUser?.Email) {
        credentials["email"] = formUser?.Email;
      }
      if (formUser?.password) {
        credentials["password"] = formUser?.password;
        delete formUser?.password;
      }

      const resSearchA = await firestore
        .collection("Empresas")
        .where("Rut", "==", formEmpresa.Rut)
        .get();

      if (resSearchA.size > 1) {
        dispatch(setAddState(FormState.Failure, "Rut de empresa en uso"));
        return;
      } else if (resSearchA.size === 1 && resSearchA.docs[0].id !== id) {
        dispatch(setAddState(FormState.Failure, "Rut de empresa en uso"));
        return;
      }

      const resSearchB = await firestore
        .collection("Usuarios")
        .where("EmpresaExterna", "==", true)
        .where("Empresa", "==", user.Empresa)
        .where("Rut", "==", formUser.Rut)
        .get();

      if (resSearchB.size > 1) {
        dispatch(setAddState(FormState.Failure, "Rut de usuario en uso"));
        return;
      } else if (resSearchB.size === 1 && resSearchB.docs[0].id !== user.id) {
        dispatch(setAddState(FormState.Failure, "Rut de usuario en uso"));
        return;
      }

      // const timeStamp = firebase.firestore.Timestamp.now();
      let code = "OK";
      if (Object.keys(credentials).length > 0) {
        const resAuth = await updateUserAuth(credentials, user.id || "");

        code = resAuth.code;
      }

      if (code === "OK" || Object.keys(credentials).length === 0) {
        const businessActive = formEmpresa?.Activo;

        if (businessActive === false) {
          let batch = firestore.batch(); //Limit transactions: 500

          //If bussines is inactive, all active users should be too
          const usersActive = await firestore
            .collection("Usuarios")
            .where("Empresa", "==", user.Empresa)
            .where("Email", "!=", formUser.Email) // Filter super admin Empresa
            .where("Activo", "==", true)
            .get();

          let transactionsCounter = 0;

          const usersBusiness = usersActive.docs.map((doc) => ({
            id: doc.id,
            isAdmin: doc.data()?.AdminEmpresa ? true : false,
          }));

          const activeUsers = usersBusiness
            .filter((doc) => !doc.isAdmin)
            .map((doc) => doc.id);

          for (const activeUsr of activeUsers) {
            transactionsCounter++;
            const userRef = firestore.collection("Usuarios").doc(activeUsr);
            batch.update(userRef, { Aprobado: false });
            if (transactionsCounter === 499) {
              await batch.commit();
              batch = firestore.batch(); // Reset bach stored
              transactionsCounter = 0;
            }
          }
          if (transactionsCounter > 0 && transactionsCounter < 499) {
            await batch.commit();
            transactionsCounter = 0;
          }
        }

        await firestore
          .collection("Empresas")
          .doc(id)
          .update({ ...formEmpresa });

        await firestore
          .collection("Usuarios")
          .doc(user.id)
          .update({
            ...formUser,
          });

        dispatch(setUpdateState(FormState.Success));
      } else {
        //Error
        console.log(code);
        let error = "Error de datos user";
        if (code !== "ERROR") error = code;
        dispatch(setUpdateState(FormState.Failure, error));
      }
    } catch (error: any) {
      console.log(error);
      //Error
      dispatch(setUpdateState(FormState.Failure, "Hubo un problema"));
    }
  };
};

export const setUsersNotBusiness = (): AppThunk => {
  return async (dispatch) => {
    try {
      let batch = firestore.batch();

      const usersActive = await firestore.collection("Usuarios").get();

      let transactionsCounter = 0;

      const users = usersActive.docs.map((doc) => doc.id);

      for (const user of users) {
        transactionsCounter++;
        const userRef = firestore.collection("Usuarios").doc(user);
        batch.update(userRef, { EmpresaExterna: false });
        if (transactionsCounter === 499) {
          await batch.commit();
          batch = firestore.batch(); // Reset bach stored
          transactionsCounter = 0;
        }
      }

      if (transactionsCounter > 0 && transactionsCounter < 499) {
        await batch.commit();
        transactionsCounter = 0;
      }
    } catch (error: any) {
      console.log(error);
      //Error
    }
  };
};

export const getEmpresa = (id: string): AppThunk => {
  return async (dispatch) => {
    try {
      const businessRes = await firestore.collection("Empresas").doc(id).get();

      if (businessRes.exists) {
        const business: BussinessModel = {
          ...businessRes.data(),
          id: id,
        } as any;

        const userRef = business.Usuario;

        const userRes = await userRef.get();

        if (userRes.exists) {
          const user: UserModel = { ...userRes.data(), id: userRes.id } as any;

          dispatch(setBusiness({ business: business, user: user }));
        } else {
          dispatch(
            openSnack(
              "Hubo un problema al obtener datos de la empresa",
              SnackState.ERROR
            )
          );
        }
      } else {
        dispatch(openSnack("Hubo un problema", SnackState.ERROR));
      }
    } catch (error: any) {
      console.log(error);
      //Error
      dispatch(openSnack("Hubo un problema", SnackState.ERROR));
    }
  };
};

export const updateEmpresaDocuments = (
  id: string,
  documents: any = {},
  progreso: string = "0/0"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setUpdateState(FormState.Submitting));
    try {
      const docs: any = {};
      for (const key of Object.keys(documents)) {
        const value = documents[`${key}`];

        if (value?.file) {
          if (value?.url) delete value.url;
          if (value?.oldFilePath) {
            const oldFileRef = storage.ref(value.oldFilePath);
            try {
              await oldFileRef.delete();
            } catch {}
            delete value.oldFilePath;
          }
          const fileRef = storage.ref(value.filePath);
          await fileRef.put(value.file);
          const url = await fileRef.getDownloadURL();
          value["url"] = url;
          delete value.file;
        }
        docs[`${key}`] = value;
      }

      const uptData = { Documentos: docs, Progreso: progreso };

      await firestore.collection("Empresas").doc(id).update(uptData);
      dispatch(setUpdateState(FormState.Success));
    } catch (error: any) {
      console.log(error);
      dispatch(setUpdateState(FormState.Failure, "Hubo un problema"));
    }
  };
};

// export const utilIndexGenerator = (): AppThunk => {
//   return async (dispatch) => {
//     try {
//       await firestore
//         .collection("Usuarios")
//         .where("Cargo", "in", ['a'])
//         .where("Area", "==", "Usuario")
//         .where("EmpresaExterna", "==", false)
//         .get();
//       console.log("correct");
//     } catch (error: any) {
//       console.log(error);
//     }
//   };
// };

export const setAddState = (
  state: FormState,
  error?: string,
  newId?: string
): Action => ({
  type: types.BUSINESS_SET_ADD_STATE,
  payload: { state: state, error: error, newId: newId },
});
export const setUpdateState = (state: FormState, error?: string): Action => ({
  type: types.BUSINESS_SET_UPDATE_STATE,
  payload: { state: state, error: error },
});

export const setBusiness = (
  selected: { business: BussinessModel; user: UserModel } | null
): Action => ({
  type: types.BUSINESS_SET_SELECTED,
  payload: selected,
});
