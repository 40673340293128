import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Download, Eye, MinusSquare, Search } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  exportActividades,
  getActividades,
  getMoreActividades,
  setSelectedActividad,
} from "../redux/actions/actividadesActions";
import { filterType } from "../redux/actions/businessActions";
import { deleteCheckLists } from "../redux/actions/checkListActions";
import { IActividades } from "../redux/reducers/actividadesReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface filterProps {
  onFilter: (value: filterType) => void;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [subjectData, setSubjectData] = useState<any>(null);

  const handleDeleteCheckLists = () => {
    if (subjectData && subjectData?.id)
      dispatch(deleteCheckLists(subjectData.id));
    setOpenDelete(false);
  };

  const handleGoToViewactividad = (data: any) => {
    dispatch(setSelectedActividad(data));
    history.push(`/gestionActividades/${data.id}/ver`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT || 50,
      onLimitChange: (e, newLimit) => {
        if (newLimit > actividades.length) {
          dispatch(getActividades(newLimit, filtros));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (actividades.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreActividades(limit, filtros));
        }
      },
    });

  useEffect(() => {
    dispatch(getActividades(limit, filtros));
  }, [filtros]);

  const { actividades, state, totalDocs } = useSelector<
    RootState,
    IActividades
  >((state) => state.actividadReducer);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  const handleExport = () => {
    dispatch(exportActividades(filtros));
  };
  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
          </>
        }
      />
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Area</TableCell>
                  <TableCell align="left">Empresa</TableCell>
                  <TableCell align="left">Tema</TableCell>
                  <TableCell align="left">Fecha de Actividad</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  actividades
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell align="left">{data?.Area}</TableCell>
                          <TableCell align="left">{data?.Empresa}</TableCell>
                          <TableCell align="left">{data?.Tema}</TableCell>
                          <TableCell align="left">
                            {timesStampFormattedsimple(
                              data?.FechaActividad.toDate()
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="Ver">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    handleGoToViewactividad(data);
                                  }}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar el fomulario?"
          onConfirm={handleDeleteCheckLists}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);
  const [empresa, setEmpresa] = useState("");
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setEmpresa(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (empresa)
      filter.push({
        field: "Empresa",
        operator: "==",
        value: empresa,
      });

    if (dateStart)
      filter.push({
        field: "FechaActividad",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaActividad",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setEmpresa("");
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };
  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre Empresa"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={empresa}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Actividades = () => (
  <React.Fragment>
    <Helmet title="Actividades" />
    <Typography variant="h3" gutterBottom display="inline">
      Gestión de Actividades.
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Gestión de Actividades</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
