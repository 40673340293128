import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface ICheckListsState {
  checkLists: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedChecklists?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  add?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  checkLists: [],
  state: FormState.Initial,
  totalDocs: undefined,
  selectedChecklists: null,
  lastDoc: undefined,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as ICheckListsState;

export const checkListReducer = (
  state = initialState,
  action: Action
): ICheckListsState => {
  switch (action.type) {
    case types.CHECKLISTS_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        checkLists: action.payload.checkLists,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.CHECKLISTS_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.CHECKLISTS_GET_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
        delete: { state: FormState.Initial },
      };
    case types.CHECKLISTS_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
      };
    case types.CHECKLISTS_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.CHECKLIST_DELETE_SET_STATE:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: action.payload,
        },
      };
    case types.CHECKLISTS_SET_SELECTED:
      return {
        ...state,
        selectedChecklists: action.payload,
      };
    case types.CHECKLISTS_GET_ONE_SUBMITTING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.CHECKLISTS_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedChecklists: action.payload,
      };
    case types.CHECKLISTS_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.CHECKLISTS_ADD_ONE_IS_SUBMMITING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.CHECKLISTS_ADD_ONE_IS_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.CHECKLISTS_ADD_ONE_IS_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.CHECKLISTS_SET_LAST_DOCS:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.CHECKLISTS_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.CHECKLISTS_GET_DOCS:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    default:
      return state;
  }
};
