import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip as MuiChip,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Download, Eye, MinusSquare, Search, Trash } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  exportAnswers,
  getAnswers,
  getAnswersFiltered,
  getMoreAnswers,
  getMoreAnswersFiltered,
  setSelectedAnswers,
} from "../redux/actions/answersActions";
import { filterType } from "../redux/actions/businessActions";
import { AnswersState } from "../redux/reducers/answersReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampToDateFormatted,
} from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip: any = styled(MuiChip)`
  ${spacing};

  background: ${(props: any) => props.activated && green[500]};
  background: ${(props: any) => props.desactivated && red[500]};
  color: ${(props) => props.theme.palette.common.white};
  min-width: 90px;
`;

interface filterProps {
  onFilter: (value: filterType) => void;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [subjectData, setSubjectData] = useState<any | {}>({ id: "" });

  const handleDeleteAccept = () => {
    // dispatch(deleteCargo(subjectData["id"]));
    // setOpenDelete(false);
  };

  const handleGoToAnswer = (data: any) => {
    dispatch(setSelectedAnswers(data));
    history.push(`/formulariosCovid/respuestas/${data.id}/respuesta`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (e, newLimit) => {
        if (newLimit > answersList.length) {
          dispatch(getAnswers(newLimit, filtros));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (answersList.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreAnswers(limit, filtros));
        }
      },
    });

  useEffect(() => {
    dispatch(getAnswers(limit, filtros));
  }, [filtros]);

  const { answersList, totalDocs, state } = useSelector<
    RootState,
    AnswersState
  >((state) => state.answersReducer);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  const handleExport = () => {
    dispatch(exportAnswers(filtros));
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
          </>
        }
      />
      <Card mb={6}>
        {/* FILTRO INICIO */}
        <FilterSection onFilter={handleFilter} />
        {/* FILTRO FINAL */}

        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Formulario</TableCell>
                  <TableCell>Fecha de Creación</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state !== FormState.Submitting ? (
                  answersList
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data?.id} in={true}>
                        <TableRow
                          hover
                          className={classes.styledRow}
                          // style={{ backgroundColor: "#f381a7" }}
                        >
                          <TableCell>{data?.Formulario.Nombre}</TableCell>
                          <TableCell>
                            {timesStampToDateFormatted(
                              data?.FechaCreacion.toDate()
                            )}
                          </TableCell>
                          <TableCell>{data?.NombreCompleto}</TableCell>
                          {/* <TableCell align="left">
                            {data?.Activado ? (
                              <Chip
                                size="small"
                                label="Activado"
                                activated="true"
                              />
                            ) : (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Desactivado"
                                desactivated="true"
                              />
                            )}
                          </TableCell>
                          <TableCell>{data?.Descripcion}</TableCell> */}
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              {/* <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setOpenNewEdit(true);
                                    setTypeDialog("edit");
                                    setSubjectData(data);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip> */}
                              <Tooltip title="Ver">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleGoToAnswer(data)}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSubjectData(data);
                                    setOpenDelete(true);
                                  }}
                                >
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar Respuestas?"
          onConfirm={handleDeleteAccept}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);
  const [nombre, setNombre] = useState("");
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "Formulario.Nombre_lower",
        operator: "==",
        value: cleanString(nombre),
      });

    if (dateStart)
      filter.push({
        field: "FechaCreacion",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaCreacion",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre Formulario"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Answers = () => (
  <React.Fragment>
    <Helmet title="Respuestas" />
    <Typography variant="h3" gutterBottom display="inline">
      Respuestas
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Listado</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
