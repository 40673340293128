// import firebase from "firebase";
import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { FormState } from "../../models/form_state";
import { SnackState } from "../../models/snack-state";
import { cleanString, lowerFieldValidation } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "ChecklistsBSNS",
  aclara: "Checklists",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export const getCheckLists = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHECKLISTS_GET_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();

      const respuesChecklists = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.CHECKLISTS_GET_SUCCESS,
        payload: {
          checkLists: respuesChecklists,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.CHECKLISTS_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar los Checklists",
      });
    }
  };
};

export const getMoreCheckLists = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHECKLISTS_GET_IS_SUBMITTING,
    });
    // const lastDoc = getState().checkListReducer.lastDoc || "";
    const { totalDocs, checkLists, lastDoc } = getState().checkListReducer;
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestaschecklists = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.CHECKLISTS_GET_SUCCESS,
        payload: {
          checkLists: checkLists.concat(respuestaschecklists),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.CHECKLISTS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado de los checklists",
      });
    }
  };
};

export const deleteCheckLists = (checklistId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setDeleteChecklistState(FormState.Submitting));
    const { user } = getState().authReducer;

    try {
      await firestore.collection(collName(user)).doc(checklistId).delete();
      dispatch(setDeleteChecklistState(FormState.Success));
      dispatch(
        openSnack("Checklist eliminado exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("No se ha podido eliminar", SnackState.ERROR));
      dispatch(setDeleteChecklistState(FormState.Failure));
    }
  };
};

export const getOneChecklist = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHECKLISTS_GET_ONE_SUBMITTING,
    });
    const { user } = getState().authReducer;

    try {
      const response = await firestore.collection(collName(user)).doc(id).get();

      dispatch({
        type: types.CHECKLISTS_GET_ONE_SUCCESS,
        payload: response.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.CHECKLISTS_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error eliminando el checklists",
      });
    }
  };
};

export const getChecklistFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy(order, "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });
      const response = await query.get();

      const rescheckList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      // dispatch(setIncidentes(incidentesList));
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));

      dispatch({
        type: types.CHECKLISTS_GET_SUCCESS,
        payload: {
          checkLists: rescheckList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};

export function updateComent(
  checklist: any,
  comentario: string,
  estado: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHECKLISTS_ADD_ONE_IS_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      const response = await firestore
        .collection(collName(user))
        .doc(checklist.id)
        .update({
          Estado: estado,
          Revision: {
            Comentario: comentario,
            FechaRevision: firebase.firestore.FieldValue.serverTimestamp(),
            UsuarioId: user.id,
            UsuarioNombreCompleto: user.name,
            UsuarioNombreCompleto_lower: cleanString(user.name),
          },
        });
      dispatch(
        openSnack("Se agreago comentario exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.CHECKLISTS_ADD_ONE_IS_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export function updateComentCierre(
  checklist: any,
  comentario: string,
  estado: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHECKLISTS_ADD_ONE_IS_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      const response = await firestore
        .collection(collName(user))
        .doc(checklist.id)
        .update({
          Estado: estado,
          Cierre: {
            ComentarioCierre: comentario,
            FechaCierre: firebase.firestore.FieldValue.serverTimestamp(),
            UsuarioId: user.id,
            UsuarioNombreCompleto: user.name,
            UsuarioNombreCompleto_lower: cleanString(user.name),
          },
        });
      dispatch(
        openSnack("Comentario agregado exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.CHECKLISTS_ADD_ONE_IS_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}
export const setSelectedCheckLists = (checkLists: any): Action => ({
  type: types.CHECKLISTS_SET_SELECTED,
  payload: checkLists,
});
const isLoading = (isloading: boolean): Action => ({
  type: types.CHECKLISTS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.CHECKLISTS_FAILURE,
  payload: error,
});

const setDeleteChecklistState = (state: FormState): Action => ({
  type: types.CHECKLIST_DELETE_SET_STATE,
  payload: state,
});
