import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ArrowUpCircle, ChevronDown, User } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import * as rutUtils from "rut.js";
import styled from "styled-components/macro";
import * as yup from "yup";
import { SnackState } from "../../models/snack-state";
import {
  getAreasAll,
  getBusinessAreas,
} from "../../redux/actions/areasActions";
import {
  getBusinessCargos,
  getCargos,
} from "../../redux/actions/positionsChargesActions";
import { openSnack } from "../../redux/actions/uiActions";
import {
  getUser,
  sendUserEmail,
  updateDocuments,
  updateEmailAndPassword,
  updateUser,
} from "../../redux/actions/usersActions";
import { AreasState } from "../../redux/reducers/areasReducer";
import { IAuthState } from "../../redux/reducers/authReducer";
import { PositionChargesState } from "../../redux/reducers/positionChargesReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { IUsersState } from "../../redux/reducers/usersReducer";
import {
  cleanString,
  getFileNameExtension,
  timesStampFormattedsimple,
} from "../../utils/utils";
import { a11yProps, TabPanel } from "./DetailTabs";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type AvatarImage = File | undefined | { Url: string; Uri: string };

const ContentCardInfo = () => {
  const { businessCargos } = useSelector<RootState, PositionChargesState>(
    (state) => state.positionChargesReducer
  );

  const dispatch = useDispatch();
  const { userId } = useParams<any>();

  const [valCargo, setValCargo] = useState<any>(null);
  const [valueArea, setArea] = useState<any>(null);
  const [motivoRechazo, setMotivoRechazo] = useState("");
  const [hiddenForeign, setHiddenForeign] = useState(false);
  // const [image, setImage] = useState<File | null>(null);

  const {
    edit: { selectedUser },
  } = useSelector<RootState, IUsersState>((state) => state.usersReducer);
  const { businessAreas } = useSelector<RootState, AreasState>(
    (state) => state.areasReducer
  );
  const { user: userAuth } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setValues,
  } = useFormik({
    initialValues: {
      id: undefined,
      Nombre: "",
      Apellido: "",
      Email: "",
      Avatar: undefined as AvatarImage,
      // Contrasenia: "",
      Rut: "",
      // Edad: "",
      Telefono: "",
      FechaIngreso: new Date(),
      Area: "",
      AreaId: "",
      Cargo: "",
      TipoPermisos: "",
      Aprobado: false,
      InduccionCompleta: false,
    } as any,
    onSubmit: (values) => {
      const telefono = "+569" + values.Telefono;
      dispatch(
        updateUser(
          { ...values, Telefono: telefono },
          selectedUser,
          hiddenForeign
        )
      );
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Nombre requerido"),
      Apellido: yup.string().required("Apellido requerido"),
      Rut: yup
        .string()
        .min(11)
        .required("Rut es requerido")
        .test({
          name: "Rut",
          message: "Rut no válido",
          test: (value) => {
            if (!value) return false;
            return rutUtils.validate(value);
          },
        }),
      // Edad: yup
      //   .number()
      //   .positive("Debe ser edad válida")
      //   .required("Edad es requerida"),
      Telefono: yup
        .number()
        .required("Es requerido")
        .test("len", "Número no válido", (val: any) => {
          try {
            return val.toString().length === 8;
          } catch {
            return false;
          }
        })
        .required("Número es requerido"),
      Email: yup.string().email("Email no válido").required("Email requerido"),
      // Contrasenia: yup.string().required("Contraseña requerida"),
      Area: yup.string().required("Área requerida"),
      Cargo: yup.string().required("Cargo requerido"),
    }),
  });
  useEffect(() => {
    if (selectedUser) {
      let date: any;
      if (selectedUser.FechaIngreso.seconds) {
        date = new Date(selectedUser?.FechaIngreso.seconds * 1000);
      } else {
        date = undefined;
      }

      const number = String(selectedUser.Telefono);
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", number);
      console.log(selectedUser);

      setValues({
        id: selectedUser?.id,
        Nombre: selectedUser?.Nombre,
        Apellido: selectedUser?.Apellido,
        Email: selectedUser?.Email,
        // Contrasenia: selectedUser?.Contrasenia,
        Rut: selectedUser?.Rut,
        // Edad: selectedUser?.Edad,
        Telefono: Number(number.replaceAll("+569", "")),
        FechaIngreso: date,
        Area: selectedUser?.Area,
        AreaId: selectedUser?.AreaId || "",
        Cargo: selectedUser?.Cargo,
        TipoPermisos: selectedUser?.TipoPermisos,
        Aprobado: selectedUser?.Aprobado || false,
        InduccionCompleta: selectedUser?.InduccionCompleta || false,
      });
      if (selectedUser?.Avatar) {
        setFieldValue("Avatar", selectedUser.Avatar);
      }
    } else {
      dispatch(getUser(userId));
    }
  }, [selectedUser, setValues, userId]);

  useEffect(() => {
    if (selectedUser) dispatch(getBusinessCargos(selectedUser?.Empresa));
  }, [selectedUser]);
  useEffect(() => {
    if (selectedUser) dispatch(getBusinessAreas(selectedUser?.Empresa));
  }, [selectedUser]);

  const findNameIn = (arrList: any[], name: string) =>
    arrList.find((item) => item.Nombre === name);

  useEffect(() => {
    if (businessCargos.length > 0) {
      if (!values.Cargo) return;
      const finded = findNameIn(businessCargos, values.Cargo);
      if (finded && typeof finded !== "undefined") {
        setValCargo(finded);
      }
    }
  }, [values.Cargo, businessCargos.length]);

  useEffect(() => {
    if (businessAreas.length > 0) {
      if (!values.Area && !values.AreaId) return;
      const finded = findNameIn(businessAreas, values.Area);
      if (finded && typeof finded !== "undefined") {
        setArea(finded);
      }
    }
  }, [values.Area, businessAreas.length]);

  const handleChangeCheck = (newVal: boolean, field: string) =>
    setFieldValue(field, newVal);

  const handleReject = async () => {
    const form = {
      email: selectedUser?.Email,
      mensaje: motivoRechazo,
    };
    // const form = {
    //   email: "ignaciok98@gmail.com",
    //   mensaje: motivoRechazo,
    // };
    setMotivoRechazo("");
    dispatch(sendUserEmail(form));
  };

  useEffect(() => {
    if (selectedUser?.Empresa && !userAuth?.business) {
      if (userAuth?.superUser) return;
      setHiddenForeign(true);
    }
  }, [selectedUser, userAuth]);

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const img = event.target.files[0];
      setFieldValue("Avatar", img);
      // if (event.target.files[0].size > 543718) {
      //   alert("Archivo demasiado pesado. Debe ser menor a 500kb");
      // } else {
      //   setImage(event.target.files[0]);
      // }
    }
  };

  return (
    <CardContent>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid container item xs={12} sm={12} spacing={3} justify="center">
            <Grid item xs={12} sm={12}>
              <Box display="flex" justifyContent="center">
                <input
                  style={{
                    display: "none",
                  }}
                  onChange={handleChangeImage}
                  id="contained-button-file"
                  accept="image/*"
                  type="file"
                />
                <Paper
                  elevation={0}
                  variant="outlined"
                  style={{
                    height: 140,
                    width: 140,
                    borderRadius: 140,
                    alignSelf: "center",
                    overflow: "hidden",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                    htmlFor="contained-button-file"
                  >
                    {values.Avatar ? (
                      <img
                        src={
                          values.Avatar instanceof File
                            ? URL.createObjectURL(values.Avatar)
                            : values.Avatar.Url
                        }
                        style={{
                          height: 140,
                          width: 140,
                          objectFit: "cover",
                        }}
                        alt={"UploadImage"}
                      />
                    ) : (
                      <>
                        <User size={30} />
                        Seleccionar Imagen
                      </>
                    )}
                  </label>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={8} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Información personal
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nombre"
                label="Nombre"
                value={values.Nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Nombre && Boolean(errors.Nombre)}
                helperText={touched.Nombre && errors.Nombre}
                disabled={hiddenForeign}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Apellido"
                label="Apellido"
                value={values.Apellido}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Apellido && Boolean(errors.Apellido)}
                helperText={touched.Apellido && errors.Apellido}
                disabled={hiddenForeign}
              />
            </Grid>
            {/* <Grid item xs={12} sm={2}>
              <TextField
                id="Edad"
                label="Edad"
                type="number"
                value={values.Edad}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Edad && Boolean(errors.Edad)}
                helperText={touched.Edad && errors.Edad}
                // disabled={hiddenForeign}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                id="Rut"
                label="Rut"
                value={values.Rut}
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                variant="outlined"
                fullWidth
                error={touched.Rut && Boolean(errors.Rut)}
                helperText={touched.Rut && errors.Rut}
                disabled={hiddenForeign}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Telefono"
                label="Teléfono"
                value={values.Telefono}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Telefono && Boolean(errors.Telefono)}
                helperText={touched.Telefono && errors.Telefono}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+569</InputAdornment>
                  ),
                }}
                disabled={hiddenForeign}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Datos cuenta
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="Email"
                label="Email"
                type="email"
                // disabled={false}
                value={values.Email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Email && Boolean(errors.Email)}
                helperText={touched.Email && errors.Email}
                disabled={hiddenForeign}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <TextField
                id="Contrasenia"
                label="Contraseña actual"
                name="Contrasenia"
                disabled={false}
                value={values.Contrasenia}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item sm={12} container spacing={3}>
              <Grid item sm={12}>
                <Typography variant="h6" gutterBottom>
                  Datos organizacionales
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="Area"
                  value={valueArea}
                  options={businessAreas}
                  getOptionLabel={(option) => option.Nombre}
                  onChange={(_: any, newValue: any | null) => {
                    if (newValue) {
                      setFieldValue("Area", newValue.Nombre);
                      setFieldValue("AreaId", newValue.id);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Area"
                      variant="outlined"
                      error={touched.Area && Boolean(errors.Area)}
                    />
                  )}
                  disabled={hiddenForeign}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="Cargo"
                  value={valCargo}
                  options={businessCargos}
                  getOptionLabel={(option) => option?.Nombre}
                  filterOptions={(option) =>
                    option.filter((opt: any) => opt.Activado)
                  }
                  onChange={(_: any, newValue: any) => {
                    if (newValue) {
                      setFieldValue("Cargo", newValue.Nombre);
                      setFieldValue(
                        "TipoPermisos",
                        newValue.TipoPermisos || "Usuario"
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cargo"
                      variant="outlined"
                      error={touched.Cargo && Boolean(errors.Cargo)}
                    />
                  )}
                  disabled={hiddenForeign}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="FechaIngreso"
                  type="text"
                  label="Fecha Ingreso"
                  value={timesStampFormattedsimple(values.FechaIngreso)}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          {userAuth?.superUser ? (
            <Grid item xs={12} sm={12}>
              <Grid item sm={12} container spacing={1}>
                <Grid item sm={12}>
                  <Typography variant="h6" gutterBottom>
                    Validación
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginLeft: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.Aprobado}
                        onChange={(_, newVal) =>
                          handleChangeCheck(newVal, "Aprobado")
                        }
                        // disabled={!userAuth?.superUser}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Aprobado  ( Usuario activo )"
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginLeft: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.InduccionCompleta}
                        onChange={(_, newVal) =>
                          handleChangeCheck(newVal, "InduccionCompleta")
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Induccion Completa"
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleReject}
                    fullWidth
                  >
                    Rechazar (Se enviará notificación por correo).
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                  <TextField
                    id="motivo-rechazo"
                    label="Motivo Rechazo"
                    value={motivoRechazo}
                    onChange={(e) => setMotivoRechazo(e.target.value)}
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    // error={touched.Nombre && Boolean(errors.Nombre)}
                    // helperText={touched.Nombre && errors.Nombre}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid item container justify="flex-end" sm={12}>
          <Button variant="contained" type="submit" color="primary">
            Guardar
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};
const ContentCardDocs = () => {
  const dispatch = useDispatch();
  const { userId } = useParams<any>();

  const {
    edit: { selectedUser },
  } = useSelector<RootState, IUsersState>((state) => state.usersReducer);

  const { handleSubmit, values, setValues, setFieldValue } = useFormik({
    initialValues: {} as any,
    onSubmit: (values) => {
      const currentDocsCount = Object.keys(values).length;
      const completionState = currentDocsCount + "/" + documentsList.length;
      const anvance =
        currentDocsCount === documentsList.length ? "Completo" : "Incompleto";
      // console.log("Completados >>> ", completionState);
      // console.log("VALUES >>> ", values);
      dispatch(updateDocuments(userId, values, completionState, anvance));
    },
    validationSchema: yup.object({}),
  });

  useEffect(() => {
    if (selectedUser) {
      if (
        selectedUser?.Documentos &&
        typeof selectedUser.Documentos === "object" &&
        Object.keys(selectedUser.Documentos).length > 0
      ) {
        setValues({
          ...selectedUser.Documentos,
        });
      }
    } else {
      // console.log("herefor some reason");
      dispatch(getUser(userId));
    }
  }, [selectedUser, dispatch, setValues, userId]);

  const handleChangeFieldValue = (
    docName: string,
    txtFieldName: string,
    newE: any
  ) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    let newValue: any = {};
    if (value) {
      newValue = { ...value };
    }
    newValue[`${txtFieldName}`] = newE;
    setFieldValue(`${cleanName}`, newValue);
  };

  const handleChangeFileDoc = (
    docName: string,
    docFieldName: string,
    newE: any,
    extension: string
  ) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    let newValue: any = {};
    if (value) {
      newValue = { ...value };
    }
    const filePath = newValue[`filePath`];
    if (filePath) {
      const newFilePath =
        userId + "/Documentos/" + docFieldName + "." + extension;
      const oldFilePath = filePath;
      newValue[`filePath`] = newFilePath;
      newValue[`oldFilePath`] = oldFilePath;
      newValue[`file`] = newE;
    } else {
      const filePath = userId + "/Documentos/" + docFieldName + "." + extension;
      newValue[`filePath`] = filePath;
      newValue[`file`] = newE;
    }
    setFieldValue(`${cleanName}`, newValue);
  };

  const textFieldValHandler = (docName: string, txtFieldName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    if (!value) return undefined;
    const fieldVal: any = value[`${txtFieldName}`];
    if (!fieldVal) return undefined;
    return fieldVal;
  };

  const handleTryDownload = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");
    const value = values[`${cleanName}`];
    if (!value || typeof value === "undefined") {
      openError();
      return;
    }
    if (!Object.keys(value).includes("url")) {
      openError();
      return;
    }
    const url = value[`url`];
    // const path: string = value[`filePath`];
    // const pathArr = path.split("/");
    // const fileName = pathArr[pathArr.length - 1];
    window.open(url, "_blank");
  };

  const validateFile = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");

    const value = values[`${cleanName}`];

    if (!value || typeof value === "undefined") return true;

    if (!Object.keys(value).includes("url")) return true;

    return false;
  };

  const fileSelected = (docName: string) => {
    const cleanName = cleanString(docName).replaceAll(" ", "");

    const value = values[`${cleanName}`];

    if (!value || typeof value === "undefined") return false;

    if (Object.keys(value).includes("file")) return true;

    return false;
  };

  const openError = () =>
    dispatch(openSnack("No hay Archivo", SnackState.ERROR));

  const documentsList = [
    //  docName Always required
    //  docFieldNameAlways required
    {
      docName: "Cedula de Identidad",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CedulaIdentidad",
    },
    {
      docName: "Examen de Salud",
      textFieldLabel: "Fecha de vencimiento de examen",
      textFieldName: "fechavencimientoexamen",
      fieldDate: true,
      docFieldName: "ExamenSalud",
    },
    {
      docName: "Anexo de Contrato o Trabajo",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "AnexoContratoTrabajo",
    },
    {
      docName: "Certificado Psicosensotecnico",
      textFieldLabel: "Fecha de vencimiento",
      textFieldName: "fechavencpsicosensotecnico",
      fieldDate: true,
      docFieldName: "CertificadoPsicosensotecnico",
    },
    {
      docName: "Entrega EPP",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "EntregaEPP",
    },
    {
      docName: "Capacitación Específica",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CapacitacionEspecifica",
    },
    {
      docName: "Entrega de RIOHS",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "EntregaRIOHS",
    },
    {
      docName: "Certificado de Curso 4x4",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CertificadoCurso4x4",
    },
    {
      docName: "Certificado de Curso Específico",
      textFieldLabel: "Fecha de vencimientpo y/o realización",
      textFieldName: "cursoespfechavencorealizacion",
      fieldDate: true,
      docFieldName: "CertificadoCursoEspecifico",
    },
    {
      docName: "Licencia de Conducir",
      textFieldLabel: "Fecha de vencimiento y clase de licencia",
      textFieldName: "licenciaconductorfechavencyclase",
      docFieldName: "LicenciaConducir",
    },
    {
      docName: "Oblicación Informar DS40",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "ObligacionInformarDS40",
    },
    {
      docName: "Alera",
      textFieldLabel: "Alera",
      textFieldName: "alera",
      docFieldName: "alera",
      hideFiles: true,
    },
    {
      docName: "ROL",
      textFieldLabel: "ROL",
      textFieldName: "rol",
      docFieldName: "rol",
      hideFiles: true,
    },
  ];

  return (
    <CardContent>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid container item xs={12} sm={12} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Info
              </Typography>
            </Grid>
            {documentsList
              .filter((item) => item.hideFiles)
              .map((item, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      // expandIcon={<ChevronDown color="#fff" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#42529b" }}
                    >
                      <Typography style={{ color: "#fff" }}>
                        {item.docName}
                      </Typography>
                      {/* {!validateFile(item.docName) ? (
                        <CheckCircle
                          style={{ marginLeft: 10, color: "#fff" }}
                        />
                      ) : null} */}
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#f5f5f5" }}>
                      <Box style={{ width: "100%" }}>
                        {item?.textFieldName && item?.textFieldLabel && (
                          <Box order={1} my={2}>
                            {item?.fieldDate ? (
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Date picker inline"
                                value={item?.textFieldLabel || ""}
                                onChange={(e: any) => {
                                  console.log(e);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            ) : (
                              <TextField
                                id={`idTextField-${index}`}
                                label={item?.textFieldLabel || ""}
                                value={
                                  textFieldValHandler(
                                    item.docName,
                                    item.textFieldName
                                  ) || ""
                                }
                                onChange={(e) =>
                                  item?.textFieldName &&
                                  handleChangeFieldValue(
                                    item.docName,
                                    item.textFieldName,
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          </Box>
                        )}
                        {/* {item?.hideFiles ? null : (
                          <Box order={2} my={3} display="flex">
                            <Box>
                              <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                              >
                                Seleccionar Archivo
                                <input
                                  type="file"
                                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                  hidden
                                  onChange={(event) => {
                                    if (
                                      event.target.files &&
                                      event.target.files[0]
                                    ) {
                                      const file = event.target.files[0];
                                      const nameArr = file.name.split(".");
                                      const fileExt =
                                        nameArr[nameArr.length - 1];
                                      // console.log("extension >>> ", fileExt);
                                      handleChangeFileDoc(
                                        item.docName,
                                        item.docFieldName,
                                        file,
                                        fileExt
                                      );
                                    }
                                  }}
                                />
                              </Button>
                              {fileSelected(item.docName) ? (
                                <ArrowUpCircle
                                  size={18}
                                  color="#000"
                                  style={{ marginLeft: 3 }}
                                />
                              ) : null}
                            </Box>
                            <Box ml={2}>
                              <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                                disabled={validateFile(item.docName)}
                                onClick={() => handleTryDownload(item.docName)}
                              >
                                Descargar Archivo
                              </Button>
                            </Box>
                          </Box>
                        )} */}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
          <Grid container item xs={12} sm={12} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Documentación
              </Typography>
            </Grid>
            {documentsList
              .filter((item) => !item.hideFiles)
              .map((item, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ChevronDown color="#fff" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#42529b" }}
                    >
                      <Typography style={{ color: "#fff" }}>
                        {item.docName === "Capacitación Específica"
                          ? item.docName +
                            " (Miper, procedimientos, estándares, reglamentos)"
                          : item.docName}
                      </Typography>
                      {!validateFile(item.docName) ? (
                        <CheckCircle
                          style={{ marginLeft: 10, color: "#fff" }}
                        />
                      ) : null}
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#f5f5f5" }}>
                      <Box style={{ width: "100%" }}>
                        {item?.textFieldName && item?.textFieldLabel && (
                          <Box order={1} my={2}>
                            {item?.fieldDate ? (
                              <TextField
                                id={`fecha-${item.textFieldName}`}
                                label={item?.textFieldLabel || ""}
                                type="date"
                                value={
                                  textFieldValHandler(
                                    item.docName,
                                    item.textFieldName
                                  ) || ""
                                }
                                onChange={(event) => {
                                  item?.textFieldName &&
                                    handleChangeFieldValue(
                                      item.docName,
                                      item.textFieldName,
                                      event.target.value
                                    );
                                }}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            ) : (
                              <TextField
                                id={`idTextField-${index}`}
                                label={item?.textFieldLabel || ""}
                                value={
                                  textFieldValHandler(
                                    item.docName,
                                    item.textFieldName
                                  ) || ""
                                }
                                onChange={(e) =>
                                  item?.textFieldName &&
                                  handleChangeFieldValue(
                                    item.docName,
                                    item.textFieldName,
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          </Box>
                        )}
                        <Box order={2} my={3} display="flex">
                          <Box>
                            <Button
                              variant="outlined"
                              component="label"
                              color="primary"
                            >
                              Seleccionar Archivo
                              <input
                                type="file"
                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                hidden
                                onChange={(event) => {
                                  if (
                                    event.target.files &&
                                    event.target.files[0]
                                  ) {
                                    const file = event.target.files[0];
                                    // const nameArr = file.name.split(".");
                                    const fileExt = getFileNameExtension(
                                      file.name
                                    );
                                    // const fileExt = nameArr[nameArr.length - 1];
                                    // console.log("extension >>> ", fileExt);
                                    handleChangeFileDoc(
                                      item.docName,
                                      item.docFieldName,
                                      file,
                                      fileExt
                                    );
                                  }
                                }}
                              />
                            </Button>
                            {fileSelected(item.docName) ? (
                              <ArrowUpCircle
                                size={18}
                                color="#000"
                                style={{ marginLeft: 3 }}
                              />
                            ) : null}
                          </Box>
                          <Box ml={2}>
                            <Button
                              variant="outlined"
                              component="label"
                              color="primary"
                              disabled={validateFile(item.docName)}
                              onClick={() => handleTryDownload(item.docName)}
                            >
                              Descargar Archivo
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          sm={12}
          style={{ marginTop: 10 }}
        >
          <Button variant="contained" type="submit" color="primary">
            Guardar
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};

export const EditUser = React.memo(() => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <Helmet title="Editar Usuario" />
      <Typography variant="h3" gutterBottom display="inline">
        Editar Usuario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/usuarios/listado">
          Listado
        </Link>
        <Typography>Editar usuario</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="user-tabs"
              >
                <Tab label="Información" {...a11yProps(0)} />
                <Tab label="Otros" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <ContentCardInfo />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContentCardDocs />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
