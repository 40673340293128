// import firebase from "firebase";
import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString, exportToCsv } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "GPSBSNS",
  aclara: "GPS",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

export const exportAlertGps = (filter: filterType = []): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).get();

      const respGps: any[] = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      const datosGps = respGps.map((e) => ({
        FechaCreacion: e.FechaCreacion,
        TiempoSeguimiento: e.TiempoSeguimiento,
        TiempoSinMovimiento: e.TiempoSinMovimiento,
      }));

      exportToCsv(
        "gps.csv",
        datosGps,
        ["FechaCreacion", "TiempoSeguimiento", "TiempoSinMovimiento"],
        ["Fecha de Creacion", "TiempoSeguimiento", "TiempoSinMovimiento"]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getAlertGps = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.GPS_GET_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();

      const respGps = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.GPS_GET_SUCCESS,
        payload: {
          gps: respGps,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.GPS_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las alertas de GPS",
      });
    }
  };
};

export const getMoreAlertGps = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.GPS_GET_SUBMMITING,
    });
    const { user } = getState().authReducer;
    const { totalDocs, gps, lastDoc } = getState().gpsReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestaGps = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.GPS_GET_SUCCESS,
        payload: {
          gps: gps.concat(respuestaGps),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GPS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado de actividades",
      });
    }
  };
};

export const getOneGps = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.GPS_GET_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;

    try {
      const response = await firestore.collection(collName(user)).doc(id).get();
      dispatch({
        type: types.GPS_GET_ONE_SUCCESS,
        payload: response.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.GPS_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error al obtener el alert Gps",
      });
    }
  };
};

export const getGpsFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;

    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      // query = firestore.collection(collName(user)).orderBy("FechaAlert");

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.get();
      const gpsList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.GPS_GET_SUCCESS,
        payload: {
          gps: gpsList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};

export const setSelectedGps = (gps: any): Action => ({
  type: types.GPS_SET_SELECTED,
  payload: gps,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.GPS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.GPS_FAILURE,
  payload: error,
});
