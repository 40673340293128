import * as types from "../../constants";
import { Action } from "../../models/action";
import { AreaModel } from "../../models/area";
import { FormState } from "../../models/form_state";
import firebase from "firebase/app";

export interface AreasState {
  get: {
    state: FormState;
    data: AreaModel[];
    error?: string;
    totalDocs?: number;
    lastDoc?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
  };
  updateCreate: { state: FormState; error?: string };
  areasList: AreaModel[];
  businessAreas: AreaModel[];
}

const initialState = {
  get: { state: FormState.Initial, data: [], totalDocs: 0 },
  updateCreate: { state: FormState.Initial },
  areasList: [],
  businessAreas: [],
} as AreasState;

export const areasReducer = (
  state = initialState,
  action: Action
): AreasState => {
  switch (action.type) {
    case types.AREAS_GET_IN_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          state: FormState.Submitting,
        },
        updateCreate: { state: FormState.Initial },
      };
    case types.AREAS_GET_SUCCESS:
      return {
        ...state,
        get: {
          state: FormState.Success,
          data: action.payload.data,
          totalDocs: action.payload.totalDocs,
          lastDoc: action.payload.lastDoc,
        },
      };
    case types.AREAS_GET_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          state: FormState.Failure,
        },
      };
    case types.AREAS_GET_MORE_SUCCESS:
      return {
        ...state,
        get: {
          state: FormState.Success,
          data: [...state.get.data, ...action.payload.data],
          lastDoc: action.payload.lastDoc,
        },
      };

    case types.AREAS_UPDATE_CREATE_IN_REQUEST:
      return {
        ...state,
        updateCreate: {
          state: FormState.Submitting,
        },
      };
    case types.AREAS_UPDATE_CREATE_SUCCESS:
      return {
        ...state,
        updateCreate: {
          state: FormState.Success,
        },
      };
    case types.AREAS_UPDATE_CREATE_FAILURE:
      return {
        ...state,
        updateCreate: {
          state: FormState.Failure,
          error: action.payload,
        },
      };

    case types.AREAS_SET_ALL_LIST:
      return {
        ...state,
        areasList: action.payload,
      };

    // list of areas related to de business the user belongs
    case types.SET_AREAS_LIST_USER:
      return {
        ...state,
        businessAreas: action.payload,
      };

    default:
      return state;
  }
};
