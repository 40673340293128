import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as rutUtils from "rut.js";
import styled from "styled-components/macro";
import * as yup from "yup";
import { FormState } from "../../models/form_state";
import { SnackState } from "../../models/snack-state";
import { addEmpresa, setAddState } from "../../redux/actions/businessActions";
import { openSnack } from "../../redux/actions/uiActions";
import { BusinessState } from "../../redux/reducers/businessReducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { cleanString } from "../../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    add: { state, error, newId },
  } = useSelector<RootState, BusinessState>((state) => state.businessReducer);

  const [motivoRechazo, setMotivoRechazo] = useState("");

  const { handleSubmit, values, handleChange, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        NombreEmpresa: "",
        RutEmpresa: "",
        EmailEmpresa: "",
        // MutualidadEmpresa: "",
        // TelefonoEmpresa: "",
        // RepresentanteLegal: {
        //   Rut: "",
        //   Telefono: "",
        //   Email: "",
        // },
        // AdministradorContrato: {
        //   Rut: "",
        //   Telefono: "",
        //   Email: "",
        // },
        Activo: false,
        Documentos: "0/0",
        password: "",
        verifyPassword: "",
        Nombre: "",
        Apellido: "",
        Rut: "",
        Email: "",
        Telefono: "",
      } as any,
      onSubmit: (values) => handleCreate(values),
      validationSchema: yup.object({
        NombreEmpresa: yup.string().required("Nombre requerido"),
        RutEmpresa: yup
          .string()
          .min(11)
          .required("Rut es requerido")
          .test({
            name: "Rut",
            message: "Rut no válido",
            test: (value) => {
              if (!value) return false;
              return rutUtils.validate(value);
            },
          }),
        EmailEmpresa: yup
          .string()
          .email("Email no válido")
          .required("Email requerido"),
        // MutualidadEmpresa: yup.string().required("Mutualidad es requerida"),
        // TelefonoEmpresa: yup
        //   .number()
        //   .required("Es requerido")
        //   .test("len", "Número no válido", (val: any) => {
        //     try {
        //       return val.toString().length === 8;
        //     } catch {
        //       return false;
        //     }
        //   })
        //   .required("Número es requerido"),
        // RepresentanteLegal: yup
        //   .object()
        //   .shape({
        //     Rut: yup
        //       .string()
        //       .min(11)
        //       .required("Requerido!")
        //       .test({
        //         name: "Rut",
        //         message: "Rut no válido",
        //         test: (value) => {
        //           if (!value) return false;
        //           return rutUtils.validate(value);
        //         },
        //       }),
        //     Telefono: yup
        //       .number()
        //       .min(10000000)
        //       .max(99999999)
        //       .required("Número es requerido"),
        //     Email: yup
        //       .string()
        //       .email("Email no válido")
        //       .required("Email requerido"),
        //   })
        //   .required("Es requerido"),

        // AdministradorContrato: yup
        //   .object()
        //   .shape({
        //     Rut: yup
        //       .string()
        //       .min(11)
        //       .required("Requerido!")
        //       .test({
        //         name: "Rut",
        //         message: "Rut no válido",
        //         test: (value) => {
        //           if (!value) return false;
        //           return rutUtils.validate(value);
        //         },
        //       }),
        //     Telefono: yup
        //       .number()
        //       .min(10000000)
        //       .max(99999999)
        //       .required("Número es requerido"),
        //     Email: yup
        //       .string()
        //       .email("Email no válido")
        //       .required("Email requerido"),
        //   })
        //   .required("Es requerido"),

        Nombre: yup.string().required("Nombre requerido"),
        Apellido: yup.string().required("Apellido requerido"),
        Rut: yup
          .string()
          .min(11)
          .required("Rut es requerido")
          .test({
            name: "Rut",
            message: "Rut no válido",
            test: (value) => {
              if (!value) return false;
              return rutUtils.validate(value);
            },
          }),
        Telefono: yup
          .number()
          .required("Es requerido")
          .test("len", "Número no válido", (val: any) => {
            try {
              return val.toString().length === 8;
            } catch {
              return false;
            }
          })
          .required("Número es requerido"),
        Email: yup
          .string()
          .email("Email no válido")
          .required("Email requerido"),
        password: yup
          .string()
          .required("Contraseña es requerida")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
            "Mínimo 7 caracteres. Debe contener una letra mayúscula y un número."
          ),
        verifyPassword: yup
          .string()
          .max(255)
          .oneOf([yup.ref("password"), null], "Contraseña no coincide")
          .required("Este campo es requerido"),
      }),
    });

  const handleReject = async () => {
    // const form = {
    //   email: selectedUser?.Email,
    //   mensaje: motivoRechazo,
    // };
    const form = {
      email: "ignaciok98@gmail.com",
      mensaje: motivoRechazo,
    };
    setMotivoRechazo("");
    // dispatch(sendUserEmail(form));

    // updateUserAuth("sokkay.518@gmail.com", { email: "carmond518@gmail.com" });
  };

  const handleCreate = (values: any) => {
    const userForm = {
      TipoPermisos: "Admin",
      Nombre: values.Nombre,
      Nombre_lower: cleanString(values.Nombre),
      Apellido: values.Apellido,
      Apellido_lower: cleanString(values.Apellido),
      Rut: values.Rut,
      Email: values.Email,
      Telefono: Number(values.Telefono),
      EmpresaExterna: true,
      Activo: true,
      Cargo: "Administrador",
      password: values.password,
    };

    const businessForm = {
      Activo: false,
      Progreso: "0/0",
      Nombre: values.NombreEmpresa,
      Nombre_lower: cleanString(values.NombreEmpresa),
      Rut: values.RutEmpresa,
      Email: values.EmailEmpresa,
      // Telefono: Number(values.TelefonoEmpresa),
      // Mutualidad: values.MutualidadEmpresa,
      // RepresentanteLegal: values.RepresentanteLegal,
      // AdministradorContrato: values.AdministradorContrato,
    };

    dispatch(addEmpresa(businessForm, userForm));
  };

  useEffect(() => {
    if (state === FormState.Success) {
      dispatch(openSnack("Empresa creada con éxito", SnackState.SUCCESS));
      if (newId) history.push(`/businesses/editar/${newId}`);
    } else if (state === FormState.Failure && error) {
      dispatch(openSnack(error, SnackState.ERROR));
    }
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch(setAddState(FormState.Initial));
    };
  }, []);

  return (
    <CardContent>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid container item xs={12} sm={12} spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Información
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                id="NombreEmpresa"
                label="Nombre"
                value={values.NombreEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.NombreEmpresa && Boolean(errors.NombreEmpresa)}
                helperText={touched.NombreEmpresa && errors.NombreEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="RutEmpresa"
                label="Rut"
                value={values.RutEmpresa}
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                variant="outlined"
                fullWidth
                error={touched.RutEmpresa && Boolean(errors.RutEmpresa)}
                helperText={touched.RutEmpresa && errors.RutEmpresa}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="EmailEmpresa"
                label="Email"
                type="email"
                value={values.EmailEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.EmailEmpresa && Boolean(errors.EmailEmpresa)}
                helperText={touched.EmailEmpresa && errors.EmailEmpresa}
              />
            </Grid>
            {/* <Grid item xs={12} sm={5}>
              <TextField
                id="TelefonoEmpresa"
                label="Teléfono"
                value={values.TelefonoEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={
                  touched.TelefonoEmpresa && Boolean(errors.TelefonoEmpresa)
                }
                helperText={touched.TelefonoEmpresa && errors.TelefonoEmpresa}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+569</InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={12}>
              <TextField
                id="MutualidadEmpresa"
                label="Mutualidad"
                value={values.MutualidadEmpresa}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={
                  touched.MutualidadEmpresa && Boolean(errors.MutualidadEmpresa)
                }
                helperText={
                  touched.MutualidadEmpresa && errors.MutualidadEmpresa
                }
              />
            </Grid> */}
            {/* <Paper
              variant="elevation"
              elevation={0}
              style={{
                backgroundColor: "rgba(211,211,211, 0.18)",
                width: "100%",
                height: "auto",
                margin: "auto",
                paddingTop: 10,
                paddingLeft: 5,
                paddingRight: 5,
                marginRight: -1,
                paddingBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="RepresentanteLegal.Rut"
                    label="Rut Representante legal"
                    value={values.RepresentanteLegal?.Rut || ""}
                    onChange={(e) => {
                      e.target.value = rutUtils.format(e.target.value);
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Rut: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Rut &&
                      Boolean((errors.RepresentanteLegal as any)?.Rut)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Rut) &&
                      "Rut no válido"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="RepresentanteLegal.Telefono"
                    label="Teléfono Representante legal"
                    value={values.RepresentanteLegal?.Telefono || ""}
                    onChange={(e) => {
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Telefono: Number(e.target.value),
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Telefono &&
                      Boolean((errors.RepresentanteLegal as any)?.Telefono)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Telefono) &&
                      "Teléfono no válido"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="RepresentanteLegal.Email"
                    label="Email Representante legal"
                    value={values.RepresentanteLegal?.Email || ""}
                    onChange={(e) => {
                      setFieldValue("RepresentanteLegal", {
                        ...values.RepresentanteLegal,
                        Email: e.target.value,
                      });
                    }}
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.RepresentanteLegal as any)?.Email &&
                      Boolean((errors.RepresentanteLegal as any)?.Email)
                    }
                    helperText={
                      Boolean((errors.RepresentanteLegal as any)?.Email) &&
                      "Email no válido"
                    }
                  />
                </Grid>
              </Grid>
            </Paper> */}
            {/* <Paper
              variant="elevation"
              elevation={0}
              style={{
                backgroundColor: "rgba(211,211,211, 0.18)",
                width: "100%",
                height: "auto",
                margin: "auto",
                paddingTop: 10,
                paddingLeft: 5,
                paddingRight: 5,
                marginRight: -1,
                paddingBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="AdministradorContrato.Rut"
                    label="Rut Administrador Contrato"
                    value={values.AdministradorContrato?.Rut || ""}
                    onChange={(e) => {
                      e.target.value = rutUtils.format(e.target.value);
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Rut: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Rut &&
                      Boolean((errors.AdministradorContrato as any)?.Rut)
                    }
                    helperText={
                      Boolean((errors.AdministradorContrato as any)?.Rut) &&
                      "Rut no válido"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="AdministradorContrato.Telefono"
                    label="Teléfono Administrador Contrato"
                    value={values.AdministradorContrato?.Telefono || ""}
                    onChange={(e) => {
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Telefono: Number(e.target.value),
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Telefono &&
                      Boolean((errors.AdministradorContrato as any)?.Telefono)
                    }
                    helperText={
                      Boolean(
                        (errors.AdministradorContrato as any)?.Telefono
                      ) && "Teléfono no válido"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="AdministradorContrato.Email"
                    label="Email Administrador Contrato"
                    value={values.AdministradorContrato?.Email || ""}
                    onChange={(e) => {
                      setFieldValue("AdministradorContrato", {
                        ...values.AdministradorContrato,
                        Email: e.target.value,
                      });
                    }}
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={
                      (touched.AdministradorContrato as any)?.Email &&
                      Boolean((errors.AdministradorContrato as any)?.Email)
                    }
                    helperText={
                      Boolean((errors.AdministradorContrato as any)?.Email) &&
                      "Email no válido"
                    }
                  />
                </Grid>
              </Grid>
            </Paper> */}
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Usuario
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Nombre"
                label="Nombre"
                value={values.Nombre}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Nombre && Boolean(errors.Nombre)}
                helperText={touched.Nombre && errors.Nombre}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Apellido"
                label="Apellido"
                value={values.Apellido}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Apellido && Boolean(errors.Apellido)}
                helperText={touched.Apellido && errors.Apellido}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Rut"
                label="Rut"
                value={values.Rut}
                onChange={(e) => {
                  e.target.value = rutUtils.format(e.target.value);
                  handleChange(e);
                }}
                variant="outlined"
                fullWidth
                error={touched.Rut && Boolean(errors.Rut)}
                helperText={touched.Rut && errors.Rut}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Telefono"
                label="Teléfono"
                value={values.Telefono}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Telefono && Boolean(errors.Telefono)}
                helperText={touched.Telefono && errors.Telefono}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+569</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="Email"
                label="Email"
                type="email"
                value={values.Email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.Email && Boolean(errors.Email)}
                helperText={touched.Email && errors.Email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                label="Contraseña"
                value={values.password}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="verifyPassword"
                label="Verificar Contraseña"
                value={values.verifyPassword}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.verifyPassword && Boolean(errors.verifyPassword)}
                helperText={touched.verifyPassword && errors.verifyPassword}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-end"
          sm={12}
          style={{ marginTop: 10 }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={
              state === FormState.Submitting || state === FormState.Success
            }
          >
            Guardar
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};

export const NewBusiness = React.memo(() => {
  return (
    <React.Fragment>
      <Helmet title="Nueva Empresa" />
      <Typography variant="h3" gutterBottom display="inline">
        Nueva Empresa
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/businesses">
          Listado
        </Link>
        <Typography>Crear</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
