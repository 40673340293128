import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { IActividades } from "../redux/reducers/actividadesReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import {
  addComent,
  getOneActividad,
} from "../redux/actions/actividadesActions";
import { exportToCsv, timesStampFormattedsimple } from "../utils/utils";
import { IAuthState } from "../redux/reducers/authReducer";
import { Download } from "react-feather";
import { Alert } from "@material-ui/lab";
import { IndeterminateCheckBoxOutlined } from "@material-ui/icons";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const [inputComent, setInputComent] = useState("");
  const [listacoment, setlistacoment] = useState<any[]>([]);

  const dispatch = useDispatch();
  const { actividadId } = useParams<any>();

  const handleComent = (e: any) => {
    setInputComent(e.target.value);
  };

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );
  const { selectedActividad, totalDocs, state } = useSelector<
    RootState,
    IActividades
  >((state) => state.actividadReducer);

  useEffect(() => {
    if (!selectedActividad) {
      dispatch(getOneActividad(actividadId));
    }
  }, [selectedActividad, actividadId]);

  useEffect(() => {
    if (selectedActividad?.Comentarios) {
      setlistacoment(selectedActividad?.Comentarios);
    }
  }, [selectedActividad]);
  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Tema:</b>
                <br />
                {selectedActividad?.Tema}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Empresa:</b>
                <br />
                {selectedActividad?.Empresa}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Area:</b> <br />
                {selectedActividad?.Area}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Tipo de actividad:</b>
                <br />
                {selectedActividad?.TipoActividad}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Competencias/Contenido:</b>
                <br />
                {selectedActividad?.CompetenciaContenido}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Observaciones:</b>
                <br />
                {selectedActividad?.Observaciones}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Fecha De Actividad</b>:<br />
                {timesStampFormattedsimple(
                  selectedActividad?.FechaActividad.toDate()
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Lugar:</b>
                <br />
                {selectedActividad?.Lugar}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                {" "}
                <b>Participantes:</b>
              </Typography>
              {selectedActividad?.Participantes.map((e: any) => (
                <Typography key={e.id}>
                  <b>Nombre:</b>
                  {e.Nombre}
                  <br />
                  <b>Rut:</b> {e.Rut}
                  <br />
                  <br />
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                {" "}
                <b>Relatores:</b>
              </Typography>
              {selectedActividad?.Relatores.map((e: any) => (
                <Typography key={e.id}>
                  <b>Nombre:</b>
                  {e.Nombre}
                  <br />
                  <b>Rut:</b> {e.Rut}
                  <br />
                  <br />
                </Typography>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography style={{ marginBottom: 10 }} variant="h6">
            Caja de comentarios
          </Typography>
          <Grid container spacing={1}>
            {user?.tipoUsuario === "Jefe" || user?.tipoUsuario === "Admin" ? (
              <Grid style={{ marginBottom: 20 }} item xs={12} sm={6}>
                <TextField
                  id="outlined-textarea"
                  placeholder="Agregar Comentario"
                  label="Agregar Comentario"
                  fullWidth
                  rows="6"
                  value={inputComent}
                  onChange={handleComent}
                  multiline
                  variant="outlined"
                />
                {listacoment?.length >= 5 ? (
                  <Alert
                    severity="error"
                    icon={false}
                    style={{
                      marginTop: 6,
                      width: 400,
                      marginBottom: 20,
                      color: "#f09c9c",
                      fontWeight: "bold",
                    }}
                  >
                    Limite de Comentarios Alcanzado...
                  </Alert>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: 5 }}
                    onClick={() => {
                      dispatch(addComent(inputComent, actividadId));
                      listacoment.push({
                        Comentario: inputComent,
                        FirebaseId: user?.id,
                        NombreUsuario: user?.name,
                        TipoUsuario: user?.tipoUsuario,
                      });
                      setInputComent("");
                    }}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            ) : (
              ""
            )}
            <Grid container>
              {listacoment?.map((e: any) => {
                return (
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Alert
                        icon={false}
                        severity="info"
                        style={{ width: 200 }}
                      >
                        <b>Tipo de Usuario:</b> {e?.TipoUsuario} <br />
                        <b> Nombre:</b> {e?.NombreUsuario}
                      </Alert>
                      <br />
                      <Alert
                        severity="success"
                        icon={false}
                        style={{
                          marginLeft: 20,
                          width: 400,
                          marginBottom: 20,
                        }}
                      >
                        {e.Comentario}
                      </Alert>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export const ActividadesView = () => (
  <React.Fragment>
    <Helmet title=" Detalle Actividad" />
    <Typography variant="h3" gutterBottom display="inline">
      Detalles De La Actividad
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} exact to="/gestionActividades">
        Lista de actividades
      </Link>
      <Typography>Detalle</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
