import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface AnswersState {
  answersList: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedAnswers?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  answersList: [],
  state: FormState.Initial,
  totalDocs: undefined,
  lastDoc: undefined,
  selectedAnswers: null,
  delete: {
    state: FormState.Initial,
  },
} as AnswersState;

export const answersReducer = (
  state = initialState,
  action: Action
): AnswersState => {
  switch (action.type) {
    case types.ANSWERS_GET_SUCCESS:
      return {
        state: FormState.Success,
        answersList: action.payload.answers,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.ANSWERS_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.ANSWERS_GET_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.ANSWERS_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
      };
    case types.ANSWERS_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ANSWERS_SET_SELECTED:
      return {
        ...state,
        selectedAnswers: action.payload,
      };
    case types.ANSWERS_GET_MORE_SUCCESS:
      return {
        ...state,
        answersList: [...state.answersList, ...action.payload.answers],
        lastDoc: action.payload.lastDoc,
        state: FormState.Success,
      };
    default:
      return state;
  }
};
