import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface IGps {
  gps: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedGps?: any;
  edit?: {
    state: FormState;
    error?: string;
  };
  delete?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  gps: [],
  state: FormState.Initial,
  totalDocs: undefined,
  selectedGps: null,
  lastDoc: undefined,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as IGps;

export const gpsReducer = (state = initialState, action: Action): IGps => {
  switch (action.type) {
    case types.GPS_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        gps: action.payload.gps,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.GPS_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.GPS_GET_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
      case types.GPS_SET_SELECTED:
        return {
          ...state,
          selectedGps: action.payload
        };
        case types.GPS_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
      case types.GPS_GET_ONE_SUCCESS:
        return {
          ...state,
          edit: {
            state: FormState.Success
          },
          selectedGps: action.payload
        };
        case types.GPS_GET_ONE_FAILURE:
          return {
            ...state,
            edit: {
              state: FormState.Failure,
              error: action.payload
            },
           
          };
    default:
      return state;
  }
};