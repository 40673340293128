// import firebase from "firebase";
import firebase from "firebase";
import * as types from "../../constants";
import { firestore, storage } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { Incidentes } from "../../pages/Incidentes";
import {
  cleanString,
  exportToCsv,
  lowerFieldValidation,
} from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "IncidentesBSNS",
  aclara: "Incidentes",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export const exportIncidentes = (
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).get();
      const incidentes: any[] = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      const datosIncidentes = incidentes.map((e) => ({
        Usuario: JSON.stringify(e?.Usuario?.NombreCompleto),
        Descripcion: e?.Descripcion,
        Estado: e?.Estado,
        FechaCreacion: e?.FechaCreacion,
        Imagen0: e?.Imagen0,
        Imagen1: e?.Imagen1,
        Lugar: e?.Lugar,
      }));
      exportToCsv(
        "incidentes.csv",
        datosIncidentes,
        [
          "Descripcion",
          "Estado",
          "FechaCreacion",
          "Imagen0",
          "Imagen1",
          "Lugar",
          "Usuario",
          "Revision",
        ],
        [
          "Descripcion",
          "Estado",
          "FechaCreacion",
          "Imagen0",
          "Imagen1",
          "Lugar",
          "Usuario",
          "Revision",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getIncidentes = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_GET_IS_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();
      const respuesIncidentes = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.INCIDENTES_GET_SUCCESS,
        payload: {
          incidentes: respuesIncidentes,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.INCIDENTES_GET_IS_FAILURE,
        payload: "Ha ocurrido un error al cargar los Incidentes",
      });
    }
  };
};

export const getMoreIncidentes = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_GET_IS_SUBMMITING,
    });
    const { totalDocs, incidentes, lastDoc } = getState().incidentesReducer;
    const { user } = getState().authReducer;

    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestasIncidentes = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.INCIDENTES_GET_SUCCESS,
        payload: {
          incidentes: incidentes.concat(respuestasIncidentes),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.INCIDENTES_GET_IS_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado de los incidentes",
      });
    }
  };
};

export function updateComentIncidentRevision(
  incidente: any,
  content: { comentario: string; area: string },
  estado: string,
  area: { id?: string; Nombre: string }
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_UPDATE_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      const dateNow = firebase.firestore.Timestamp.now();
      await firestore
        .collection(collName(user))
        .doc(incidente.id)
        .update({
          Estado: estado,
          Area: area,
          Revision: firebase.firestore.FieldValue.arrayUnion({
            Comentario: content.comentario,
            FechaRevision: dateNow,
            UsuarioId: user.id,
            UsuarioNombreCompleto: user.name,
            UsuarioNombreCompleto_lower: cleanString(user.name),
            Area: content.area,
            tipoUsuario: user?.tipoUsuario || " - ",
          }),
        });

      dispatch({
        type: types.INCIDENTES_UPDATE_ONE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.INCIDENTES_UPDATE_ONE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export function updateComentIncidentCierre(
  incidente: any,
  comentario: string,
  estado: string,
  imagenes: { file: any; ext: string }[] = []
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_UPDATE_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      let imgArr: any[] = [];
      let imgNum = 0;
      for (const img of imagenes) {
        if (img.file) {
          const path =
            `Incidentes/${incidente.id}/Imagenes/imagen${imgNum}` +
            "." +
            img.ext;
          const imgRef = storage.ref(path);
          await imgRef.put(img.file);
          const url = await imgRef.getDownloadURL();
          imgArr.push({ Path: path, Url: url });
          imgNum++;
        }
      }
      await firestore
        .collection(collName(user))
        .doc(incidente.id)
        .update({
          Estado: estado,
          CierreIncidente: {
            ComentarioCierre: comentario,
            FechaRevision: firebase.firestore.FieldValue.serverTimestamp(),
            UsuarioId: user.id,
            UsuarioNombreCompleto: user.name,
            UsuarioNombreCompleto_lower: cleanString(user.name),
            tipoUsuario: user?.tipoUsuario || " - ",
            Imagenes: imgArr,
          },
        });
      dispatch({
        type: types.INCIDENTES_UPDATE_ONE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.INCIDENTES_UPDATE_ONE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export function updateRevisionYRecomendacion(
  incidente: any,
  comentario: string,
  estado: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_UPDATE_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      const response = await firestore
        .collection(collName(user))
        .doc(incidente.id)
        .update({
          Estado: estado,
          RevisionYRecomendacion: {
            RevisonRecomendacion: comentario,
            FechaRevision: firebase.firestore.FieldValue.serverTimestamp(),
            UsuarioId: user.id,
            UsuarioNombreCompleto: user.name,
            UsuarioNombreCompleto_lower: cleanString(user.name),
          },
        });

      dispatch(
        openSnack("Se agreago comentario exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.INCIDENTES_UPDATE_ONE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export const getIncidentFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy(order, "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.get();
      const incidentesList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));
      // dispatch(setIncidentes(incidentesList));
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));

      dispatch({
        type: types.INCIDENTES_GET_SUCCESS,
        payload: {
          incidentes: incidentesList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};

export const getOneIncidente = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.INCIDENTES_GET_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;

    try {
      const response = await firestore.collection(collName(user)).doc(id).get();

      dispatch({
        type: types.INCIDENTES_GET_ONE_SUCCESS,
        payload: { ...response.data() },
      });
    } catch (error: any) {
      dispatch({
        type: types.INCIDENTES_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error eliminando el incidente",
      });
    }
  };
};

export const setSelectedIncidente = (incidente: any): Action => ({
  type: types.INCIDENTES_SET_SELECTED,
  payload: incidente,
});
const setIncidentes = (incidentes: any[]): Action => ({
  type: types.INCIDENTES_GET_DOCS,
  payload: incidentes,
});

const setLastDoc = (doc: any): Action => ({
  type: types.INCIDENTES_SET_LAST_DOC,
  payload: doc,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.INCIDENTES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.INCIDENTES_FAILURE,
  payload: error,
});
