import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { getUserInformation } from "../../redux/actions/information.Actions";
import logo from "../../vendor/icon.png";
import { CheckCircle, User, XCircle } from "react-feather";
import { cleanString } from "../../utils/utils";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
`;

function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function QRView() {
  const dispatch = useDispatch();
  const { selectedId } = useParams();
  const history = useHistory();

  const { basicInfo, documentsInfo } = useSelector(
    (state) => state.informationReducer
  );

  const [nombreEmpresa, setNombre] = useState("");
  const [minWidth, setMin] = useState(0);

  useEffect(() => {
    if (selectedId) {
      const bsnsName = getParameterByName("bsns");
      if (bsnsName) setNombre(bsnsName.replaceAll("_", " "));
      dispatch(getUserInformation(selectedId));
    }
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      console.log("mobile");
    } else {
      setMin(900);
    }
  }, [selectedId]);

  const documentsList = [
    //  docName Always required
    //  docFieldNameAlways required
    {
      docName: "Cedula de Identidad",
      textFieldLabel: "",
      textFieldName: "",
      docFieldName: "CedulaIdentidad",
    },
    {
      docName: "Examen de Salud",
      textFieldLabel: "Fecha de vencimiento de examen",
      textFieldName: "fechavencimientoexamen",
      fieldDate: true,
      docFieldName: "ExamenSalud",
    },
    // {
    //   docName: "Anexo de Contrato o Trabajo",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "AnexoContratoTrabajo",
    // },
    {
      docName: "Certificado Psicosensotecnico",
      textFieldLabel: "Fecha de vencimiento",
      textFieldName: "fechavencpsicosensotecnico",
      fieldDate: true,
      docFieldName: "CertificadoPsicosensotecnico",
    },
    // {
    //   docName: "Entrega EPP",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "EntregaEPP",
    // },
    // {
    //   docName: "Capacitación Específica",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "CapacitacionEspecifica",
    // },
    // {
    //   docName: "Entrega de RIOHS",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "EntregaRIOHS",
    // },
    // {
    //   docName: "Certificado de Curso 4x4",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "CertificadoCurso4x4",
    // },
    // {
    //   docName: "Certificado de Curso Específico",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "CertificadoCursoEspecifico",
    // },
    {
      docName: "Licencia de Conducir",
      textFieldLabel: "Fecha de vencimiento y clase de licencia",
      textFieldName: "licenciaconductorfechavencyclase",
      docFieldName: "LicenciaConducir",
    },
    // {
    //   docName: "Oblicación Informar DS40",
    //   textFieldLabel: "",
    //   textFieldName: "",
    //   docFieldName: "ObligacionInformarDS40",
    // },
    // {
    //   docName: "Alera",
    //   textFieldLabel: "Alera",
    //   textFieldName: "alera",
    //   docFieldName: "alera",
    //   hideFiles: true,
    // },
    {
      docName: "ROL",
      textFieldLabel: "ROL",
      textFieldName: "rol",
      docFieldName: "rol",
      hideFiles: true,
    },
  ];

  if (!basicInfo) return <></>;
  if (!basicInfo?.Aprobado) return <>No Aprobado</>;

  return (
    <Wrapper>
      <Helmet title="Información" />
      <Box m={2} display="flex" justifyContent="center">
        <img src={logo} style={{ maxHeight: 60 }} alt="img-logo" />
      </Box>

      <Card variant="outlined" style={{ marginTop: 20, minWidth: minWidth }}>
        <CardContent>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Información
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Fotografía:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      style={{
                        height: 120,
                        width: 120,
                        borderRadius: 10,
                        alignSelf: "center",
                        overflow: "hidden",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          alignSelf: "center",
                        }}
                      >
                        {basicInfo.Avatar ? (
                          <img
                            src={
                              basicInfo.Avatar instanceof File
                                ? URL.createObjectURL(basicInfo.Avatar)
                                : basicInfo.Avatar.Url
                            }
                            style={{
                              height: 120,
                              width: 120,
                              objectFit: "cover",
                            }}
                            alt={"UploadImage"}
                          />
                        ) : (
                          <User size={30} />
                        )}
                      </label>
                    </Paper>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Usuario:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {basicInfo?.Nombre + " " + basicInfo?.Apellido}
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Empresa:
                    </Typography>
                  </TableCell>
                  <TableCell>{nombreEmpresa}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Área:
                    </Typography>
                  </TableCell>
                  <TableCell>{basicInfo?.Area}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Inducción Completa:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {basicInfo?.InduccionCompleta ? (
                      <CheckCircle color="green" />
                    ) : (
                      <XCircle color="red" />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Card variant="outlined" style={{ marginTop: 20, minWidth: minWidth }}>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                {documentsList.map((item, index) => {
                  const name = cleanString(item.docName).replaceAll(" ", "");
                  let text = "";
                  if (name in documentsInfo) {
                    const objItem = documentsInfo[name];
                    if (item.textFieldName in objItem) {
                      const textValue = objItem[item.textFieldName];
                      text = textValue;
                    }
                  }
                  return (
                    <TableRow hover key={"doc-info-row" + index}>
                      <TableCell>
                        <Typography style={{ fontWeight: "bold" }}>
                          {item.docName === "Capacitación Específica"
                            ? item.docName +
                              " (Miper, procedimientos, estándares, reglamentos)"
                            : item.docName}
                          {name in documentsInfo && text ? (
                            <Typography style={{ fontWeight: "lighter" }}>
                              {`\n${item.textFieldLabel}: ${text}`}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {typeof documentsInfo === "object" &&
                        name in documentsInfo ? (
                          <CheckCircle color="green" />
                        ) : (
                          <XCircle color="red" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default QRView;
