// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const SNACK_DURATION = 3000;
export const VERSION = "v1.1.3";
export const TABLE_LIMITS = [5, 10, 20, 30, 50];
export const TABLE_LIMIT_DEFAULT = TABLE_LIMITS[1];

//Maps Key
export const MAPS_API_KEY = "AIzaSyCOk8Qr49hgKFBno3_kPh482gpc1h0rctg";

//Redux

//UI
export const UI_SNACK_STATE_CHANGE = "[UI] Snack state change";
export const UI_SNACK_OPEN = "[UI] Snack open change";
export const UI_SNACK_TEXT_CHANGE = "[UI] Snack text change";

export const UI_SNACK_STATE_CHANGE_LOGIN = "[UI] Snack state change login";
export const UI_SNACK_OPEN_LOGIN = "[UI] Snack open change login";
export const UI_SNACK_TEXT_CHANGE_LOGIN = "[UI] Snack text change login";

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Empresa
// export const BUSINESS_GET_DOCS = "[Business] Get Docs";
// export const BUSINESS_ADD_DOCS = "[Business] Add Docs";
// export const BUSINESS_SET_LAST_DOC = "[Business] Set last Doc";
// export const BUSINESS_SET_TOTAL_DOCS = "[Business] Set Total Docs";
// export const BUSINESS_LOADING = "[Business] Is Loading";
// export const BUSINESS_UPDATE_LOADING = "[Business] Is update loading";
// export const BUSINESS_UPDATE_SELECTED = "[Business] Update selected business";
// export const BUSINESS_FAILURE = "[Business] Failure";
// export const BUSINESS_SET_SELECTED = "[Business] Set Selected business";

// Tours
export const TOURS_GET_DOCS = "[Tours] Get Docs";
export const TOURS_ADD_DOCS = "[Tours] Add Docs";
export const TOURS_SET_LAST_DOC = "[Tours] Set last Doc";
export const TOURS_SET_TOTAL_DOCS = "[Tours] Set Total Docs";
export const TOURS_LOADING = "[Tours] Is Loading";
export const TOURS_UPDATE_LOADING = "[Tours] Is update loading";
export const TOURS_CHANGE_FORM_STATE = "[Tours] Change Form State";
export const TOURS_UPDATE_DOC = "[Tours] Update doc";
export const TOURS_REMOVE_DOC = "[Tours] Delete doc";
export const TOURS_UPDATE_SELECTED = "[Tours] Update selected business";
export const TOURS_FAILURE = "[Tours] Failure";
export const TOURS_SET_SELECTED = "[Tours] Set Selected business";

// Regions
export const REGIONS_GET_DOCS = "[Regions] Get Docs";
export const REGIONS_LOADING = "[Regions] Is Loading";
export const REGIONS_FAILURE = "[Regions] Failure";

// Tour Types
export const TOUR_TYPES_GET_DOCS = "[Tour Types] Get Docs";
export const TOUR_TYPES_LOADING = "[Tour Types] Is Loading";
export const TOUR_TYPES_FAILURE = "[Tour Types] Failure";

// Tour Guides
export const TOUR_GUIDES_GET_DOCS = "[Tour TourGuides] Get Docs";
export const TOUR_GUIDES_ADD_DOCS = "[Tour TourGuides] Add Docs";
export const TOUR_GUIDES_SET_LAST_DOC = "[Tour TourGuides] Set last Doc";
export const TOUR_GUIDES_SET_TOTAL_DOCS = "[Tour TourGuides] Set Total Docs";
export const TOUR_GUIDES_LOADING = "[Tour TourGuides] Is Loading";
export const TOUR_GUIDES_UPDATE_LOADING = "[Tour TourGuides] Is update loading";
export const TOUR_GUIDES_UPDATE_DOC = "[Tour TourGuides] Update doc";
export const TOUR_GUIDES_REMOVE_DOC = "[Tour TourGuides] Delete doc";
export const TOUR_GUIDES_UPDATE_SELECTED =
  "[Tour TourGuides] Update selected business";
export const TOUR_GUIDES_FAILURE = "[Tour TourGuides] Failure";
export const TOUR_GUIDES_SET_SELECTED =
  "[Tour TourGuides] Set Selected business";
export const TOUR_GUIDES_CHANGE_DIALOG_ACTION =
  "[Tour TourGuides] Change Dialog Action";
export const TOUR_GUIDES_IS_DIALOG_OPEN = "[Tour TourGuides] Is Dialog open";
export const TOUR_GUIDES_REGIONS_GET = "[Tour Regions] Get Regions";

// Clientes
export const CLIENTS_GET_DOCS = "[Clients] Get Docs";
export const CLIENTS_ADD_DOCS = "[Clients] Add Docs";
export const CLIENTS_SET_LAST_DOC = "[Clients] Set last Doc";
export const CLIENTS_SET_TOTAL_DOCS = "[Clients] Set Total Docs";
export const CLIENTS_LOADING = "[Clients] Is Loading";
export const CLIENTS_UPDATE_LOADING = "[Clients] Is update loading";
export const CLIENTS_UPDATE_DOC = "[Clients] Update doc";
export const CLIENTS_REMOVE_DOC = "[Clients] Delete doc";
export const CLIENTS_UPDATE_SELECTED = "[Clients] Update selected client";
export const CLIENTS_FAILURE = "[Clients] Failure";
export const CLIENTS_SET_SELECTED = "[Clients] Set Selected client";
export const CLIENTS_GET_SELECTED = "[Clients] Get Selected client";

// Usuarios
export const USERS_GET_DOCS = "[Users] Get Docs";
export const USERS_ADD_DOCS = "[Users] Add Docs";
export const USERS_SET_LAST_DOC = "[Users] Set last Doc";
export const USERS_SET_TOTAL_DOCS = "[Users] Set Total Docs";
export const USERS_LOADING = "[Users] Is Loading";
export const USERS_FAILURE = "[Users] Failure";
export const USERS_EDIT_LOADING = "[Users] Edit is loading";
export const USERS_SET_SELECTED = "[Users] Set selected";
export const USERS_UPDATE_DOC = "[Users] Update doc";
export const USERS_UPDATE_SELECTED_USER = "[Users] Update selected doc";
export const USERS_REMOVE_DOC = "[Users] Delete doc";

// Bloqueos Guia
export const BLOCK_GUIDES_SET_SELECTED = "[Block] Set selected block guide";

// BUTTON

export const BUTTON_PLUS = "[Button Plus] Sum number";
export const BUTTON_MINUS = "[Button Minus] Minus number";

// Cargos - Postion Charges

export const CHARGES_IS_SUBMITTING = "[PositionCharges] Is Submitting";
export const CHARGES_SUCCESS = "[PositionCharges] Get Charges Success";
export const CHARGES_FAILURE = "[PositionCharges] Failure ";
export const CHARGES_SET_TOTAL_DOCS = "[PositionCharges] Set Total Docs";
export const CHARGES_SET_LAST_DOC = "[PositionCharges] Set Last Doc";
export const CHARGES_GET_DOCS = "[PositionCharges] Get Docs";
export const CHARGES_EDITING = "[PositionCharges] Is Submitting Edit";
export const CHARGES_EDITING_SUCCESS = "[PositionCharges] Success Edit";
export const CHARGES_EDITING_FAILURE = "[PositionCharges] Failure Edit";
export const CHARGES_SET_LIST_ALL = "[PositionCharges] Set all charges list";

// covid forms add
export const COVID_FORM_INITIAL = "[Covid Forms] Covid Forms Initial";
export const COVID_FORM_SUCCESS = "[Covid Forms] Covid Forms Success";
export const COVID_FORM_FAILURE = "[Covid Forms] Covid Forms Failure";
export const COVID_FORM_IS_SUBMITTING =
  "[Covid Forms] Covid Forms Is Submitting";
export const COVID_FORM_ADD_SUCCESS = "[Covid Forms] Covid Forms Add Success";
export const COVID_FORM_ADD_FAILURE = "[Covid Forms] Covid Forms Add Failure";
export const COVID_FORM_ADD_IS_SUBMITTING =
  "[Covid Forms] Covid Forms Add submitting";
export const COVID_FORM_REMOVE_SUCCES =
  "[Covid Forms] Covid Forms Remove Success";
export const COVID_FORM_REMOVE_FAILURE =
  "[Covid Forms] Covid Forms Remove Failure";
export const COVID_FORM_REMOVE_SUBMITTING =
  "[Covid Forms] Covid Forms Remove submitting";
export const COVID_FORM_CLONE = "[Covid Forms] Covid Forms clone";
export const COVID_FORM_ANSWERS_IS_SUBMITTING =
  "[Covid Forms] Covid Form Get One Submitting";
export const COVID_FORM_ANSWERS_SUCCESS =
  "[Covid Forms] Covid Form Get One Success";
export const COVID_FORM_ANSWERS_FAILURE =
  "[Covid Forms] Covid Form Get One Failure";
export const COVID_FORM_IS_LOADING = "[Covid Forms] Covid Form is loading";
export const COVID_FORM_IS_FAILURE = "[Covid Forms] Covid Form is failure";

// Answers consts
export const ANSWERS_GET_IS_SUBMITTING = "[Answers] Get is submitting";
export const ANSWERS_GET_SUCCESS = "[Answers] Get success";
export const ANSWERS_GET_MORE_SUCCESS = "[Answers] Get More success";
export const ANSWERS_GET_FAILURE = "[Answers] Get failure";
export const ANSWERS_DELETE_SUCCESS = "[Answers] Delete success";
export const ANSWERS_DELETE_FAILURE = "[Answers] Delete failure";
export const ANSWERS_SET_SELECTED = "[Answers] Set selected";

// checkList consts
export const CHECKLISTS_GET_IS_SUBMITTING =
  "[CheckList]  checklist is submitting";
export const CHECKLISTS_GET_SUCCESS = "[CheckList]  checklist is success";
export const CHECKLISTS_GET_FAILURE = "[CheckList]  checklist is failure";
export const CHECKLISTS_DELETE_SUCCESS =
  "[CheckList]  checklist delete success";
export const CHECKLISTS_DELETE_FAILURE =
  "[CheckList]  checklist delete failure";
export const CHECKLIST_DELETE_SET_STATE =
  "[CHECKLIST] Set status delete checklist";
export const CHECKLISTS_SET_SELECTED = "[CheckList]  checklist set selected";
export const CHECKLISTS_GET_ONE_SUBMITTING =
  "[CheckList]  checklict get one submitting";
export const CHECKLISTS_GET_ONE_SUCCESS =
  "[CheckList]  checklict get one success";
export const CHECKLISTS_GET_ONE_FAILURE =
  "[CheckList]  checklict get one failure";
export const CHECKLISTS_ADD_ONE_IS_SUBMMITING =
  "[CheckList]  checklict add one submmiting";
export const CHECKLISTS_ADD_ONE_IS_SUCCESS =
  "[CheckList]  checklict add one success";
export const CHECKLISTS_ADD_ONE_IS_FAILURE =
  "[CheckList]  checklict add one failure";
export const CHECKLISTS_SET_TOTAL_DOCS =
  "[CheckList]  checklict set total docs";
export const CHECKLISTS_SET_LAST_DOCS = "[CheckList]  checklict set last docs";
export const CHECKLISTS_GET_DOCS = "[CheckList]  checklict get docs";
export const CHECKLISTS_LOADING = "[CheckList]  checklist loading";
export const CHECKLISTS_FAILURE = "[CheckList]  checklist failure";

// incidencias const
export const INCIDENTES_GET_SUCCESS = "[Incidentes]  incidentes get success";
export const INCIDENTES_GET_IS_SUBMMITING =
  "[Incidentes]  incidentes get is submitting";
export const INCIDENTES_GET_IS_FAILURE =
  "[Incidentes]  incidentes get is failure";
export const INCIDENTES_DELETE_SUCCESS =
  "[Incidentes]  incidentes delete is success";
export const INCIDENTES_DELETE_IS_FAILURE =
  "[Incidentes]  incidentes delete is failure";
export const INCIDENTES_SET_SELECTED = "[Incidentes]  incidentes set selected";
export const INCIDENTES_GET_ONE_SUBMMITING =
  "[Incidentes]  incidentes get one is submmiting";
export const INCIDENTES_GET_ONE_SUCCESS =
  "[Incidentes]  incidentes get one success";
export const INCIDENTES_GET_ONE_FAILURE =
  "[Incidentes]  incidentes get one failure";
export const INCIDENTES_UPDATE_ONE_SUCCESS =
  "[Incidentes]  incidentes update one success";
export const INCIDENTES_UPDATE_ONE_SUBMMITING =
  "[Incidentes]  incidentes update one submmiting";
export const INCIDENTES_UPDATE_ONE_FAILURE =
  "[Incidentes]  incidentes update one submmiting";
export const INCIDENTES_GET_DOCS = "[Incidentes]  incidentes get docs";
export const INCIDENTES_SET_LAST_DOC = "[Incidentes]  incidentes set last docs";
export const INCIDENTES_LOADING = "[Incidentes]  incidentes loading";
export const INCIDENTES_FAILURE = "[Incidentes]  incidentes failure";

// Actividades const
export const ACTIVIDADES_GET_SUCCESS = "[Actividades]  actividades get success";
export const ACTIVIDADES_GET_SUBMMITING =
  "[Actividades]  actividades get submmiting";
export const ACTIVIDADES_GET_FAILURE = "[Actividades]  actividades get failure";
export const ACTIVIDADES_SET_SELECTED =
  "[Actividades]  actividades set selected";
export const ACTIVIDADES_GET_ONE_SUCCES =
  "[Actividades]  actividades get one success";
export const ACTIVIDADES_GET_ONE_IS_SUBMMITING =
  "[Actividades]  actividades get one is submmiting";
export const ACTIVIDADES_GET_ONE_IS_FAILURE =
  "[Actividades]  actividades get one is failure";
export const ACTIVIDADES_ADD_COMENT_IS_SUBMMITING =
  "[Actividades]  actividades add coment is sibmmiting";
export const ACTIVIDADES_ADD_COMENT_IS_SUCCESS =
  "[Actividades]  actividades add coment is success";
export const ACTIVIDADES_ADD_COMENT_IS_FAILURE =
  "[Actividades]  actividades add coment is failure";
export const ACTIVIDADES_LOADING = "[Actividades]  actividades loading";
export const ACTIVIDADES_FAILURE = "[Actividades]  actividades failure";

// Analisis de riegos de actividades (art)
export const ARIESGO_GET_SUCCESS = "[Ariesgo]  ariesgo get success";
export const ARIESGO_GET_FAILURE = "[Ariesgo]  ariesgo get failure";
export const ARIESGO_GET_SUBMMITING = "[Ariesgo]  ariesgo get submmiting";
export const ARIESGO_SET_SELECTED = "[Ariesgo]  ariesgo set selected";
export const ARIESGO_GET_ONE_SUCCESS = "[Ariesgo]  ariesgo get one success";
export const ARIESGO_GET_ONE_SUBMMITING =
  "[Ariesgo]  ariesgo get one submmiting";
export const ARIESGO_GET_ONE_FAILURE = "[Ariesgo]  ariesgo get one failure";
export const ARIESGO_LOADING = "[Ariesgo]  ariesgo loading";
export const ARIESGO_FAILURE = "[Ariesgo]  ariesgo failure";

// Alerta GPS
export const GPS_GET_SUCCESS = "[Gps]  gps get success";
export const GPS_GET_SUBMMITING = "[Gps]  gps get submmiting";
export const GPS_GET_FAILURE = "[Gps]  gps get failure";
export const GPS_SET_SELECTED = "[Gps]  gps set selected";
export const GPS_GET_ONE_SUBMMITING = "[Gps]  gps get one submmiting";
export const GPS_GET_ONE_SUCCESS = "[Gps]  gps get one success";
export const GPS_GET_ONE_FAILURE = "[Gps]  gps get one failure";
export const GPS_LOADING = "[Gps]  gps loading";
export const GPS_FAILURE = "[Gps]  gps failure";

/// AREAS
export const AREAS_GET_IN_REQUEST = "AREAS_GET_IN_REQUEST";
export const AREAS_GET_SUCCESS = "AREAS_GET_SUCCESS";
export const AREAS_GET_FAILURE = "AREAS_GET_FAILURE";
export const AREAS_GET_MORE_SUCCESS = "AREAS_GET_MORE_SUCCESS";
export const AREAS_UPDATE_CREATE_IN_REQUEST = "AREAS_UPDATE_CREATE_IN_REQUEST";
export const AREAS_UPDATE_CREATE_SUCCESS = "AREAS_UPDATE_CREATE_SUCCESS";
export const AREAS_UPDATE_CREATE_FAILURE = "AREAS_UPDATE_CREATE_FAILURE";
export const AREAS_SET_ALL_LIST = "AREAS_SET_ALL_LIST";

// Empresas
export const BUSINESSES_GET_IN_REQUEST = "BUSINESSES_GET_IN_REQUEST";
export const BUSINESSES_GET_SUCCESS = "BUSINESSES_GET_SUCCESS";
export const BUSINESSES_GET_FAILURE = "BUSINESSES_GET_FAILURE";
export const BUSINESSES_GET_MORE_SUCCESS = "BUSINESSES_GET_MORE_SUCCESS";
export const BUSINESS_SET_ADD_STATE = "BUSINESS_SET_ADD_STATE";
export const BUSINESS_SET_UPDATE_STATE = "BUSINESS_SET_UPDATE_STATE";
export const BUSINESS_SET_SELECTED = "BUSINESS_SET_SELECTED";

// EDIT USER LIST CARGOS - AREAS
export const SET_AREAS_LIST_USER = "SET_AREAS_LIST_USER";
export const SET_CARGOS_LIST_USER = "SET_CARGOS_LIST_USER";

export const SET_INFO_USER_VIEW = "SET_INFO_USER_VIEW";
