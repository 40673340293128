import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "CargosBSNS",
  aclara: "Cargos",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

export const getCargos = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    // dispatch({
    //   type: types.CHARGES_IS_SUBMITTING,
    // });
    const { user } = getState().authReducer;
    try {
      // let query = firestore.collection(collName(user)).orderBy("Nombre_lower");
      let business = undefined;

      if (user?.business) {
        // query = query.where("Empresa", "==", user.business);
        business = user.business;
      }

      dispatch(getBusinessCargos(business));

      // // const response = await sortQueries(query, filter).limit(limit).get();
      // const responsetotal = await sortQueries(query, filter).get();

      // const cargos = responsetotal.docs.map((x) => ({ ...x.data(), id: x.id }));
      // console.log(cargos);

      // dispatch({
      //   type: types.CHARGES_SUCCESS,
      //   payload: {
      //     Cargos: cargos,
      //     lastDoc: responsetotal.docs[responsetotal.docs.length - 1],
      //     totalDocs: cargos.length,
      //   },
      // });
      // const allCargos = responsetotal.docs.map((x) => ({
      //   ...x.data(),
      //   id: x.id,
      // }));

      // dispatch({
      //   type: types.CHARGES_SET_LIST_ALL,
      //   payload: allCargos,
      // });

      // dispatch({
      //   type: types.CHARGES_SET_TOTAL_DOCS,
      //   payload: {
      //     Cargos: cargos,
      //     totalDocs: responsetotal.size,
      //     lastDoc: response.docs[response.docs.length - 1],
      //   },
      // });
    } catch (error: any) {
      console.log(error);
      // dispatch({
      //   type: types.CHARGES_FAILURE,
      //   payload: "Ha ocurrido un error obteniendo cargos",
      // });
    }
  };
};

export const getMoreCargos = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    const { totalDocs, Cargos, lastDoc } = getState().positionChargesReducer;
    const { user } = getState().authReducer;
    dispatch({
      type: types.CHARGES_IS_SUBMITTING,
    });
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("Nombre_lower")
        .startAfter(lastDoc);

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await query.limit(limit).get();
      const cargos = response.docs.map((x) => ({ ...x.data(), id: x.id }));

      dispatch({
        type: types.CHARGES_SET_TOTAL_DOCS,
        payload: {
          Cargos: Cargos.concat(cargos),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.CHARGES_FAILURE,
        payload: "Ha ocurrido un error obteniendo cargos",
      });
    }
  };
};

export const getCargosFiltered = (
  filtros: filterType,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHARGES_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy("Nombre_lower");

      if (filtros.length>0) {
        query = query.where("Nombre_lower", "==", cleanString(filtros[0].value));
      }

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      // const response = await query.limit(limit).get();
      const response = await query.get();

      // const responsetotal = await firestore
      //   .collection(collName(user))
      //   .where("Nombre_lower", "==", nombre)
      //   .get();

      // dispatch({
      //   type: types.CHARGES_SET_TOTAL_DOCS,
      //   payload: responsetotal.size,
      // });
      const cargos = response.docs.map((x) => ({ ...x.data(), id: x.id }));

      dispatch({
        type: types.CHARGES_SUCCESS,
        payload: {
          Cargos: cargos,
          lastDoc: response.docs[response.docs.length - 1],
          totalDocs: cargos.length
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.CHARGES_FAILURE,
        payload: "Ha ocurrido un error obteniendo cargos",
      });
    }
  };
};

export const addCargos = (values: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHARGES_EDITING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .where("Nombre_lower", "==", cleanString(values.Nombre));
      console.log(values);

      if (user?.business) {
        values["Empresa"] = user.business;
        query = query.where("Empresa", "==", user.business);
      }

      const nameCheck = await query.get();

      if (nameCheck.docs.length === 0) {
        await firestore.collection(collName(user)).add({
          ...values,
          Nombre_lower: cleanString(values.Nombre),
          FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
        });
        dispatch({
          type: types.CHARGES_EDITING_SUCCESS,
        });
        dispatch(getCargos());
      } else {
        dispatch(openSnack("El cargo ya existe", SnackState.ERROR));
      }
    } catch (error: any) {
      dispatch({
        type: types.CHARGES_EDITING_FAILURE,
        payload: "Ha ocurrido un error añadiendo cargo",
      });
    }
  };
};

export const editCargo = (values: any, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHARGES_EDITING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .where("Nombre_lower", "==", cleanString(values.Nombre));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const nameCheck = await query.get();

      if (nameCheck.docs.length !== 0) {
        // IF ALREADY EXIST DOC WITH SAME NAME
        if (nameCheck.docs[0].id === id) {
          // IF DOC WITH SAME NAME IS THE ONE BEING MODIFIED
          await firestore
            .collection(collName(user))
            .doc(id)
            .update({
              ...values,
              Nombre_lower: cleanString(values.Nombre),
              FechaModificacion:
                firebase.firestore.FieldValue.serverTimestamp(),
            });
          dispatch({
            type: types.CHARGES_EDITING_SUCCESS,
          });

          let usersQuery = firestore
            .collection("Usuarios")
            .where("Cargo", "==", values.Nombre)
            .where("TipoPermisos", "!=", values.TipoPermisos);

          if (user?.business) {
            usersQuery = usersQuery.where("Empresa", "==", user.business);
          }
          const usersResponse = await usersQuery.get();
          const usersIds = usersResponse.docs.map((doc) => doc.id);

          let batch = firestore.batch();
          let transactionsCounter = 0; //500 transactions per commit
          for (const usrId of usersIds) {
            transactionsCounter++;

            const usrRef = firestore.collection("Usuarios").doc(usrId);
            batch.update(usrRef, { TipoPermisos: values.TipoPermisos });

            if (transactionsCounter === 499) {
              await batch.commit();
              batch = firestore.batch(); // Clean Transactions
              transactionsCounter = 0;
            }
          }
          if (transactionsCounter > 0 && transactionsCounter < 499) {
            await batch.commit();
            transactionsCounter = 0;
          }

          dispatch(openSnack("Cargo ha sido actualizado", SnackState.SUCCESS));
          dispatch(getCargos());
        } else {
          dispatch(openSnack("El cargo ya existe", SnackState.ERROR));
        }
      } else {
        // THERE'S NOT DOC WITH THE NAME
        await firestore
          .collection(collName(user))
          .doc(id)
          .update({
            ...values,
            Nombre_lower: cleanString(values.Nombre),
            FechaModificacion: firebase.firestore.FieldValue.serverTimestamp(),
          });

        let usersQuery = firestore
          .collection("Usuarios")
          .where("Cargo", "==", values.Nombre)
          .where("TipoPermisos", "!=", values.TipoPermisos);

        if (user?.business) {
          usersQuery = usersQuery.where("Empresa", "==", user.business);
        }
        const usersResponse = await usersQuery.get();
        const usersIds = usersResponse.docs.map((doc) => doc.id);

        let batch = firestore.batch();
        let transactionsCounter = 0; //500 transactions per commit
        for (const usrId of usersIds) {
          transactionsCounter++;

          const usrRef = firestore.collection("Usuarios").doc(usrId);
          batch.update(usrRef, { TipoPermisos: values.TipoPermisos });

          if (transactionsCounter === 499) {
            await batch.commit();
            batch = firestore.batch(); // Clean Transactions
            transactionsCounter = 0;
          }
        }
        if (transactionsCounter > 0 && transactionsCounter < 499) {
          await batch.commit();
          transactionsCounter = 0;
        }

        dispatch({
          type: types.CHARGES_EDITING_SUCCESS,
        });
        dispatch(openSnack("Cargo ha sido actualizado", SnackState.SUCCESS));
        dispatch(getCargos());
      }
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.CHARGES_EDITING_FAILURE,
        payload: "Ha ocurrido un error editando el cargo",
      });
    }
  };
};

export const deleteCargo = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CHARGES_EDITING,
    });
    const { user } = getState().authReducer;
    try {
      await firestore.collection(collName(user)).doc(id).delete();
      dispatch({
        type: types.CHARGES_EDITING_SUCCESS,
      });
      dispatch(openSnack("Cargo ha sido eliminado", SnackState.ERROR));
      dispatch(getCargos());
    } catch (error: any) {
      dispatch({
        type: types.CHARGES_EDITING_FAILURE,
        payload: "Ha ocurrido un error eliminando el cargo",
      });
    }
  };
};

export const getBusinessCargos = (
  business?: firebase.firestore.DocumentReference
): AppThunk => {
  return async (dispatch) => {
    try {
      let query = firestore
        .collection(collName({ business: business }))
        .orderBy("Nombre")
        .where("Activado", "==", true);

      if (business) {
        query = query.where("Empresa", "==", business);
      }

      const response = await query.get();
      // console.log(response);

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // dispatch({
      //   type: types.CHARGES_SUCCESS,
      //   payload: {
      //     Cargos: data,
      //     lastDoc: response.docs[response.docs.length - 1],
      //     totalDocs: data.length,
      //   },
      // });

      dispatch({
        type: types.SET_CARGOS_LIST_USER,
        payload: data,
      });
    } catch (error: any) {
      console.log(error);
    }
  };
};
