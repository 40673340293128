import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface PositionChargesState {
  Cargos: any[];
  allCargos: any[];
  state: FormState;
  error?: string;
  totalDocs: number;
  lastDoc?: any;
  edit?: any;
  businessCargos: any[];
}

const initialState = {
  Cargos: [],
  allCargos: [],
  state: FormState.Initial,
  totalDocs: 0,
  edit: {
    state: FormState.Initial,
    error: undefined,
  },
  businessCargos: [],
} as PositionChargesState;

export const positionChargesReducer = (
  state = initialState,
  action: Action
): PositionChargesState => {
  switch (action.type) {
    case types.CHARGES_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        Cargos: action.payload.Cargos,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.CHARGES_SET_LIST_ALL:
      return {
        ...state,
        allCargos: action.payload,
      };
    case types.CHARGES_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.CHARGES_IS_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.CHARGES_GET_DOCS:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.CHARGES_SET_TOTAL_DOCS:
      return {
        ...state,
        state: FormState.Success,
        totalDocs: action.payload.totalDocs,
        Cargos: action.payload.Cargos,
        lastDoc: action.payload.lastDoc,
      };
    case types.CHARGES_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.CHARGES_EDITING:
      return {
        ...state,
        edit: { state: FormState.Submitting },
      };
    case types.CHARGES_EDITING_SUCCESS:
      return {
        ...state,
        edit: { state: FormState.Success },
      };
    case types.CHARGES_EDITING_FAILURE:
      return {
        ...state,
        edit: { state: FormState.Failure, error: action.payload },
      };

    // list of cargos related to de business the user belongs
    case types.SET_CARGOS_LIST_USER:
      return {
        ...state,
        businessCargos: action.payload,
      };
    default:
      return state;
  }
};
