import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface IActividades {
  actividades: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedActividad?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  add?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
    actividades: [],
  state: FormState.Initial,
  totalDocs: undefined,
  selectedActividad: null,
  lastDoc: undefined,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as IActividades;

export const actividadReducer = (
  state = initialState,
  action: Action
): IActividades => {
  switch (action.type) {
    case types.ACTIVIDADES_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        actividades: action.payload.actividades,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.ACTIVIDADES_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.ACTIVIDADES_GET_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
      case types.ACTIVIDADES_SET_SELECTED:
      return {
        ...state,
        selectedActividad: action.payload,
      };
      case types.ACTIVIDADES_GET_ONE_SUCCES:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedActividad: action.payload,
      };
      case types.ACTIVIDADES_GET_ONE_IS_SUBMMITING:
        return {
          ...state,
          edit: {
            state: FormState.Submitting,
            error: undefined,
          },
        };
        case types.ACTIVIDADES_GET_ONE_IS_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
      case types.ACTIVIDADES_ADD_COMENT_IS_SUBMMITING:
        return {
          ...state,
          add: {
            state: FormState.Submitting,
            error: undefined,
          },
        };
        case types.ACTIVIDADES_ADD_COMENT_IS_SUCCESS:
          return {
            ...state,
            add: {
              state: FormState.Success,
            },
          };
          case types.ACTIVIDADES_ADD_COMENT_IS_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
