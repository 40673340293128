// import { format } from "date-fns";
import firebase from "firebase/app";
import { filterType } from "../redux/actions/businessActions";
type Timestamp = firebase.firestore.Timestamp;

export const removeUndefinedKeys = (obj: any) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
};

export const phoneValidation = (number: string) => {
  try {
    if (
      number.substring(0, 4) === "+569" &&
      number.substring(4).length === 8 &&
      number.length === 12 &&
      Number.isInteger(Number(number))
    ) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const sortByName = (a: any, b: any) =>
  a.Nombre < b.Nombre ? -1 : a.Nombre > b.Nombre ? 1 : 0;

export const timesStampToDateFormatted = (timestamp: any) => {
  try {
    return new Intl.DateTimeFormat("cl-CL", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    }).format(timestamp);
  } catch (e) {
    return "timestamp inválido";
  }
};

export const timesStampFormattedsimple = (timestamp: any) => {
  try {
    return new Intl.DateTimeFormat("cl-CL", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour12: false,
    }).format(timestamp);
  } catch (e) {
    return "timestamp inválido";
  }
};

export const timeStampToDate = (timeStamp?: any): Date => {
  if (timeStamp) return (timeStamp as Timestamp).toDate();
  return new Date();
};

export const cleanString = (value: string): string => {
  let newValue = `${value}`;
  newValue = newValue.toLowerCase();
  newValue = newValue.replace(/á/gi, "a");
  newValue = newValue.replace(/é/gi, "e");
  newValue = newValue.replace(/í/gi, "i");
  newValue = newValue.replace(/ó/gi, "o");
  newValue = newValue.replace(/ú/gi, "u");
  return newValue;
};
export const upperString = (value: string): string => {
  let newValue = `${value}`;
  newValue = newValue.toUpperCase();
  newValue = newValue.replace(/á/gi, "a");
  newValue = newValue.replace(/é/gi, "e");
  newValue = newValue.replace(/í/gi, "i");
  newValue = newValue.replace(/ó/gi, "o");
  newValue = newValue.replace(/ú/gi, "u");
  return newValue;
};

export const exportToCsv = (
  filename: string,
  rows: object[],
  headers: string[],
  headersAs?: string[]
): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator: string = ",";

  if (headersAs) {
    if (headers.length !== headersAs.length) {
      throw new Error("headers and headersAs must be equal length");
    }
  }
  const columHearders = headers;

  const csvContent =
    "sep=,\n" +
    (headersAs ? headersAs.join(separator) : columHearders.join(separator)) +
    "\n" +
    rows
      .map((row: any, i) => {
        return columHearders
          .map((k) => {
            let cell = k.includes(".")
              ? resolve(k, row)
              : row[k] === null || row[k] === undefined
              ? ""
              : row[k];
            if (!cell) {
              cell = "";
            }
            if (cell instanceof firebase.firestore.Timestamp) {
              cell = (cell as firebase.firestore.Timestamp)
                .toDate()
                .toLocaleString();
            } else if (cell instanceof Date) {
              cell = cell.toLocaleString();
            } else {
              cell = cell.toString().replace(/"/g, '""');
            }

            cell = cell.replace(/á/gi, "a");
            cell = cell.replace(/é/gi, "e");
            cell = cell.replace(/í/gi, "i");
            cell = cell.replace(/ó/gi, "o");
            cell = cell.replace(/ú/gi, "u");
            cell = cell.replace(/ñ/gi, "n");

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export function resolve(path: string, obj: any) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
}

export const handleFiltro = (
  filtro: any = {},
  query: firebase.firestore.Query<firebase.firestore.DocumentData>
) => {
  if (filtro && typeof filtro === "object" && Object.keys(filtro).length > 0) {
    Object.keys(filtro).forEach((key) => {
      const value = filtro[key];

      if (key.includes("FechaCreacion")) {
        // FechaCreacion like [dateStart, dateEnd]
        query = query.where(key, ">=", value[0]);
        query = query.where(key, "<=", value[1]);
        console.log("hereASDS");
      } else {
        if (key.includes("lower") || key.includes("Lower")) {
          if (Object.keys(filtro).length === 1) {
            query = query
              .startAt(cleanString(value))
              .endAt(cleanString(value) + "\uf8ff");
            console.log("xxxA");
          } else {
            console.log("xxxB");
            query = query.where(key, "==", cleanString(value));
          }
        } else if (key === "endAt") {
          console.log("hereA");

          const miliDate = Date.parse(value);
          query = query.where("FechaCreacion", "<=", new Date(miliDate));
        } else if (key === "startAt") {
          console.log("hereB");
          const miliDate = Date.parse(value);
          query = query.where("FechaCreacion", ">=", new Date(miliDate));
        } else {
          console.log("hereC");
          query = query.where(key, "==", value);
        }
      }
    });
  }

  return query;
};

export const validateEmailManually = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const handleDateFormat = (date: Date) => {
  let day = String(date.getDate());
  if (day.length === 1) day = "0" + day;
  let month = String(date.getMonth() + 1);
  if (month.length === 1) month = "0" + month;

  return `${day}/${month}/${date.getFullYear()}`;
};

export const editCount = async (
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  type: "plus" | "minus"
) => {
  const docSnapshot = await ref.get();
  if (docSnapshot.exists) {
    return ref.update(
      "count",
      firebase.firestore.FieldValue.increment(type === "plus" ? 1 : -1)
    );
  } else {
    return ref.set({ count: 1 });
  }
};

export const lowerFieldValidation = (
  filter: filterType = []
): number | "empty" => {
  if (filter.length === 0) return "empty";
  let counFieldsLower = 0;
  let indexFieldLower: number | "empty" = "empty";

  const fields = filter.map((q) => cleanString(q.field));

  if (fields.includes("fechacreacion") || fields.includes("fechaingreso")) {
    return "empty";
  }
  fields.map((field, index) => {
    if (field.includes("lower")) {
      counFieldsLower++;
      indexFieldLower = index;
    }
  });

  if (counFieldsLower === 1 && indexFieldLower !== "empty") {
    return indexFieldLower;
  }
  return "empty";
};

export const getFileNameExtension = (fileName: string) => {
  const nameArr = fileName.split(".");
  const fileExt = nameArr[nameArr.length - 1];
  return fileExt;
};
