// import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import {
  cleanString,
  exportToCsv,
  lowerFieldValidation,
} from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "FormulariosCovidRespuestasBSNS",
  aclara: "FormulariosCovidRespuestas",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export const exportAnswers = (
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).get();
      const respuestasList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      exportToCsv(
        "respuestas.csv",
        respuestasList,
        [
          "Formulario.Nombre",
          "NombreCompleto",
          "Rut",
          "FechaCreacion",
          "SintomasCovid",
          "Preguntas",
        ],
        [
          "Nombre",
          "Usuario",
          "Rut",
          "Fecha Creacion",
          "Sintomas Covid",
          "Preguntas",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getAnswers = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ANSWERS_GET_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();
      const respuestasList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ANSWERS_GET_SUCCESS,
        payload: {
          answers: respuestasList,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.ANSWERS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado de respuestas",
      });
    }
  };
};

export const getMoreAnswers = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ANSWERS_GET_IS_SUBMITTING,
    });
    const { lastDoc } = getState().answersReducer;
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      let query = firestore
        .collection(collName(user))
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestasList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.ANSWERS_GET_MORE_SUCCESS,
        payload: {
          answers: respuestasList,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.ANSWERS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado con más respuestas",
      });
    }
  };
};

export const getAnswersFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ANSWERS_GET_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;

    try {
      let query = firestore.collection(collName(user)).orderBy("FechaCreacion");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        // console.log(key, value);

        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.limit(limit).get();
      const responseAll = await query.get();

      const respuestasList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ANSWERS_GET_SUCCESS,
        payload: {
          answers: respuestasList,
          totalDocs: responseAll.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.ANSWERS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo respuestas filtradas",
      });
    }
  };
};

export const getMoreAnswersFiltered = (
  filtro: any = {},
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ANSWERS_GET_IS_SUBMITTING,
    });
    const { lastDoc, totalDocs } = getState().answersReducer;
    const { user } = getState().authReducer;

    try {
      const total = totalDocs || 0;
      let query = firestore.collection(collName(user)).orderBy("FechaCreacion");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      if (filtro) {
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
      }

      const response = await query.startAfter(lastDoc).limit(limit).get();

      const respuestasList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ANSWERS_GET_SUCCESS,
        payload: {
          answers: respuestasList,
          totalDocs: total,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.ANSWERS_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo respuestas filtradas",
      });
    }
  };
};

export const deleteAnswers = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      await firestore.collection(collName(user)).doc(id).delete();
      dispatch({
        type: types.ANSWERS_DELETE_SUCCESS,
      });
      dispatch(openSnack("Respuestas han sido eliminadas", SnackState.ERROR));
      dispatch(getAnswers());
    } catch (error: any) {
      dispatch({
        type: types.ANSWERS_DELETE_FAILURE,
        payload: "Ha ocurrido un error eliminando las respuestas",
      });
    }
  };
};

export const setSelectedAnswers = (answers: any): Action => ({
  type: types.ANSWERS_SET_SELECTED,
  payload: answers,
});
