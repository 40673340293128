import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Add as AddIcon,
  Category,
  CenterFocusStrong,
  Label,
} from "@material-ui/icons";
import * as yup from "yup";
import {
  addCovid,
  initialCovidForms,
} from "../redux/actions/covidFormsActions";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardActions,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import { display, minWidth, spacing, typography } from "@material-ui/system";
import { AlignCenter, Edit, Eye, Trash } from "react-feather";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SelectedComponent } from "./SelectedComponent";
import { format } from "date-fns";
import { NavLink, useHistory } from "react-router-dom";
import { FormState } from "../models/form_state";
import { ICovidFormsState } from "../redux/reducers/covidFormsReducer";
import { RootState } from "../redux/reducers/rootReducer";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type Question = {
  Descripcion: string;
  Respuesta: number;
};

type FormValues = {
  Activo: boolean;
  Preguntas: Question[];
};

const ContentCard = (): JSX.Element => {
  // Solo obtiene la respuesta del input para agregar una pregunta nueva
  const [question, setQuestion] = useState<string>("");
  const [inputNombre, setInputNombre] = useState("Formulario test");

  const { add, edit } = useSelector<RootState, ICovidFormsState>(
    (state) => state.covidFormsReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik<FormValues>({
    initialValues: {
      Activo: true,
      Preguntas: [],
      // FechaCreacion: format(new Date(), "dd/MM/yyyy HH:mm:ss"),
    },
    onSubmit: (values) => {},
    validationSchema: yup.object().shape({
      Preguntas: yup.array().of(
        yup.object().shape({
          Descripcion: yup.string().required("Debes seleccionar una pregunta"),
          Respuesta: yup.number().required("Debes seleccionar una respuesta"),
        })
      ),
    }),
  });

  const [errormessage, setErrormessage] = useState<string | null>(null);

  useEffect(() => {
    if (add.state === FormState.Success) {
      history.push("/formulariosCovid/listado");
    }
    console.log("state reducer", add.state);
  }, [add.state]);

  useEffect(() => {
    return () => {
      dispatch(initialCovidForms());
    };
  }, []);

  useEffect(() => {
    if (edit.selectedForm) {
      setInputNombre(edit.selectedForm.Nombre);
      setValues({
        Activo: true,
        Preguntas: edit?.selectedForm.Preguntas,
      });
    }
  }, [edit.selectedForm]);

  const goToMainForm = () => {
    history.push(`/formulariosCovid/listado`);
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <TextField
            label="Nombre formulario"
            value={inputNombre}
            onChange={(e) => setInputNombre(e.target.value)}
          />
          <Grid
            container
            style={{ marginBottom: 20, marginTop: 20 }}
            spacing={4}
          >
            <Grid item xs={12} sm={4}>
              <Typography>Temperatura Coporal</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>¿Has Tenido Covid?</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>Mes en que contrajo el Virus</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>¿Ha Tenido síntomas de Covid?</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                Si presenta algún síntoma, indique medio de transporte
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>¿Posee pase de movílidad?</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Grid container spacing={2} justify="space-between">
            <Grid>
              <Box display="flex">
                <Box order={1}>
                  <TextField
                    id="Preguntas"
                    label="Escribe aqui tu pregunta"
                    onChange={(event) => {
                      setErrormessage(null);
                      setQuestion(event.target.value);
                    }}
                    value={question}
                    error={touched.Preguntas && Boolean(errors.Preguntas)}
                    helperText={
                      errormessage ? (
                        <Typography style={{ color: "red" }}>
                          "Agrega una pregunta por favor"
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    autoComplete="off"
                    fullWidth
                  />
                </Box>
                <Box order={2} m={4}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      if (question.length > 0) {
                        const newArray = [
                          ...(values?.Preguntas || []),
                          {
                            Descripcion: question,
                            Respuesta: 0,
                          },
                        ];
                        setQuestion("");
                        setFieldValue("Preguntas", newArray);
                      } else {
                        setErrormessage("Campo Vacio");
                      }
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Agregar
                  </Button>
                </Box>
              </Box>
            </Grid>
            <TableContainer component={Paper}>
              <Table aria-label="caption table">
                <caption>Listado de Preguntas agregadas</caption>
                <TableHead>
                  <TableRow>
                    <TableCell align="justify">Preguntas agregadas</TableCell>
                    <TableCell align="justify">
                      Valor de peligro <p>(Indicar respuesta segura)</p>
                    </TableCell>
                    <TableCell align="justify">Acciones </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.Preguntas
                    ? values?.Preguntas.map((v, index) => (
                        <TableRow key={index}>
                          <TableCell align="justify">{v.Descripcion}</TableCell>
                          <TableCell
                            align="justify"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <SelectedComponent
                              value={values.Preguntas[index].Respuesta}
                              onChange={(value) => {
                                const newArray = [...values.Preguntas];
                                newArray[index] = {
                                  ...newArray[index],
                                  Respuesta: value,
                                };
                                setFieldValue("Preguntas", newArray);
                              }}
                            />
                          </TableCell>
                          <TableCell align="justify">
                            {/* <Tooltip title="Editar">
                          <IconButton color="primary" size="small">
                            <Edit />
                          </IconButton>
                        </Tooltip> */}
                            {/* <Tooltip title="Ver">
                          <IconButton color="primary" size="small">
                            <Eye />
                          </IconButton>
                        </Tooltip> */}
                            <Tooltip title="Eliminar">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setFieldValue(
                                    "Preguntas",
                                    values.Preguntas.filter((x) => x !== v)
                                  )
                                }
                              >
                                <Trash color="red" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: 13, justifyContent: "flex-end" }}>
          <Button
            style={{ marginRight: 5, marginTop: 13 }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => goToMainForm()}
          >
            cancelar
          </Button>
          <Button
            style={{ marginTop: 13 }}
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(
                addCovid(values.Preguntas, inputNombre || "Formulario test")
              );
            }}
          >
            Guardar
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export const AddCovidForm = () => (
  <React.Fragment>
    <Helmet title="Agregar Formulario COVID" />
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h3" gutterBottom display="inline">
          Administrador Preguntas del Formulario
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to="/formulariosCovid/listado">
            Listado
          </Link>
          <Typography>Administar</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
    <Divider my={6} />

    <ContentCard />
  </React.Fragment>
);
