import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface IAriesgo {
  ariesgo: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc: any;
  selectedAriesgo?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  add?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  ariesgo: [],
  state: FormState.Initial,
  totalDocs: undefined,
  selectedAriesgo: null,
  lastDoc: undefined,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as IAriesgo;

export const artReducer = (state = initialState, action: Action): IAriesgo => {
  switch (action.type) {
    case types.ARIESGO_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        ariesgo: action.payload.ariesgo,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.ARIESGO_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.ARIESGO_GET_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.ARIESGO_SET_SELECTED:
      return {
        ...state,
        selectedAriesgo: action.payload,
      };
    case types.ARIESGO_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
      case types.ARIESGO_GET_ONE_SUCCESS:
        return {
          ...state,
          edit: {
            state: FormState.Success,
          },
          selectedAriesgo: action.payload
        };
        case types.ARIESGO_GET_ONE_FAILURE:
          return {
            ...state,
            edit: {
              state: FormState.Failure,
              error: action.payload
            },
            
          };
    default:
      return state;
  }
};
