import * as types from "../../constants";
import firebase from "firebase";

export interface IAuthState {
  user?: {
    id?: string;
    email?: string;
    name?: string;
    image?: string;
    token?: string;
    activo?: boolean;
    tipoUsuario?: string;
    superUser?: boolean;
    business?: firebase.firestore.DocumentReference;
    businessInfo?: { Nombre: string; Activo: boolean };
    businessModules?: string[];
    modulos?: string[];
  };
}

const initialState = {} as IAuthState;

export default function reducer(state = initialState, actions: any) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          ...actions,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
