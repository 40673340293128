import { Action } from "../../models/action";
import * as types from "../../constants";
import { BussinessModel } from "../../models/businesses";
import firebase from "firebase";
import { FormState } from "../../models/form_state";
import { UserModel } from "../../models/user";

export interface BusinessState {
  getList: {
    businesses: BussinessModel[];
    lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
    totalDocs: number;
    isLoading?: boolean;
  };
  add: {
    state: FormState;
    error?: string;
    newId?: string;
  };
  update: {
    state: FormState;
    error?: string;
  };
  selected: { business: BussinessModel; user: UserModel } | null;
}

const initialState = {
  getList: { businesses: [], lastDoc: null, totalDocs: 0, isLoading: false },
  add: {
    state: FormState.Initial,
  },
  update: {
    state: FormState.Initial,
  },
  selected: null,
} as BusinessState;

export const businessReducer = (
  state = initialState,
  action: Action
): BusinessState => {
  switch (action.type) {
    case types.BUSINESSES_GET_IN_REQUEST:
      return {
        ...state,
        getList: { ...state.getList, isLoading: true },
      };
    case types.BUSINESSES_GET_FAILURE:
      return {
        ...state,
        getList: { ...state.getList, isLoading: false },
      };
    case types.BUSINESSES_GET_SUCCESS:
      return {
        ...state,
        getList: { ...action.payload, isLoading: false },
      };
    case types.BUSINESSES_GET_MORE_SUCCESS:
      return {
        ...state,
        getList: { ...state.getList, ...action.payload, isLoading: false },
      };

    case types.BUSINESS_SET_ADD_STATE:
      return {
        ...state,
        add: action.payload,
      };
    case types.BUSINESS_SET_UPDATE_STATE:
      return {
        ...state,
        update: action.payload,
      };

    case types.BUSINESS_SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    default:
      return state;
  }
};
