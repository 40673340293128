import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Download, Eye, MinusSquare, Search } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  exportAlertGps,
  getAlertGps,
  getMoreAlertGps,
  setSelectedGps,
} from "../redux/actions/alertGpsAction";
import { filterType } from "../redux/actions/businessActions";
import { IGps } from "../redux/reducers/gpsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface filterProps {
  onFilter: (value: filterType) => void;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filtros, setFiltros] = useState<filterType>([]);

  const handleGoToViewGps = (data: any) => {
    dispatch(setSelectedGps(data));
    history.push(`/gpsRoutes/${data.id}/ver`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT || 50,
      onLimitChange: (e, newLimit) => {
        if (newLimit > gps.length) {
          dispatch(getAlertGps(newLimit, filtros));
        }
      },

      onPageChange: (newPage, oldPage, limit) => {
        if (gps.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreAlertGps(limit, filtros));
        }
      },
    });

  useEffect(() => {
    dispatch(getAlertGps(limit, filtros));
  }, [filtros]);

  const { gps, state, totalDocs } = useSelector<RootState, IGps>(
    (state) => state.gpsReducer
  );

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  const handleExport = () => {
    dispatch(exportAlertGps(filtros));
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: " #fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
          </>
        }
      />
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Apellido</TableCell>
                  <TableCell align="left">Latitud</TableCell>
                  <TableCell align="left">Longitud</TableCell>
                  <TableCell align="left">Fecha de Creación</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  gps.slice(page * limit, page * limit + limit).map((data) => (
                    <Fade key={data.id} in={true}>
                      <TableRow hover className={classes.styledRow}>
                        <TableCell align="left">
                          {data?.Usuario?.Nombre}
                        </TableCell>
                        <TableCell align="left">
                          {data?.Usuario?.Apellido}
                        </TableCell>
                        <TableCell align="left">
                          {data?.UbicacionInicial?.latitude}
                        </TableCell>
                        <TableCell align="left">
                          {data?.UbicacionInicial?.longitude}
                        </TableCell>
                        <TableCell align="left">
                          {timesStampFormattedsimple(
                            data?.FechaCreacion.toDate()
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Box style={{ justifyContent: "flex-start" }}>
                            <Tooltip title="Ver">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleGoToViewGps(data)}
                              >
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </Fade>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </Card>
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);
  const [nombre, setNombre] = useState("");
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "Usuario.Nombre",
        operator: "==",
        value: nombre,
      });

    if (dateStart)
      filter.push({
        field: "FechaCreacion",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaCreacion",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Gps = () => {
  return (
    <React.Fragment>
      <Helmet title="GPS" />
      <Typography variant="h3" gutterBottom display="inline">
        Alerta GPS
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>GPS</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
