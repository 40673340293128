// import firebase from "firebase";
import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString, exportToCsv } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "AnalisisDeRiesgosBSNS",
  aclara: "AnalisisDeRiesgos",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

export const exportAriesgos = (filter: filterType = []): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).get();
      const respuesAriesgo: any[] = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      const datosArt = respuesAriesgo.map((e: any) => ({
        Tareas: JSON.stringify(e.Tareas.map((item: any) => ({ item }))),
        MedidasControl: JSON.stringify(
          e.MedidasControl.map((item: any) => ({ item }))
        ),
        PeligrosYRiesgos: JSON.stringify(
          e.PeligrosYRiesgos.map((item: any) => ({ item }))
        ),
        Consecuencias: JSON.stringify(
          e.Consecuencias.map((item: any) => ({ item }))
        ),
        Participantes: JSON.stringify(
          e.Participantes.map((item: any) => ({
            Nombre: item.Nombre,
          }))
        ),
        Area: e.Area,
        Descripcion: e.Descripcion,
        FechaCreacion: e.fechaCreacion,
      }));

      exportToCsv(
        "art.csv",
        datosArt,
        [
          "Area",
          "Tareas",
          "Descripcion",
          "FechaCreacion",
          "MedidasControl",
          "PeligrosYRiegos",
          "Preguntas",
          "Participantes",
        ],
        [
          "Area",
          "Tareas",
          "Descripcion",
          "Fecha de Creacion",
          "Medidas de control",
          "Pelisgros y riegos",
          "Preguntas",
          "Participantes",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getAriesgos = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ARIESGO_GET_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();
      const respuesAriesgo = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.ARIESGO_GET_SUCCESS,
        payload: {
          ariesgo: respuesAriesgo,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.ARIESGO_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar el analisis de riesgo",
      });
    }
  };
};

export const getOneAriesgo = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ARIESGO_GET_ONE_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      const response = await firestore.collection(collName(user)).doc(id).get();
      dispatch({
        type: types.ARIESGO_GET_ONE_SUCCESS,
        payload: response.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.ARIESGO_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error obteniendo el analisis de riesgo",
      });
    }
  };
};
export const getMoreArt = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ARIESGO_GET_SUBMMITING,
    });
    // const lastDoc = getState().artReducer.lastDoc || "";
    const { totalDocs, ariesgo, lastDoc } = getState().artReducer;
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const responseArt = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ARIESGO_GET_SUCCESS,
        payload: {
          ariesgo: ariesgo.concat(responseArt),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.ARIESGO_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo mas Art",
      });
    }
  };
};
export const getArtFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy(order, "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      // query = firestore.collection(collName(user)).orderBy("FechaCreacion");

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });
      const response = await query.get();
      const artList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));
      // dispatch(setIncidentes(incidentesList));
      // dispatch(setLastDoc(response.docs[response.docs.length - 1]));

      dispatch({
        type: types.ARIESGO_GET_SUCCESS,
        payload: {
          ariesgo: artList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};

export const setSelectedAriesgo = (ariesgo: any): Action => ({
  type: types.ARIESGO_SET_SELECTED,
  payload: ariesgo,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.ARIESGO_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.ARIESGO_FAILURE,
  payload: error,
});
