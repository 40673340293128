import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import { uiReducer } from "./uiReducer";
import { usersReducer } from "./usersReducer";
import { buttonReducer } from "./buttonReducer";
import { positionChargesReducer } from "./positionChargesReducer";
import covidFormsReducer from "./covidFormsReducer";
import { answersReducer } from "./answersReducer";
import { checkListReducer } from "./checkListReducer";
import { incidentesReducer } from "./incidentsReducer";
import { actividadReducer } from "./actividadesReducer";
import { artReducer } from "./ariesgoReducer";
import { gpsReducer } from "./gpsReducer";
import { areasReducer } from "./areasReducer";
import { businessReducer } from "./businessReducer";
import { informationReducer } from "./informationReducer";

export const rootReducer = combineReducers({
  themeReducer,
  uiReducer,
  authReducer,
  usersReducer,
  buttonReducer,
  positionChargesReducer,
  covidFormsReducer,
  answersReducer,
  checkListReducer,
  incidentesReducer,
  actividadReducer,
  artReducer,
  gpsReducer,
  areasReducer,
  businessReducer,
  informationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
