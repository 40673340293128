import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { FormState } from "../models/form_state";
import { IncidentState } from "../models/incident-states";
import { SnackState } from "../models/snack-state";
import { getAreasAll, getBusinessAreas } from "../redux/actions/areasActions";
import {
  getOneIncidente,
  updateComentIncidentCierre,
  updateComentIncidentRevision,
} from "../redux/actions/incidentesActions";
import { openSnack } from "../redux/actions/uiActions";
import { AreasState } from "../redux/reducers/areasReducer";
import { IAuthState } from "../redux/reducers/authReducer";
import { Iincidentes } from "../redux/reducers/incidentsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { timesStampFormattedsimple } from "../utils/utils";
import noImagen from "../vendor/sin_imagen.jpg";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const { incidentesId } = useParams<any>();
  const dispatch = useDispatch();

  const [valueArea, setArea] = useState<any>(null);
  const [showAreaError, setShowAError] = useState(false);
  const [inputComentIncident, setInputComentIncident] = useState("");
  const [inputCierreIncident, setInputCierreIncident] = useState("");
  const [imgsToAdd, setImgsToAdd] = useState<{ file: any; ext: string }[]>([]);
  // const [imgsToRemove, setImgsToRemove] = useState<any[]>([]);

  const handleInputComentIncident = (e: any) => {
    setInputComentIncident(e.target.value);
  };

  const handleInputCierreIncident = (e: any) => {
    setInputCierreIncident(e.target.value);
  };

  const { selectedIncident, update: updateIncidente } = useSelector<
    RootState,
    Iincidentes
  >((state) => state.incidentesReducer);
  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  const { businessAreas } = useSelector<RootState, AreasState>(
    (state) => state.areasReducer
  );

  useEffect(() => {
    if (!selectedIncident && incidentesId) {
      dispatch(getOneIncidente(incidentesId));
    }
  }, [selectedIncident, incidentesId]);

  useEffect(() => {
    if (!user) return;
    dispatch(getBusinessAreas(user?.business));
  }, [user]);

  const openImage = (src: string) => {
    if (src) window.open(src, "_blank");
  };

  const handleSaveComentario = () => {
    let estado = IncidentState.Pendiente;
    if (!valueArea) {
      setShowAError(true);
      return;
    }

    if (selectedIncident?.Area?.id !== valueArea?.id) {
      estado = IncidentState.PorEvaluar;
    }
    setShowAError(false);
    dispatch(
      updateComentIncidentRevision(
        { id: incidentesId },
        { comentario: inputComentIncident, area: valueArea?.Nombre },
        estado,
        { id: valueArea?.id, Nombre: valueArea?.Nombre }
      )
    );
  };

  const handleSaveCierre = () => {
    dispatch(
      updateComentIncidentCierre(
        { id: incidentesId },
        inputCierreIncident,
        IncidentState.Cerrado,
        imgsToAdd
      )
    );
  };

  useEffect(() => {
    if (inputComentIncident) setInputComentIncident("");
    if (valueArea) setArea(null);
    if (updateIncidente?.state === FormState.Success) {
      setImgsToAdd([]);
      dispatch(openSnack("Información Actualizada.", SnackState.SUCCESS));
      dispatch(getOneIncidente(incidentesId));
    } else if (updateIncidente?.state === FormState.Failure) {
      dispatch(openSnack("No se ha podido actualizar.", SnackState.ERROR));
    }
  }, [updateIncidente]);

  return (
    <>
      <Card mb={4} mt={1}>
        <CardContent>
          <Grid
            container
            spacing={4}
            style={{ minHeight: 280 }}
            justify="space-between"
          >
            <Grid item xs={12} sm={4}>
              <Typography>
                <b>Nombre</b>: {selectedIncident?.Usuario?.NombreCompleto}
                <br />
                <br />
                <b>Fecha</b>:{" "}
                {timesStampFormattedsimple(
                  selectedIncident?.FechaCreacion.toDate()
                )}
                <br />
                <br />
                <b>Lugar</b>: {selectedIncident?.Lugar}
                <br />
                <br />
              </Typography>
              <Typography>
                <b>Descripción</b>: {selectedIncident?.Descripcion}
              </Typography>
            </Grid>
            {selectedIncident?.Imagen0 && (
              <Grid item xs={12} sm={4}>
                <CardMedia
                  style={{ borderRadius: 20, maxHeight: 400 }}
                  // height="180"
                  alt="Imagen"
                  component="img"
                  title="imagen1"
                  onClick={() => openImage(selectedIncident?.Imagen0)}
                  src={selectedIncident?.Imagen0}
                />
                {/* )
                : (
                  <CardMedia
                    style={{ borderRadius: 20, maxHeight: 400 }}
                    // height="180"
                    alt="Imagen"
                    component="img"
                    title="imagen1"
                    src={noImagen}
                  /> */}
              </Grid>
            )}
            {selectedIncident?.Imagen1 && (
              <Grid item xs={12} sm={4}>
                <CardMedia
                  style={{ borderRadius: 20, maxHeight: 400 }}
                  // height="180"
                  alt="Imagen"
                  component="img"
                  title="imagen2"
                  onClick={() => openImage(selectedIncident?.Imagen1)}
                  src={selectedIncident?.Imagen1}
                />
                {/* ) : (
                   <CardMedia
                    style={{ borderRadius: 20, maxHeight: 400 }}
                    // height="180"
                    alt="Imagen"
                    component="img"
                    title="imagen2"
                    src={noImagen}
                  />
                 */}
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={7}>
              <TextField
                id="area-text"
                label="Area"
                value={selectedIncident?.Area?.Nombre || " - "}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                id="etiqueta-text"
                label="Etiqueta"
                value={selectedIncident?.Etiqueta || " - "}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item container xs={12} sm={6}>
              {(user?.tipoUsuario === "Jefe" ||
                user?.tipoUsuario === "Admin") &&
                selectedIncident?.Estado === "Por Evaluar" && (
                  <>
                    {selectedIncident?.Revision &&
                      Array.isArray(selectedIncident?.Revision) && (
                        <RevisionComponent
                          Revision={selectedIncident?.Revision}
                        />
                      )}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="outlined-textarea"
                        placeholder="Analisis  y medidas correctivas"
                        label="Analisis  y medidas correctivas"
                        fullWidth
                        rows="8"
                        multiline
                        variant="outlined"
                        value={inputComentIncident}
                        onChange={handleInputComentIncident}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: 5 }}
                        onClick={handleSaveComentario}
                      >
                        Guardar comentario.
                      </Button>
                      <Autocomplete
                        id="Area"
                        value={valueArea}
                        options={businessAreas}
                        getOptionLabel={(option) => option.Nombre}
                        onChange={(_: any, newValue: any | null) => {
                          if (newValue) {
                            setArea(newValue);
                          }
                        }}
                        style={{ marginTop: 10 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Area"
                            variant="outlined"
                            // error={touched.Area && Boolean(errors.Area)}
                          />
                        )}
                      />
                      {showAreaError && (
                        <Typography variant="caption" color="error">
                          Debe seleccionar área
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}

              {(selectedIncident?.Estado === "Pendiente de Cierre" ||
                selectedIncident?.Estado === "Cerrado") && (
                <RevisionComponent Revision={selectedIncident?.Revision} />
              )}
            </Grid>

            <Grid item container xs={12} sm={6}>
              {selectedIncident?.Estado === "Cerrado" && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Alert icon={false} severity="warning">
                      <Typography component="div">
                        <Box fontWeight="fontWeightBold" display="inline">
                          {`Tipo de Usuario: `}
                        </Box>
                        {selectedIncident?.CierreIncidente?.tipoUsuario}
                      </Typography>
                      <Typography component="div">
                        <Box fontWeight="fontWeightBold" display="inline">
                          {`Usuario: `}
                        </Box>
                        {
                          selectedIncident?.CierreIncidente
                            ?.UsuarioNombreCompleto
                        }
                      </Typography>
                      <Typography component="div">
                        <Box fontWeight="fontWeightBold" display="inline">
                          {`Fecha: `}
                        </Box>
                        {timesStampFormattedsimple(
                          selectedIncident?.selectedIncident?.CierreIncidente?.FechaCierre.toDate()
                        )}
                      </Typography>
                    </Alert>
                    <Alert
                      icon={false}
                      severity="success"
                      style={{ marginTop: 3 }}
                    >
                      <Typography component="div">
                        <Box fontWeight="fontWeightBold">
                          {`Revisión medidas correctivas y cierre(Area de Prevención)`}
                        </Box>
                        {selectedIncident?.CierreIncidente?.ComentarioCierre}
                      </Typography>
                    </Alert>
                    {selectedIncident?.CierreIncidente &&
                      selectedIncident?.CierreIncidente?.Imagenes &&
                      Array.isArray(
                        selectedIncident?.CierreIncidente?.Imagenes
                      ) && (
                        <Box
                          justifyContent="flex-start"
                          display="flex"
                          mt={5}
                          style={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: 10,
                          }}
                          px={2}
                          py={2}
                        >
                          {selectedIncident?.CierreIncidente?.Imagenes.map(
                            (img: any, index: number) => (
                              <Box
                                order={index + 1}
                                mr={1}
                                key={"key-" + index}
                              >
                                <CardMedia
                                  component="img"
                                  style={{
                                    height: 120,
                                    width: 120,

                                    borderRadius: 10,
                                    objectFit: "cover",
                                  }}
                                  image={img.Url}
                                  onClick={() => openImage(img.Url)}
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                  </Grid>
                </>
              )}

              {
                // selectedIncident?.Revision?.Comentario !== "" &&
                user?.tipoUsuario === "Admin" &&
                  selectedIncident?.Estado === "Pendiente de Cierre" && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="outlined-textarea"
                          placeholder="Revisión medidas correctivas y cierre"
                          label="Revisión medidas correctivas y cierre"
                          fullWidth
                          rows="5"
                          multiline
                          variant="outlined"
                          value={inputCierreIncident}
                          onChange={handleInputCierreIncident}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginTop: 5 }}
                          onClick={handleSaveCierre}
                        >
                          Agregar Analisis
                        </Button>
                        <Grid item xs={12} sm={12} style={{ marginTop: 5 }}>
                          <Box justifyContent="flex-start" display="flex">
                            {imgsToAdd.map((img, index) => (
                              <Box
                                order={index + 1}
                                mr={1}
                                key={"key+" + index}
                              >
                                <CardMedia
                                  component="img"
                                  style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: 10,
                                    objectFit: "cover",
                                  }}
                                  image={URL.createObjectURL(img.file)}
                                />
                              </Box>
                            ))}
                          </Box>
                          <IconButton
                            aria-label="Agregar"
                            component="label"
                            color="primary"
                            disabled={imgsToAdd.length >= 3}
                          >
                            <PlusCircle />
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) => {
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  const file = event.target.files[0];
                                  const nameArr = file.name.split(".");
                                  const fileExt = nameArr[nameArr.length - 1];
                                  imgsToAdd.length < 3 &&
                                    setImgsToAdd([
                                      ...imgsToAdd,
                                      { file: file, ext: fileExt },
                                    ]);
                                }
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: 5 }}>
                          <Typography variant="caption">
                            Seleccionar Imagenes (Max. 3)
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

interface RevisionCompProps {
  Revision: any;
}

const RevisionComponent: React.FC<RevisionCompProps> = ({ Revision }) => {
  if (!Revision) return null;

  if (Array.isArray(Revision)) {
    return (
      <React.Fragment>
        {Revision.map((item, index) => (
          <RevComponentItem key={index} Revision={item} />
        ))}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <RevComponentItem Revision={Revision} />
    </React.Fragment>
  );
};

interface RevCompItemProps {
  Revision: any;
}
const RevComponentItem: React.FC<RevCompItemProps> = ({ Revision }) => {
  return (
    <React.Fragment>
      <Grid container item xs={12} sm={12} style={{ marginBottom: 15 }}>
        <Card style={{ backgroundColor: "#f5f5f5", padding: 5 }}>
          <Box justifyContent="flex-start" style={{ width: "100%" }}>
            <Alert icon={false} severity="info">
              <Typography component="div">
                <Box fontWeight="fontWeightBold" display="inline">
                  {`Tipo de Usuario: `}
                </Box>
                {Revision?.tipoUsuario}
              </Typography>
              <Typography component="div">
                <Box fontWeight="fontWeightBold" display="inline">
                  {`Usuario: `}
                </Box>
                {Revision?.UsuarioNombreCompleto}
              </Typography>
              <Typography component="div">
                <Box fontWeight="fontWeightBold" display="inline">
                  {`Fecha: `}
                </Box>
                {timesStampFormattedsimple(Revision?.FechaRevision?.toDate())}
              </Typography>
              <Typography component="div">
                <Box fontWeight="fontWeightBold" display="inline">
                  {`Area: `}
                </Box>
                {Revision?.Area || " - "}
              </Typography>
            </Alert>
            <Box order={2} mt={1}>
              <Alert icon={false} severity="success">
                <Typography component="div">
                  <Box fontWeight="fontWeightBold">
                    {`Analisis y medidas correctivas (Area de Jefatura). `}
                    <Box fontWeight="fontWeightMedium" display="inline">
                      {`Recomendación:`}
                    </Box>
                  </Box>
                  {Revision?.Comentario}
                </Typography>
              </Alert>
            </Box>
          </Box>
        </Card>
      </Grid>
    </React.Fragment>
  );
};

export const ViewIcidentes = () => (
  <React.Fragment>
    <Helmet title="Detalle Incidente" />
    <Typography variant="h3" gutterBottom display="inline">
      Completar Incidentes
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} exact to="/Incidentes">
        Incidentes
      </Link>
      <Typography>Detalle de Incidente</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
