import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { IAriesgo } from "../redux/reducers/ariesgoReducer";
import {
  getOneAriesgo,
  setSelectedAriesgo,
} from "../redux/actions/ariesgoActions";
import { exportToCsv, timesStampFormattedsimple } from "../utils/utils";
import { Download } from "react-feather";
import { Alert } from "@material-ui/lab";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const { artId } = useParams<any>();
  const dispatch = useDispatch();

  const { selectedAriesgo, totalDocs, state } = useSelector<
    RootState,
    IAriesgo
  >((state) => state.artReducer);

  useEffect(() => {
    if (!selectedAriesgo ) {
      //&& selectedAriesgo?.FechaCreacion 
      // console.log("dispaching ehereee");

      dispatch(getOneAriesgo(artId));
    }
  }, [selectedAriesgo, artId]);

  return (
    <>
      <Card style={{ backgroundColor: "#f4f5f5f8" }} mb={4}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Area:</b>
                <br /> {selectedAriesgo?.Area}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Nombre</b>:<br /> {selectedAriesgo?.Usuario?.Nombre}{" "}
                {selectedAriesgo?.Usuario?.Apellido}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Fecha:</b> <br />
                {timesStampFormattedsimple(
                  selectedAriesgo?.FechaCreacion.toDate()
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Descripcion de la tarea:</b>
                <br /> {selectedAriesgo?.Descripcion}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>Preguntas Generales:</b>
              </Typography>
              <br />
              <Typography>
                1.- ¿Estoy autorizado para realizar el trabajo?
                <br />
                <br />
                {selectedAriesgo?.Preguntas[0] ? (
                  <Alert style={{ width: 100 }} severity="success">
                    SI
                  </Alert>
                ) : (
                  <Alert style={{ width: 100 }} severity="error">
                    NO
                  </Alert>
                )}
              </Typography>
              <br />
              <Typography>
                2.- ¿Tengo las herramientas y los equipos en buen estado para
                desarrollar la tarea con seguridad?
                <br />
                <br />
                {selectedAriesgo?.Preguntas[1] ? (
                  <Alert style={{ width: 100 }} severity="success">
                    SI
                  </Alert>
                ) : (
                  <Alert style={{ width: 100 }} severity="error">
                    NO
                  </Alert>
                )}
              </Typography>
              <br />
              <Typography>
                3.- ¿Estoy en condiciones para realizar el trabajo?
                <br />
                <br />
                {selectedAriesgo?.Preguntas[2] ? (
                  <Alert style={{ width: 100 }} severity="success">
                    SI
                  </Alert>
                ) : (
                  <Alert style={{ width: 100 }} severity="error">
                    NO
                  </Alert>
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: "#f4f5f5f8" }} mb={4}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">
                <b>Analisis y Riesgos del Entorno</b>
              </Typography>
            </Grid>
            <br />
            <br />
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>RESIDUOS GENERADOS:</b>
              </Typography>
              {selectedAriesgo?.Residuos?.map((e: any) => {
                return (
                  <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                    <Typography>-{e}</Typography>
                  </Box>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography>
                <b>PARTICIPANTES:</b>
              </Typography>
              {selectedAriesgo?.Participantes?.map((e: any) => {
                return (
                  <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                    <Typography>Nombre: {e.Nombre}</Typography>
                    <Typography>Rut: {e.Rut}</Typography>
                  </Box>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>PELIGROS/CONCECUENCIAS:</b>
              </Typography>
              {selectedAriesgo?.PeligrosYRiesgos?.map((e: any) => {
                return (
                  <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                    <Typography>-{e}</Typography>
                  </Box>
                );
              })}
              {selectedAriesgo?.Consecuencias?.map((e: any) => {
                return (
                  <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                    <Typography>-{e}</Typography>
                  </Box>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <b>MEDIDAS DE CONTROL:</b>
              </Typography>
              {selectedAriesgo?.MedidasControl?.map((e: any) => {
                return (
                  <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                    <Typography>-{e}</Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: "#f4f5f5f8" }}>
        <CardContent>
          <Grid container>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              sm={12}
            >
              <Typography variant="h4" style={{ marginBottom: 20 }}>
                <b>
                  Análisis y Resumen de la Tarea ({" "}
                  {selectedAriesgo?.Descripcion} )
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card style={{ backgroundColor: "#f4f5f5f8" }} mb={4}>
                <CardContent>
                  <Typography>
                    <b>1-PASOS DE LA TAREA</b>
                  </Typography>
                  <br />
                  {selectedAriesgo?.Tareas?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e.Pasos}</Typography>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card style={{ backgroundColor: "#f4f5f5f8" }} mb={4}>
                <CardContent>
                  <Typography>
                    <b>2-PELIGROS/CONSECUENCIAS</b>
                  </Typography>
                  <br />
                  {selectedAriesgo?.PeligrosYRiesgos?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e}</Typography>
                      </Box>
                    );
                  })}
                  {selectedAriesgo?.Consecuencias?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e}</Typography>
                      </Box>
                    );
                  })}
                  {selectedAriesgo?.Tareas?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e.Peligros}</Typography>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card style={{ backgroundColor: "#f4f5f5f8" }} mb={4}>
                <CardContent>
                  <Typography>
                    <b>3-MEDIDAS DE CONTROL</b>
                  </Typography>
                  <br />
                  {selectedAriesgo?.MedidasControl?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e}</Typography>
                      </Box>
                    );
                  })}
                  {selectedAriesgo?.Tareas?.map((e: any) => {
                    return (
                      <Box key={e.id} style={{ marginBottom: 8, marginTop: 4 }}>
                        <Typography>-{e.Medidas}</Typography>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export const ArtView = () => (
  <React.Fragment>
    <Helmet title="Detalle ART" />
    <Typography variant="h3" gutterBottom display="inline">
      Análisis y Riesgo de la Tarea.
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} exact to="/analisisRiesgos">
        Lista de ART
      </Link>
      <Typography>Detalle del Análisis</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
