import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { AreaModel } from "../models/area";

interface Props {
  data?: AreaModel;
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, id: string) => void;
}

export const NewEditArea: React.FC<Props> = React.memo(
  ({ data, open, onAccept, onClose }) => {
    // const { user } = useSelector<RootState, IAuthState>(
    //   (state) => state.authReducer
    // );

    const [selected, setSelected] = useState("");

    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      setValues,
      resetForm,
    } = useFormik({
      initialValues: {
        Nombre: "",
        Descripcion: "",
      },
      onSubmit: (values) => {
        onAccept(values, selected);
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
        Descripcion: yup.string().required("Descripción requerida"),
      }),
    });

    useEffect(() => {
      if (data && open) {
        setSelected(data?.id || "");
        setValues({
          Nombre: data?.Nombre,
          Descripcion: data?.Descripcion,
        });
      } else if (!open) {
        setSelected("");
        resetForm();
      }
    }, [data, open]);

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              onClose();
            }
          }}
          aria-labelledby="add-and-update-dialog-title"
        >
          <DialogTitle id="add-and-update-dialog-title">
            {data ? "Editar Área" : "Añadir Área"}
          </DialogTitle>

          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={3} justify="space-between">
                <Grid item sm={12} xs={12}>
                  <TextField
                    autoFocus
                    id="Nombre"
                    label="Nombre"
                    type="text"
                    value={values?.Nombre}
                    name="Nombre"
                    onChange={handleChange}
                    error={touched.Nombre && Boolean(errors.Nombre)}
                    helperText={touched.Nombre && errors.Nombre}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    id="Descripcion"
                    label="Descripción"
                    type="text"
                    multiline
                    rows={4}
                    value={values?.Descripcion}
                    name="Descripcion"
                    onChange={handleChange}
                    error={touched.Descripcion && Boolean(errors.Descripcion)}
                    helperText={touched.Descripcion && errors.Descripcion}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="combo-box-permisos"
                    value={
                      values.TipoPermisos
                        ? tipoPermisosOptions.find(
                            (x) => x.Tipo === values?.TipoPermisos
                          )
                        : valPermiso
                    }
                    options={tipoPermisosOptions}
                    getOptionLabel={(option) => option.Nombre}
                    getOptionDisabled={(option) =>
                      user?.tipoUsuario !== "Admin"
                        ? option.Tipo === "Admin"
                        : false
                    }
                    onChange={(event: any, newValue: any | null) => {
                      setValPermiso(newValue);
                      if (newValue) {
                        values.TipoPermisos = newValue.Tipo;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Permisos"
                        variant="outlined"
                        error={
                          touched.TipoPermisos && Boolean(errors.TipoPermisos)
                        }
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
);
