// import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";

export const getUserInformation = (id: string): AppThunk => {
  return async (dispatch) => {
    try {
      let query = firestore.collection("Usuarios").doc(id);

      const response = await query.get();

      const usrDB = response.data();

      const form = {
        basicInfo: {
          Nombre: usrDB?.Nombre,
          Apellido: usrDB?.Apellido,
          Area: usrDB?.Area,
          InduccionCompleta: usrDB?.InduccionCompleta,
          Aprobado: usrDB?.Aprobado,
          Avatar: usrDB?.Avatar,
        },
        documentsInfo: usrDB?.Documentos,
      };

      dispatch(setInformation(form));
    } catch (error: any) {
      console.log(error);
    }
  };
};

const setInformation = (form: {
  basicInfo: {
    Nombre: string;
    Apellido: string;
    Area: string;
    InduccionCompleta: boolean;
    Aprobado: boolean;
    Avatar?: any;
  } | null;
  documentsInfo: any;
}): Action => ({
  type: types.SET_INFO_USER_VIEW,
  payload: form,
});
