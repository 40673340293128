import * as types from "../../constants";
import { Action } from "../../models/action";

export interface IButtonState {
  numero: number;
}

const initialState = {
  numero: 0,
} as IButtonState;

export const buttonReducer = (
  state = initialState,
  action: Action
): IButtonState => {
  switch (action.type) {
    case types.BUTTON_PLUS:
      return {
        ...state,
        numero: state.numero + 1,
      };
    case types.BUTTON_MINUS:
      return {
        ...state,
        numero: state.numero - 1,
      };
    default:
      return state;
  }
};
