import Axios from "axios";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firebaseConfig, firestore, auth } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { NewGoogleUserResponse } from "../../models/new_google_user_response";
import { SnackState } from "../../models/snack-state";
import {
  cleanString,
  exportToCsv,
  handleFiltro,
  lowerFieldValidation,
} from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const API_KEY = firebaseConfig.apiKey;

const collectionNames = {
  bsns: "AreasBSNS",
  aclara: "Areas",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export const exportAreas = (
  order: string = "FechaCreacion",
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      query = firestore.collection(collName(user)).orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user?.business);
      }

      const response = await sortQueries(query, filter).get();

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      exportToCsv(
        "usuarios.csv",
        data,
        [
          "Nombre",
          "Apellido",
          "Rut",
          "Area",
          "Cargo",
          "Edad",
          "Email",
          "Telefono",
          "FechaIngreso",
        ],
        [
          "Nombre",
          "Apellido",
          "Rut",
          "Area",
          "Cargo",
          "Edad",
          "Email",
          "Telefono",
          "Fecha de Ingreso",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getAreasAll = (order: string = "NombreLower"): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_GET_IN_REQUEST,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy(order);

      if (user?.business) {
        query = query.where("Empresa", "==", user?.business);
      }

      const response = await query.get();

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({
        type: types.AREAS_SET_ALL_LIST,
        payload: data,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.AREAS_GET_FAILURE,
        payload: "error",
      });
    }
  };
};

const handleOrder = (order: string, filters: any) => {
  if (filters && Object.keys(filters).length > 0) {
    if (
      Object.keys(filters).includes("startAt") ||
      Object.keys(filters).includes("endAt")
    ) {
      return "FechaCreacion";
    }
  }
  return order;
};

export const getAreas = (
  order: string = "FechaCreacion",
  filter: filterType = [],
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_GET_IN_REQUEST,
    });
    const { user } = getState().authReducer;
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }

      query = firestore.collection(collName(user)).orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user?.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const responseAll = await sortQueries(query, filter).get();

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lastDoc = response.docs[response.docs.length - 1];
      dispatch({
        type: types.AREAS_GET_SUCCESS,
        payload: { data: data, lastDoc: lastDoc, totalDocs: responseAll.size },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.AREAS_GET_FAILURE,
        payload: "error",
      });
    }
  };
};

export const getMoreAreas = (
  order: string = "NombreLower",
  filtro?: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_GET_IN_REQUEST,
    });
    const { get: getAreasStatus } = getState().areasReducer;
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy(order)
        .startAfter(getAreasStatus.lastDoc);

      if (user?.business) {
        query = query.where("Empresa", "==", user?.business);
      }

      query = handleFiltro(filtro, query);

      const response = await query.limit(limit).get();

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lastDoc = response.docs[response.docs.length - 1];
      dispatch({
        type: types.AREAS_GET_MORE_SUCCESS,
        payload: { data: data, lastDoc: lastDoc },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.AREAS_GET_FAILURE,
        payload: "error",
      });
    }
  };
};

export const createArea = (area: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_UPDATE_CREATE_IN_REQUEST,
    });
    const { user } = getState().authReducer;
    try {
      if (user?.business) {
        area["Empresa"] = user.business;
      }
      await firestore.collection(collName(user)).add({
        ...area,
        NombreLower: cleanString(area?.Nombre || ""),
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
      });
      dispatch(openSnack("Área creada correctamente", SnackState.SUCCESS));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("Área no se ha podido crear", SnackState.ERROR));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_FAILURE,
        payload: "error",
      });
    }
  };
};
export const updateArea = (area: any, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_UPDATE_CREATE_IN_REQUEST,
    });
    const { user } = getState().authReducer;
    try {
      await firestore
        .collection(collName(user))
        .doc(id)
        .update({
          ...area,
          NombreLower: cleanString(area?.Nombre || ""),
        });
      dispatch(openSnack("Área actualizada correctamente", SnackState.SUCCESS));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("Área no se ha podido actualizar", SnackState.ERROR));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_FAILURE,
        payload: "error",
      });
    }
  };
};

export const deleteArea = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.AREAS_UPDATE_CREATE_IN_REQUEST,
    });
    const { user } = getState().authReducer;
    try {
      await firestore.collection(collName(user)).doc(id).delete();
      dispatch(openSnack("Área eliminada correctamente", SnackState.SUCCESS));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("Área no se ha podido eliminar", SnackState.ERROR));
      dispatch({
        type: types.AREAS_UPDATE_CREATE_FAILURE,
        payload: "error",
      });
    }
  };
};

export const getBusinessAreas = (
  business?: firebase.firestore.DocumentReference
): AppThunk => {
  return async (dispatch) => {
    try {
      let query = firestore
        .collection(collName({ business: business }))
        .orderBy("Nombre");

      if (business) {
        query = query.where("Empresa", "==", business);
      }

      const response = await query.get();

      const data: any[] = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({
        type: types.SET_AREAS_LIST_USER,
        payload: data,
      });
    } catch (error: any) {
      console.log(error);
    }
  };
};

// export const setSelectedUser = (user: any): Action => ({
//   type: types.USERS_SET_SELECTED,
//   payload: user,
// });
