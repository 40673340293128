import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { RootState } from "../redux/reducers/rootReducer";
import { answersReducer, AnswersState } from "../redux/reducers/answersReducer";
import { useDispatch, useSelector } from "react-redux";
import { ICovidFormsState } from "../redux/reducers/covidFormsReducer";
import { getOneCovidForm } from "../redux/actions/covidFormsActions";
import { AlertTriangle } from "react-feather";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = React.memo(() => {
  const dispatch = useDispatch();
  const { respuestaId } = useParams<any>();
  const { selectedAnswers } = useSelector<RootState, AnswersState>(
    (state) => state.answersReducer
  );

  const {
    edit: { selectedForm },
  } = useSelector<RootState, ICovidFormsState>(
    (state) => state.covidFormsReducer
  );

  useEffect(() => {
    dispatch(getOneCovidForm(selectedAnswers.Formulario));
  }, []);

  useEffect(() => {}, [selectedAnswers]);
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <span>
              <Button
                variant="outlined"
                disableElevation={true}
                disableRipple={true}
              >
                Formulario: {selectedAnswers?.Formulario.Nombre}
              </Button>
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} style={{ marginBlock: 20 }}>
            Información Personal:
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Nombre"
              variant="outlined"
              value={selectedAnswers?.NombreCompleto}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="outlined-basic"
              label="Edad"
              variant="outlined"
              value={selectedAnswers?.Edad}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="outlined-basic"
              label="Rut"
              variant="outlined"
              value={selectedAnswers?.Rut}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={selectedAnswers?.Email}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              label="Telefono"
              variant="outlined"
              value={selectedAnswers?.Telefono}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginBlock: 20 }}>
            Respuestas:
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Typography>- Medio de Transporte:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{selectedAnswers?.MedioDeTransporte}</Typography>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Typography>- Temperatura corporal:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Box order={1} mr={2} mt={1}>
                <Typography>{selectedAnswers?.TemperaturaCorporal}°</Typography>
              </Box>
              {selectedAnswers?.TemperaturaCorporal > 38 ? (
                <Box order={2}>
                  <AlertTriangle color="red" height={22} />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>- ¿Ha tenido Covid?:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Box order={1} mr={2} mt={1}>
                <Typography>
                  {selectedAnswers?.HaTenidoCovid === 1 ? "Si" : "No"}
                </Typography>
              </Box>
              {selectedAnswers?.HaTenidoCovid === 1 ? (
                <Box order={2}>
                  <AlertTriangle color="red" height={22} />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          {selectedAnswers?.HaTenidoCovid === 1 ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography>- Mes en que ha tenido Covid:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{selectedAnswers?.MesHaTenidoCovid}</Typography>
              </Grid>
            </>
          ) : (
            ""
          )}

          {selectedAnswers?.SintomasCovid ? (
            <>
              <Grid item xs={12} sm={12}>
                <Typography>- Síntomas:</Typography>
              </Grid>
              {selectedAnswers.SintomasCovid.map((data: any, index: number) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Box display="flex">
                    <Box order={1} mr={2} mt={1}>
                      <Typography style={{ marginInlineStart: 20 }}>
                        {data}
                      </Typography>
                    </Box>
                    {data !== "Ninguno" ? (
                      <Box order={2}>
                        <AlertTriangle color="red" height={22} />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            ""
          )}

          {selectedAnswers?.Preguntas ? (
            <>
              {selectedAnswers.Preguntas.map((data: any, index: number) => (
                <>
                  <Grid item xs={12} sm={6} key={index}>
                    <Typography>- {data.Descripcion}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} key={index + 100}>
                    <Box display="flex">
                      <Box order={1} mr={2} mt={1}>
                        <Typography>
                          {data.Respuesta === 1 ? "Si" : "No"}
                        </Typography>
                      </Box>
                      {selectedForm?.Preguntas ? (
                        Number(selectedForm.Preguntas[index].Respuesta) ===
                        data.Respuesta ? (
                          <Box order={2}>
                            <AlertTriangle color="red" height={22} />
                          </Box>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>
                </>
              ))}
            </>
          ) : (
            ""
          )}
        </Grid>
      </CardContent>
    </Card>
  );
});

export const Answer = () => {
  return (
    <React.Fragment>
      <Helmet title="Respuesta" />
      <Typography variant="h3" gutterBottom display="inline">
        Respuesta Formulario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/formulariosCovid/respuestas">
          Respuestas
        </Link>
        <Typography>Respuesta</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
