import Axios from "axios";
import firebase from "firebase/app";
import * as types from "../../constants";
import {
  firebaseConfig,
  firestore,
  auth,
  storage,
} from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { NewGoogleUserResponse } from "../../models/new_google_user_response";
import { SnackState } from "../../models/snack-state";
import {
  cleanString,
  exportToCsv,
  lowerFieldValidation,
} from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";
import { getFileNameExtension } from "../../utils/utils";

const API_KEY = firebaseConfig.apiKey;

const dir = (order: string) => {
  if (order === "FechaIngreso") return "desc";
  return undefined;
};

export const exportUsers = (
  order: string = "Nombre_lower",
  filter: filterType = [],
  business?: firebase.firestore.DocumentReference
): AppThunk => {
  return async () => {
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      if (
        filter.length === 1 &&
        filter[0].field === "Cargo" &&
        order === "Cargo"
      ) {
        query = firestore
          .collection("Usuarios")
          // .orderBy(order, dir(order)) // To prevent firebase query errors
          .where("Activo", "==", true);
      } else {
        query = firestore
          .collection("Usuarios")
          .orderBy(order, dir(order))
          .where("Activo", "==", true);
      }

      if (business) {
        query = query.where("Empresa", "==", business);
      }

      const response = await sortQueries(query, filter, "FechaIngreso").get();
      const users = mapUsers(response);

      exportToCsv(
        "usuarios.csv",
        users,
        [
          "Nombre",
          "Apellido",
          "Rut",
          "Area",
          "Cargo",
          // "Edad",
          "Email",
          "Telefono",
          "FechaIngreso",
        ],
        [
          "Nombre",
          "Apellido",
          "Rut",
          "Area",
          "Cargo",
          // "Edad",
          "Email",
          "Telefono",
          "Fecha de Ingreso",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getUsers = (
  order: string = "Nombre_lower",
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  business?: firebase.firestore.DocumentReference
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      if (
        filter.length === 1 &&
        filter[0].field === "Cargo" &&
        order === "Cargo"
      ) {
        query = firestore
          .collection("Usuarios")
          // .orderBy(order, dir(order)) // To prevent firebase query errors
          .where("Activo", "==", true);
      } else {
        query = firestore
          .collection("Usuarios")
          .orderBy(order, dir(order))
          .where("Activo", "==", true);
      }

      if (business) {
        query = query.where("Empresa", "==", business);
      }

      const response = await sortQueries(query, filter, "FechaIngreso")
        .limit(limit)
        .get();
      const response2 = await sortQueries(query, filter, "FechaIngreso").get();
      dispatch(setUsers(mapUsers(response)));
      dispatch(setTotalDocs(response2.size));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addMoreUsers = (
  order: string = "Nombre_lower",
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  business?: firebase.firestore.DocumentReference
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const lastDoc = getState().usersReducer.lastDoc || "";
    try {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      // let query = firestore.collection("Usuarios").orderBy(order, dir(order));

      if (
        filter.length === 1 &&
        filter[0].field === "Cargo" &&
        order === "Cargo"
      ) {
        query = firestore
          .collection("Usuarios")
          // .orderBy(order, dir(order)) // To prevent firebase query errors
          .where("Activo", "==", true);
      } else {
        query = firestore
          .collection("Usuarios")
          .orderBy(order, dir(order))
          .where("Activo", "==", true);
      }

      if (business) {
        query = query.where("Empresa", "==", business);
      }

      const response = await sortQueries(query, filter, "FechaIngreso")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      dispatch(addUsers(mapUsers(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getUsersFiltered = (
  filtro: any = {},
  order: string = "FechaIngreso",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection("Usuarios")
        .orderBy(order, dir(order))
        .where("Activo", "==", true);

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];

        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.get();

      dispatch(setUsers(mapUsers(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getUser = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Usuarios").doc(id).get();

      dispatch(setSelectedUser(mapUser(response)));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const createUser = (user: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user: authUser } = getState().authReducer;
    try {
      //Check RUT UNIQUE
      let usrsQuery = firestore
        .collection("Usuarios")
        .where("Rut", "==", user.Rut)
        .where("Activo", "==", true);

      if (authUser?.business) {
        usrsQuery = usrsQuery.where("Empresa", "==", authUser.business);
      }
      const usrsSnap = await usrsQuery.get();

      if (usrsSnap.size > 0) {
        dispatch(openSnack("Rut se encuentra en uso", SnackState.ERROR));
        dispatch(isLoading(false));
        return;
      }
      //

      const { code, uid } = await createUserAuth({
        email: user.Email,
        password: user.Contrasenia,
      });

      if (code !== "OK" || !uid) {
        const msg = "Error al crear usuario";
        if (code !== "ERROR") {
          console.log(code);
        }
        dispatch(openSnack(msg, SnackState.ERROR));
        dispatch(isLoading(false));
        return;
      }

      // const uid = res.data.localId;
      user.Nombre_lower = cleanString(user.Nombre);
      user.Apellido_lower = cleanString(user.Apellido);

      if (authUser?.business) {
        user["EmpresaExterna"] = true;
        user["Empresa"] = authUser.business;
      }
      user["Progreso"] = "0/13";

      if (user?.Avatar instanceof File) {
        const fileExt = getFileNameExtension(user.Avatar.name);
        const filePath = "AvatarsUsers/" + uid + "." + fileExt;
        const fileRef = storage.ref(filePath);
        await fileRef.put(user.Avatar);
        const url = await fileRef.getDownloadURL();
        user.Avatar = { Url: url, Uri: filePath };
      } else if (typeof user?.Avatar === "undefined") {
        user.Avatar = "";
      }

      await firestore
        .collection("Usuarios")
        .doc(uid)
        .set({
          ...user,
        });

      dispatch(
        getUsers(
          "Nombre_lower",
          types.TABLE_LIMIT_DEFAULT,
          [],
          authUser?.business
        )
      );
      dispatch(openSnack("Usuario creado con éxito", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const sendUserEmail = (form: {
  email: string;
  mensaje: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      await Axios.post(
        // "http://localhost:5000/biolantanidos/us-central1/app/alert/user",
        "https://us-central1-biolantanidos.cloudfunctions.net/app/alert/user",
        form,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(
        openSnack("Correo de notificación enviado.", SnackState.SUCCESS)
      );
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("Ha ocurrido un problema", SnackState.ERROR));
    }
  };
};

export const updateUserAuth = async (
  newProps: {
    email: string;
    password: string;
  },
  userId: string
) => {
  try {
    const { data } = await Axios.post(
      "https://us-central1-biolantanidos.cloudfunctions.net/app/update/user/auth",
      // "http://localhost:5000/biolantanidos/us-central1/app/update/user/auth",
      {
        id: userId,
        newEmail: newProps?.email || "",
        newPassword: newProps?.password || "",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log(error);
    return { code: "error" };
  }
};

export const createUserAuth = async (creds: {
  email: string;
  password: string;
}) => {
  try {
    const { data } = await Axios.post(
      "https://us-central1-biolantanidos.cloudfunctions.net/app/create/user/auth",
      // "http://localhost:5000/biolantanidos/us-central1/app/create/user/auth",
      creds,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log(error);
    return { code: "error" };
  }
};

export const removeUserAuth = async (creds: { email: string }) => {
  try {
    const { data } = await Axios.post(
      "https://us-central1-biolantanidos.cloudfunctions.net/app/delete/user/auth",
      // "http://localhost:5000/biolantanidos/us-central1/app/delete/user/auth",
      creds,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error: any) {
    console.log(error);
    return { code: "error" };
  }
};

export const restorePassword = (user: any): AppThunk => {
  return async (dispatch) => {
    try {
      //Crear usuario en Authentication
      const res: any = await Axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`,
        {
          requestType: "PASSWORD_RESET",
          email: user.Email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(openSnack("Correo enviado", SnackState.SUCCESS));
    } catch (error: any) {
      console.log(error);
      dispatch(openSnack("Hubo un problema", SnackState.ERROR));
    }
  };
};

export const updateUser = (
  user: any,
  oldUser: any,
  foreignUpdate: boolean = false
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(editIsLoading(true));
    const { user: authUser } = getState().authReducer;
    try {
      const data: any = { ...user };
      delete data.id;
      data.Nombre_lower = cleanString(data.Nombre);
      data.Apellido_lower = cleanString(data.Apellido);

      if (foreignUpdate) {
        delete data.Area;
        delete data.AreaId;
        delete data.Cargo;
        delete data.TipoPermisos;
      }

      if (data?.Avatar instanceof File) {
        const fileExt = getFileNameExtension(data.Avatar.name);
        const filePath = "AvatarsUsers/" + user.id + "." + fileExt;
        const fileRef = storage.ref(filePath);
        await fileRef.put(data.Avatar);
        const url = await fileRef.getDownloadURL();
        data.Avatar = { Url: url, Uri: filePath };
      } else if (typeof data?.Avatar === "undefined") {
        data.Avatar = "";
      }

      //Check RUT UNIQUE
      let usrsQuery = firestore
        .collection("Usuarios")
        .where("Rut", "==", user.Rut)
        .where("Activo", "==", true);

      if (authUser?.business) {
        usrsQuery = usrsQuery.where("Empresa", "==", authUser.business);
      }
      const usrsSnap = await usrsQuery.get();

      if (usrsSnap.size > 1) {
        dispatch(openSnack("Rut se encuentra en uso", SnackState.ERROR));
        dispatch(editIsLoading(false));
        return;
      } else if (usrsSnap.size === 1 && usrsSnap.docs[0].id !== user.id) {
        dispatch(openSnack("Rut se encuentra en uso", SnackState.ERROR));
        dispatch(editIsLoading(false));
        return;
      }
      ///

      let code: any = "OK";
      const credentials: any = {};
      if (data.Email !== oldUser.Email) {
        credentials["email"] = data.Email;
      }
      if (data?.Contrasenia && data.Contrasenia !== oldUser.Contrasenia) {
        credentials["password"] = data.Contrasenia;
      }
      if (Object.keys(credentials).length > 0) {
        const { code: codeAuth } = await updateUserAuth(credentials, user.id);
        code = codeAuth;
      }
      if (code === "OK") {
        await firestore.collection("Usuarios").doc(user.id).update(data);
        dispatch(openSnack("Datos han sido actualizados", SnackState.SUCCESS));
        dispatch(getUser(user.id));
      } else if (code === "auth/email-already-exists") {
        dispatch(openSnack("Correo ya existe", SnackState.ERROR));
      } else {
        dispatch(openSnack("Hubo un ERROR", SnackState.ERROR));
      }
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(editIsLoading(false));
    }
  };
};

export const updateDocuments = (
  userId: string,
  documents: any = {},
  progreso: string = "0/0",
  estado: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(editIsLoading(true));
    try {
      const docs: any = {};
      for (const key of Object.keys(documents)) {
        const value = documents[`${key}`];
        if (value?.file) {
          if (value?.url) delete value.url;
          if (value?.oldFilePath) {
            const oldFileRef = storage.ref(value.oldFilePath);
            try {
              await oldFileRef.delete();
            } catch {}
            delete value.oldFilePath;
          }
          const fileRef = storage.ref(value.filePath);
          await fileRef.put(value.file);
          const url = await fileRef.getDownloadURL();
          value["url"] = url;
          delete value.file;
        }
        docs[`${key}`] = value;
      }

      const uptData = {
        Documentos: docs,
        Progreso: progreso,
        EstadoProgreso: estado,
      };

      await firestore.collection("Usuarios").doc(userId).update(uptData);
      dispatch(openSnack("Datos han sido actualizados", SnackState.SUCCESS));
      dispatch(getUser(userId));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    } finally {
      dispatch(editIsLoading(false));
    }
  };
};
// update email and password
export const updateEmailAndPassword = (user: any, values: any): AppThunk => {
  return async (dispatch) => {
    dispatch(editIsLoading(true));
    try {
      await Axios.post(
        "https://us-central1-biolantanidos.cloudfunctions.net/app/update/email/password",
        {
          email: user.Email,
          id: user.id,
          newEmail: values.Email,
          newPassword: values.NuevaContrasenia,
          contrasenia: user.Contrasenia,
        }
      );
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
      dispatch(editIsLoading(false));
    }
  };
};

export const deleteUser = (selected: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(editIsLoading(true));
    const { user } = getState().authReducer;

    try {
      // if (selected?.TipoUsuario === "Admin") {
      await firestore.collection("Usuarios").doc(selected.id).update({
        Activo: false,
        EliminadoPor: user.id,
        FechaEliminacion: firebase.firestore.FieldValue.serverTimestamp(),
      });
      // } else {
      // await firestore.collection("Usuarios").doc(selected.id).delete();
      const { code } = await removeUserAuth({ email: selected.Email });
      // await Axios.post(
      //   "https://us-central1-biolantanidos.cloudfunctions.net/app/delete/user/auth",

      // );
      if (code === "OK") {
        dispatch(openSnack("Usuario eliminado", SnackState.SUCCESS));
        dispatch(getUsers());
      } else {
        dispatch(openSnack("Hubo un error al eliminar", SnackState.ERROR));
      }
      // }
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
      dispatch(editIsLoading(false));
    }
  };
};

const mapUsers = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let users: any[] = [];
    response.docs.forEach((doc) => {
      let data = mapUser(doc);
      users.push(data);
    });
    return users;
  } catch (error) {
    throw error;
  }
};

const mapUser = (doc: any) => {
  let data = doc.data();
  data.id = doc.id;
  return data;
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection("Usuarios")
        .orderBy("Nombre_lower")
        .where("Activo", "==", true);

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }
      const response = await query.get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      console.log(error);
    }
  };
};

export const setSelectedUser = (user: any): Action => ({
  type: types.USERS_SET_SELECTED,
  payload: user,
});

const editIsLoading = (isLoading: boolean): Action => ({
  type: types.USERS_EDIT_LOADING,
  payload: isLoading,
});

// const updateUserStore = (user: any): Action => ({
//   type: types.USERS_UPDATE_DOC,
//   payload: user,
// });

// const updateSelectedUserStore = (user: any): Action => ({
//   type: types.USERS_UPDATE_SELECTED_USER,
//   payload: user,
// });

const setUsers = (users: any[]): Action => ({
  type: types.USERS_GET_DOCS,
  payload: users,
});

const setLastDoc = (doc: any): Action => ({
  type: types.USERS_SET_LAST_DOC,
  payload: doc,
});

const addUsers = (users: any[]): Action => ({
  type: types.USERS_ADD_DOCS,
  payload: users,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.USERS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.USERS_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.USERS_SET_TOTAL_DOCS,
  payload: total,
});
