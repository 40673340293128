import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import {
  Download,
  Edit,
  MinusSquare,
  Plus,
  Search,
  Trash,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { BussinessModel } from "../models/businesses";
import {
  exportEmpresas,
  filterType,
  getEmpresas,
  getMoreEmpresas,
} from "../redux/actions/businessActions";
import { BusinessState } from "../redux/reducers/businessReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { handleDateFormat } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface filterProps {
  onFilter: (newFilter: filterType) => void;
}

const ContentCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [filtros, setFiltros] = useState<filterType>([]);

  const {
    getList: { isLoading, businesses, totalDocs },
  } = useSelector<RootState, BusinessState>((state) => state.businessReducer);

  const handleAdd = () => history.push(`/businesses/crear`);

  const handleRemove = () => {};

  const handleEdit = (selected: BussinessModel) => {
    history.push(`/businesses/editar/${selected.id}`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (e, newLimit) => {
        if (newLimit > businesses.length) {
          dispatch(getEmpresas(filtros, "FechaCreacion", newLimit));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (businesses.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreEmpresas(filtros, "FechaCreacion", limit));
        }
      },
    });

  const handleFilter = (newFilter: filterType) => setFiltros(newFilter);

  useEffect(() => {
    if (setPage && page) setPage(0);
    dispatch(getEmpresas(filtros, "FechaCreacion", limit));
  }, [filtros]);

  const handleExport = () => {
    dispatch(exportEmpresas(filtros));
  };

  return (
    <React.Fragment>
      <CardHeader
        action={
          <React.Fragment>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
            <Button
              startIcon={<Plus />}
              style={{ backgroundColor: "#42529b", color: "#fff" }}
              variant="contained"
              onClick={handleAdd}
            >
              Nueva Empresa
            </Button>
          </React.Fragment>
        }
      />
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Razón Social</TableCell>
                  <TableCell>Fecha Creación</TableCell>
                  <TableCell align="left">Progreso</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  businesses
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>{data?.Nombre}</TableCell>
                          <TableCell>
                            {handleDateFormat(data?.FechaCreacion.toDate())}
                          </TableCell>
                          <TableCell>{data?.Progreso || ""}</TableCell>
                          <TableCell>
                            {data.Activo ? (
                              <Chip
                                label="Activa"
                                style={{
                                  backgroundColor: "#81c784",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Inactiva"
                                style={{
                                  backgroundColor: "#ffb74d",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="flex-start">
                              <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleEdit(data)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Eliminar">
                                <IconButton size="small" onClick={() => {}}>
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);

  const [selectedState, setSelectedState] = useState(null);

  const handleSearch = () => {
    const filtros: filterType = [];
    if (selectedState === "Activa") {
      filtros.push({ field: "Activo", operator: "==", value: true });
    } else if (selectedState === "Inactiva") {
      filtros.push({ field: "Activo", operator: "==", value: false });
    }
    onFilter(filtros);
  };
  const handleClean = () => {
    setSelectedState(null);
    onFilter([]);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
        {/* <Grid item>
          
        </Grid> */}
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          {/* <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              variant="outlined"
              fullWidth
              // value={valueNombre}
              // onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {

                  ev.preventDefault();
                }
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="Estado"
              value={selectedState}
              options={["Activa", "Inactiva"]}
              size="small"
              fullWidth
              // getOptionLabel={(option) => option.Nombre}
              onChange={(event: any, newValue: any | null) => {
                setSelectedState(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Estado" variant="outlined" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleSearch}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Businesses = () => (
  <React.Fragment>
    <Helmet title="Empresas" />
    <Typography variant="h3" gutterBottom display="inline">
      Empresas
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Listado</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
