import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  ExpandLess,
  ExpandMore,
  FileCopy,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { MinusSquare, Plus, Search, Trash } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Chip } from "../components/Chip";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import { filterType } from "../redux/actions/businessActions";
import {
  deleteCovidForm,
  getCovidForms,
  getCovidFormsFiltered,
  getMoreCovidForms,
  setSelectedCovidForms,
} from "../redux/actions/covidFormsActions";
import { ICovidFormsState } from "../redux/reducers/covidFormsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { cleanString, timesStampToDateFormatted } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface filterProps {
  onFilter: (value: filterType) => void;
}

export const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [subjectData, setSubjectData] = useState<any>(null);

  const handleDeleteCovidForms = () => {
    dispatch(deleteCovidForm(subjectData));
    setOpenDelete(false);
  };

  const handleGoToEditCovidForm = (data: any) => {
    dispatch(setSelectedCovidForms(data));
    history.push(`/formulariosCovid/editar`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT || 5,
      onLimitChange: (e, newLimit) => {
        if (newLimit > covidForms.length) {
          dispatch(getCovidForms(newLimit, filtros));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (covidForms.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreCovidForms(limit, filtros));
        }
      },
    });

  const handleNavigateToAddPage = () => {
    history.push("/formulariosCovid/agregar");
  };

  useEffect(() => {
    dispatch(getCovidForms(limit, filtros));
  }, [filtros]);

  const { covidForms, state, error, totalDocs } = useSelector<
    RootState,
    ICovidFormsState
  >((state) => state.covidFormsReducer);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Plus />}
              style={{ backgroundColor: "#42529b", color: "#fff" }}
              variant="contained"
              onClick={() => handleNavigateToAddPage()}
            >
              Nuevo Formulario
            </Button>
          </>
        }
      />
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Formulario</TableCell>
                  <TableCell align="left">Fecha Creación</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  covidForms
                    .slice(page * limit, page * limit + limit)
                    .map((data, index) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell align="left">{data.Nombre}</TableCell>
                          <TableCell align="left">
                            {timesStampToDateFormatted(
                              data.FechaCreacion.toDate()
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {data?.Activo ? (
                              <Chip
                                size="small"
                                label="Activado"
                                activated="true"
                              />
                            ) : (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Desactivado"
                                desactivated="true"
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="clonar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleGoToEditCovidForm(data)}
                                >
                                  <FileCopy />
                                </IconButton>
                              </Tooltip>
                              {/* <Tooltip title="Ver">
                              <IconButton color="primary" size="small">
                                <Eye />
                              </IconButton>
                            </Tooltip> */}
                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSubjectData(data);
                                    setOpenDelete(true);
                                  }}
                                >
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar el fomulario?"
          onConfirm={handleDeleteCovidForms}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};
const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);
  const [nombre, setNombre] = useState("");
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "Nombre_lower",
        operator: "==",
        value: cleanString(nombre),
      });

    if (dateStart)
      filter.push({
        field: "FechaCreacion",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaCreacion",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre Formulario"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};
export const CovidForms = () => {
  return (
    <React.Fragment>
      <Helmet title="Formularios COVID" />

      <Typography variant="h3" gutterBottom display="inline">
        Formularios COVID
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Formularios COVID</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
