import { AppThunk } from "../../models/app-thunk";
import { Action } from "../../models/action";
import * as types from "../../constants";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase";
import { cleanString, lowerFieldValidation } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";

const collectionNames = {
  bsns: "FormulariosCovidBSNS",
  aclara: "FormulariosCovid",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

const dir = (order: string) => {
  if (order === "FechaCreacion") return "desc";
  return undefined;
};

export function getCovidForms(
  limit = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COVID_FORM_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      let query = firestore
        .collection(collName(user))
        .where("Eliminado", "==", false)
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const responseAll = await sortQueries(query, filter).get();
      const covidForms = response.docs.map((x) => ({ ...x.data(), id: x.id }));

      dispatch({
        type: types.COVID_FORM_SUCCESS,
        payload: {
          totalDocs: responseAll.size,
          lastDoc: response.docs[response.docs.length - 1],
          covidForms: covidForms,
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.COVID_FORM_FAILURE,
        payload: error,
      });
    }
  };
}

export function getMoreCovidForms(
  limit = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = [],
  order: string = "FechaCreacion"
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COVID_FORM_IS_SUBMITTING,
    });
    const { lastDoc, totalDocs } = getState().covidFormsReducer;
    const { user } = getState().authReducer;
    try {
      const total = totalDocs || 0;

      if (
        filter.length === 1 &&
        cleanString(filter[0].field).includes("lower")
      ) {
        order = filter[0].field;
      }
      const indexUtil = lowerFieldValidation(filter);
      if (indexUtil !== "empty") {
        order = filter[indexUtil].field;
      }
      let query = firestore
        .collection(collName(user))
        .where("Eliminado", "==", false)
        .orderBy(order, dir(order));

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const reponseCovidForms = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.COVID_FORM_SUCCESS,
        payload: {
          totalDocs: total,
          lastDoc: response.docs[response.docs.length - 1],
          covidForms: reponseCovidForms,
        },
      });
    } catch (error: any) {
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.COVID_FORM_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export function addCovid(covidForm: any, nombre: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COVID_FORM_ADD_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      const form: any = {
        Nombre: nombre,
        Nombre_lower: cleanString(nombre),
        Preguntas: covidForm,
        Activo: true,
        Eliminado: false,
      };

      let query = firestore
        .collection(collName(user))
        .orderBy("FechaCreacion", "desc");

      if (user?.business) {
        form["Empresa"] = user.business;
        query = query.where("Empresa", "==", user.business);
      }
      const snapForms = await query.limit(1).get();

      if (snapForms.size > 0) {
        const lastId = snapForms.docs[0].id;

        await firestore
          .collection(collName(user))
          .doc(lastId)
          .update({ Activo: false });
      }

      await firestore.collection(collName(user)).add({
        ...form,
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
      });

      dispatch({
        type: types.COVID_FORM_ADD_SUCCESS,
      });

      dispatch(
        openSnack("Se agreago formulario exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      console.log(error);

      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.COVID_FORM_ADD_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export const deleteCovidForm = (covidForm: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COVID_FORM_REMOVE_SUBMITTING,
    });
    const { user } = getState().authReducer;
    try {
      await firestore
        .collection(collName(user))
        .doc(covidForm.id)
        .update({ Eliminado: true });

      dispatch(
        openSnack("Formulario eliminado correctamente", SnackState.SUCCESS)
      );
      dispatch({
        type: types.COVID_FORM_REMOVE_SUCCES,
        payload: covidForm,
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.COVID_FORM_REMOVE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
};
export const getCovidFormsFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;

    try {
      let query: any = firestore
        .collection(collName(user))
        .orderBy(order, "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];

        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.get();
      const covidFormsList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.COVID_FORM_SUCCESS,
        payload: {
          covidForms: covidFormsList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};
export const getOneCovidForm = (covidForm: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COVID_FORM_ANSWERS_IS_SUBMITTING,
    });
    const { user } = getState().authReducer;

    try {
      const response = await firestore
        .collection(collName(user))
        .doc(covidForm.Id)
        .get();

      const covidFormSelected: any = { ...response.data(), id: response.id };

      dispatch({
        type: types.COVID_FORM_ANSWERS_SUCCESS,
        payload: covidFormSelected,
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.COVID_FORM_ANSWERS_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
};

export const setSelectedCovidForms = (covidForm: any): Action => ({
  type: types.COVID_FORM_CLONE,
  payload: covidForm,
});

export const initialCovidForms = (): Action => ({
  type: types.COVID_FORM_INITIAL,
});
const isLoading = (isloading: boolean): Action => ({
  type: types.COVID_FORM_IS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.COVID_FORM_IS_FAILURE,
  payload: error,
});
