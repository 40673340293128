/* eslint-disable import/first */

// import async from "../components/Async";

import {
  Activity,
  AlertOctagon,
  Book,
  Clipboard,
  FilePlus,
  FileText,
  Flag,
  Home,
  LifeBuoy,
  Navigation,
  User,
  Users as UsersIcon,
} from "react-feather";
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Guards
import AuthGuard from "../components/AuthGuard";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
// import { BlankTest } from "../pages/BlankTest";
import { Cargos } from "../pages/Cargos";
import { Dashboard } from "../pages/Dashboard";
import { Users } from "../pages/Users";
import { EditUser } from "../pages/Users/EditUser";
import { NewUser } from "../pages/Users/NewUser";
import { CovidForms } from "../pages/CovidForms";
import { AddCovidForm } from "../pages/AddCovidForm";
import { Answers } from "../pages/Answers";
import { CheckList } from "../pages/CheckList";
import { CheckListView } from "../pages/CheckListView";
import { Answer } from "../pages/Answer";
import { Incidentes } from "../pages/Incidentes";
import { ViewIcidentes } from "../pages/ViewIcidentes";
import { Actividades } from "../pages/Actividades";
import { ActividadesView } from "../pages/ActividadesView";
import { Art } from "../pages/Art";
import { ArtView } from "../pages/ArtView";
import { Gps } from "../pages/Gps";
import { GpsView } from "../pages/GpsView";
import { Areas } from "../pages/Areas";
import { HomeWork } from "@material-ui/icons";
import { Businesses } from "../pages/Businesses";
import { NewBusiness } from "../pages/Business/NewBusiness";
import { EditBusiness } from "../pages/Business/EditBusiness";
import QRView from "../pages/VistaQR/QRView";

const incidentRoutes = {
  id: "Gestión de Incidentes",
  path: "/Incidentes",
  icon: <Activity />,
  guard: AuthGuard,
  component: Incidentes,
  children: null,
};
const incidentSubRoutes = {
  id: "Listas de incidentes SubRutas",
  path: "/Incidentes",
  icon: <Clipboard />,
  guard: AuthGuard,
  children: [
    {
      path: "/Incidentes/:incidentesId/ver",
      name: "Ver",
      guard: AuthGuard,
      component: ViewIcidentes,
    },
  ],
};

const completeIncidentRoutes = {
  id: "Completar Incidentes",
  path: "/CompletarIncidentes",
  icon: <AlertOctagon />,
  guard: AuthGuard,
  component: Dashboard,
  children: null,
};

const checkListsRoutes = {
  id: "Listas de Chequeo",
  path: "/ListaDeChequeo",
  icon: <Clipboard />,
  guard: AuthGuard,
  component: CheckList,
  children: null,
};

const checkListsSubRoutesRoutes = {
  id: "Listas de Chequeo SubRutas",
  path: "/ListaDeChequeo",
  icon: <Clipboard />,
  guard: AuthGuard,
  children: [
    {
      path: "/listaDeChequeo/:checklistId/ver",
      name: "Ver",
      guard: AuthGuard,
      component: CheckListView,
    },
  ],
};

const checkListsEvaluationRoutes = {
  id: "Evaluar listas de Checkeo",
  path: "/evaluarListasCheckeo",
  icon: <FilePlus />,
  guard: AuthGuard,
  component: Dashboard,
  children: null,
};

const risksAnalisisRoutes = {
  id: "Análisis de Riesgos",
  path: "/analisisRiesgos",
  icon: <Flag />,
  guard: AuthGuard,
  component: Art,
  children: null,
};

const risksAnalisisSubRoutes = {
  id: "Análisis de Riesgos Sub route",
  path: "/analisisRiesgos",
  icon: <Flag />,
  guard: AuthGuard,
  component: Art,
  children: [
    {
      path: "/analisisRiesgos/:artId/ver",
      name: "ver",
      guard: AuthGuard,
      component: ArtView,
    },
  ],
};

const activitiesManagmentRoutes = {
  id: "Gestión de Actividades",
  path: "/gestionActividades",
  icon: <FileText />,
  guard: AuthGuard,
  component: Actividades,
  children: null,
};
const activitiesManagmentSubRoutes = {
  id: "Gestión de Actividades sub routes",
  path: "/gestionActividades",
  icon: <FileText />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/gestionActividades/:actividadId/ver",
      name: "ver",
      guard: AuthGuard,
      component: ActividadesView,
    },
  ],
};

const gpsRoutes = {
  id: "GPS",
  path: "/gpsRoutes",
  icon: <Navigation />,
  guard: AuthGuard,
  component: Gps,
  children: null,
};

const gpsSubRoutes = {
  id: "GPS Sub",
  path: "/gpsRoutes",
  icon: <Navigation />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/gpsRoutes/:gpsId/ver",
      name: "ver",
      guard: AuthGuard,
      component: GpsView,
    },
  ],
};
const covidFormRoutes = {
  id: "Formulario COVID",
  path: "/formulariosCovid",
  icon: <Book />,
  children: [
    {
      path: "/formulariosCovid/listado",
      name: "Listado",
      guard: AuthGuard,
      component: CovidForms,
    },
    {
      path: "/formulariosCovid/respuestas",
      name: "Respuestas",
      guard: AuthGuard,
      component: Answers,
    },
  ],
};

const covidFormSubRoutesRoutes = {
  id: "Formulario COVID SubRutas",
  path: "/formulariosCovid",
  icon: <Book />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/formulariosCovid/agregar",
      name: "agregar",
      guard: AuthGuard,
      component: AddCovidForm,
    },
    {
      path: "/formulariosCovid/editar",
      name: "editar",
      guard: AuthGuard,
      component: AddCovidForm,
    },
    {
      path: "/formulariosCovid/respuestas/:respuestaId/respuesta",
      name: "Respuestas",
      guard: AuthGuard,
      component: Answer,
    },
  ],
};

const usersdRoutes = {
  id: "Usuarios",
  path: "/usuarios",
  icon: <User />,
  guard: AuthGuard,
  children: [
    {
      path: "/usuarios/listado",
      name: "Listado",
      guard: AuthGuard,
      component: Users,
    },
    {
      path: "/usuarios/cargos",
      name: "Cargos",
      guard: AuthGuard,
      component: Cargos,
    },
  ],
};

const usersSubRoutes = {
  path: "/usuarios",
  component: null,
  children: [
    {
      path: "/editar/:userId",
      name: "Editar Tour",
      guard: AuthGuard,
      component: EditUser,
    },
    {
      path: "/crear",
      name: "Crear Tour",
      guard: AuthGuard,
      component: NewUser,
    },
  ],
};

const areasRoutes = {
  id: "Areas",
  path: "/areas",
  icon: <LifeBuoy />,
  guard: AuthGuard,
  component: Areas,
  children: null,
};

const businessesRoutes = {
  id: "Empresas",
  path: "/businesses",
  icon: <HomeWork />,
  guard: AuthGuard,
  component: Businesses,
  children: null,
};

const businessSubRoutes = {
  path: "/businesses",
  component: null,
  children: [
    {
      path: "/businesses/editar/:businessId",
      name: "Editar Tour",
      guard: AuthGuard,
      component: EditBusiness,
    },
    {
      path: "/businesses/crear",
      name: "Crear Tour",
      guard: AuthGuard,
      component: NewBusiness,
    },
  ],
};

const areasSubRoutes = {
  id: "GPS Sub",
  path: "/areas",
  icon: <LifeBuoy />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/areas/:areaId/ver",
      name: "ver",
      guard: AuthGuard,
      component: GpsView,
    },
  ],
};

// const businessesRoutes = {
//   id: "Empresas",
//   path: "/empresas",
//   icon: <Book />,
//   guard: AuthGuard,
//   component: Businesses,
//   children: null,
// };

// const salesRoutes = {
//   id: "Sales",
//   path: "/sales",
//   icon: <Book />,
//   guard: AuthGuard,
//   component: Sales,
//   children: null,
// };

// const clientsRoutes = {
//   id: "Clientes",
//   path: "/clientes",
//   icon: <UsersIcon />,
//   component: Clients,
//   guard: AuthGuard,
//   children: null,
// };

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <UsersIcon />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const qrRoutes = {
  id: "QR",
  path: "/information",
  icon: <UsersIcon />,
  children: [
    {
      path: "/information/:selectedId",
      name: "Sign In",
      component: QRView,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes, qrRoutes];

// Routes that are protected
export const protectedRoutes = [
  usersdRoutes,
  usersSubRoutes,
  incidentRoutes,
  completeIncidentRoutes,
  checkListsRoutes,
  checkListsEvaluationRoutes,
  risksAnalisisRoutes,
  activitiesManagmentRoutes,
  gpsRoutes,
  covidFormRoutes,
  covidFormSubRoutesRoutes,
  checkListsSubRoutesRoutes,
  incidentSubRoutes,
  activitiesManagmentSubRoutes,
  risksAnalisisSubRoutes,
  gpsSubRoutes,
  areasRoutes,
  businessesRoutes,
  businessSubRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  incidentRoutes,
  checkListsRoutes,
  risksAnalisisRoutes,
  activitiesManagmentRoutes,
  gpsRoutes,
  covidFormRoutes,
  usersdRoutes,
  areasRoutes,
  businessesRoutes,
];
