import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";
import { Eye, MinusSquare, Search, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "../components/Chip";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  deleteCheckLists,
  getCheckLists,
  getMoreCheckLists,
  setSelectedCheckLists,
} from "../redux/actions/checkListActions";
import { ICheckListsState } from "../redux/reducers/checkListReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import { timesStampFormattedsimple } from "../utils/utils";

import { Autocomplete } from "@material-ui/lab";
import { filterType } from "../redux/actions/businessActions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
//TODO: Agregar paginacion de la tabla para total docs

export const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const color = red[300];
  const revision = orange[300];
  const cerrada = green[300];

  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [filtros, setFiltros] = useState<filterType>([]);

  const handleDeleteCheckList = () => {
    if (idDelete) dispatch(deleteCheckLists(idDelete));
    setOpenDelete(false);
  };

  const handleGoToViewCheckLists = (data: any) => {
    dispatch(setSelectedCheckLists(data));
    history.push(`/listaDeChequeo/${data.id}/ver`);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT || 50,
      onLimitChange: (e, newLimit) => {
        if (newLimit > checkLists.length) {
          dispatch(getCheckLists(newLimit, filtros));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (checkLists.length < totalDocs && newPage > oldPage) {
          dispatch(getMoreCheckLists(limit, filtros));
        }
      },
    });

  useEffect(() => {
    dispatch(getCheckLists(limit, filtros));
  }, [filtros]);

  const {
    checkLists,
    state,
    totalDocs,
    delete: deleteStatus,
  } = useSelector<RootState, ICheckListsState>(
    (state) => state.checkListReducer
  );

  const handleDeleteThis = (selected: any) => {
    if (selected && selected?.id) {
      setIdDelete(selected.id);
      setOpenDelete(true);
    }
  };

  useEffect(() => {
    if (deleteStatus?.state === FormState.Success) {
      if (setPage && page) setPage(0);
      dispatch(getCheckLists(limit, filtros));
    }
  }, [deleteStatus?.state]);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  return (
    <>
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nombre del conductor</TableCell>
                  <TableCell align="left">Vehiculo</TableCell>
                  <TableCell align="left">Patente</TableCell>
                  <TableCell align="left">Fecha</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  checkLists
                    .slice(page * limit, page * limit + limit)
                    .map((data, index) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell align="left">
                            {data.NombreConductor}
                          </TableCell>
                          <TableCell align="left">{data?.Vehiculo}</TableCell>
                          <TableCell align="left">{data?.Patente}</TableCell>
                          <TableCell align="left">
                            {timesStampFormattedsimple(
                              data?.FechaCreacion.toDate()
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {data.Estado === "Por Evaluar" && (
                              <Chip
                                label="Por Evaluar"
                                style={{ backgroundColor: color, width: 140 }}
                              />
                            )}
                            {data.Estado === "Pendiente de Cierre" && (
                              <Chip
                                label="Pendiente de Cierre"
                                style={{
                                  backgroundColor: revision,
                                  width: 140,
                                }}
                              />
                            )}
                            {data.Estado === "Cerrado" && (
                              <Chip
                                label="Cerrado"
                                style={{ backgroundColor: cerrada, width: 140 }}
                              />
                            )}
                            {data.Estado !== "Cerrado" &&
                              data.Estado !== "Pendiente de Cierre" &&
                              data.Estado !== "Por Evaluar" && (
                                <Chip
                                  label="-"
                                  style={{
                                    backgroundColor: "#808080",
                                    width: 140,
                                  }}
                                />
                              )}
                          </TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="Ver">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleGoToViewCheckLists(data)}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteThis(data)}
                                >
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar la Checklist?"
          onConfirm={handleDeleteCheckList}
          onConfirmText={"Eliminar"}
        />
      </Card>
    </>
  );
};
interface filterProps {
  onFilter: (value: filterType) => void;
}
const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  const [openFilter, setOpenFilters] = useState(false);
  const [nombreConductor, setNombreConductor] = useState("");
  const [patente, setPatente] = useState("");
  const [estado, setEstado] = useState<any>(null);
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombreConductor(e.target.value);
  };
  const handleChangePatente = (e: any) => {
    setPatente(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombreConductor)
      filter.push({
        field: "NombreConductor",
        operator: "==",
        value: nombreConductor,
      });

    if (patente)
      filter.push({
        field: "Patente",
        operator: "==",
        value: patente.toUpperCase(),
      });

    if (estado)
      filter.push({
        field: "Estado",
        operator: "==",
        value: estado,
      });

    if (dateStart)
      filter.push({
        field: "FechaCreacion",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaCreacion",
        operator: "<=",
        value: new Date(dateEnd),
      });
    onFilter(filter);
  };
  const handleClean = () => {
    setNombreConductor("");
    setPatente("");
    setEstado(null);
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };
  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre Conductor"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombreConductor}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="Patente"
              size="small"
              label="Patente"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={patente}
              onChange={handleChangePatente}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();

                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="orderby"
              size="small"
              value={estado}
              options={["Por Evaluar", "Pendiente de Cierre", "Cerrado"]}
              fullWidth
              onChange={(event: any, newValue: string | null) => {
                setEstado(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Estado" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};
export const CheckList = () => {
  return (
    <React.Fragment>
      <Helmet title="Checklist" />

      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lista de Chequeo.
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Lista de Chequeo</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
