import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  Download,
  Edit,
  MinusSquare,
  Plus,
  Search,
  Trash,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { NewEditArea } from "../components/NewEditArea";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { AreaModel } from "../models/area";
import { FormState } from "../models/form_state";
import {
  createArea,
  deleteArea,
  exportAreas,
  getAreas,
  getMoreAreas,
  updateArea,
} from "../redux/actions/areasActions";
import { filterType } from "../redux/actions/businessActions";
import { AreasState } from "../redux/reducers/areasReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const orderByUsers = ["Nombre", "Fecha"];

interface filterProps {
  onFilter: (value: filterType) => void;
  onOrder: React.Dispatch<any>;
}

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editTarget, setEditTarget] = useState<AreaModel | undefined>(
    undefined
  );
  const [deleteTarget, setDeleteTarget] = useState("");
  const [order, setOrder] = useState<any>("Fecha");

  const orderBy = () => {
    const hasDate = filtros.find((ff) => ff.field === "FechaCreacion");
    let orderString = "FechaCreacion";
    if (order === "Nombre") {
      orderString = "NombreLower";
    }
    const sort = hasDate ? "FechaCreacion" : orderString;

    return sort;
  };

  const closeDialog = () => {
    setEditTarget(undefined);
    setOpenDialog(false);
  };

  const handleDeleteArea = () => {
    setOpenDelete(false);
    if (deleteTarget) dispatch(deleteArea(deleteTarget));
    setDeleteTarget("");
  };

  const editArea = (selected: AreaModel) => {
    setEditTarget(selected);
    setOpenDialog(true);
  };
  const removeArea = (selected: AreaModel) => {
    setDeleteTarget(selected.id || "");
    setOpenDelete(true);
  };

  const handleAcceptCreateEdit = (usr: AreaModel | undefined, id: string) => {
    closeDialog();
    if (!usr) return;
    if (id) {
      dispatch(updateArea(usr, id));
    } else {
      dispatch(createArea(usr));
    }
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (e, newLimit) => {
        if (newLimit > getStatus.data.length) {
          dispatch(getAreas(orderBy(), filtros, newLimit));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (
          getStatus.data.length < (getStatus?.totalDocs || 0) &&
          newPage > oldPage
        ) {
          dispatch(getMoreAreas("", {}, limit));
        }
      },
    });

  const { get: getStatus, updateCreate } = useSelector<RootState, AreasState>(
    (state) => state.areasReducer
  );

  useEffect(() => {
    if (!order) return;
    if (setPage && page) setPage(0);
    dispatch(getAreas(orderBy(), filtros, limit));
  }, [filtros, order]);

  useEffect(() => {
    if (updateCreate.state === FormState.Success) {
      dispatch(getAreas(orderBy(), filtros, limit));
    }
  }, [updateCreate.state]);

  const handleExport = () => {
    dispatch(exportAreas(orderBy(), filtros));
  };

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
            <Button
              startIcon={<Plus />}
              style={{ backgroundColor: "#42529b", color: "#fff" }}
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Nueva Área
            </Button>
          </>
        }
      />
      <Card mb={6}>
        {/* FILTRO INICIO */}
        <FilterSection onFilter={handleFilter} onOrder={setOrder} />
        {/* FILTRO FINAL */}
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha Creación</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getStatus.state === FormState.Success ? (
                  getStatus.data
                    .slice(page * limit, page * limit + limit)
                    .map((data) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>{data?.Nombre}</TableCell>
                          <TableCell>
                            {data?.FechaCreacion
                              ? timesStampFormattedsimple(
                                  data?.FechaCreacion.toDate()
                                )
                              : ""}
                          </TableCell>
                          <TableCell>{data?.Descripcion}</TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="flex-start">
                              <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => editArea(data)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => removeArea(data)}
                                >
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={getStatus?.totalDocs || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar esta Area?"
          onConfirm={handleDeleteArea}
          onConfirmText={"Eliminar"}
        />
      </Card>
      <NewEditArea
        data={editTarget}
        open={openDialog}
        onClose={closeDialog}
        onAccept={handleAcceptCreateEdit}
      />
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter, onOrder }) => {
  const [openFilter, setOpenFilters] = useState(false);
  //
  const [nombre, setNombre] = useState("");
  const [dateStart, setDateStart] = useState<any>("");
  const [dateEnd, setDateEnd] = useState<any>("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "NombreLower",
        operator: "==",
        value: cleanString(nombre),
      });

    if (dateStart)
      filter.push({
        field: "FechaCreacion",
        operator: ">=",
        value: new Date(dateStart),
      });

    if (dateEnd)
      filter.push({
        field: "FechaCreacion",
        operator: "<=",
        value: new Date(dateEnd),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    setDateStart("");
    setDateEnd("");
    onFilter([]);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
        <Grid item>
          <Autocomplete
            id="orderby"
            size="small"
            options={orderByUsers}
            // defaultValue="Fecha"
            style={{ width: 170 }}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                onOrder(newValue);
              } else {
                onOrder("Fecha");
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Ordenar por" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              variant="outlined"
              fullWidth
              autoComplete="off"
              value={nombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleFilter();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={dateStart}
              onChange={(event) => {
                setDateStart(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={handleFilter}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={handleClean}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};
// const FilterSection: React.FC<filterProps> = ({ changeOrder }) => {
//   const dispatch = useDispatch();

//   const [openFilter, setOpenFilters] = useState(false);
//   const [filtersList, setValueFiltersList] = React.useState<any>({});
//   const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
//   const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
//   const [valueNombre, setValueNombre] = useState("");
//   const [valueCargo, setValueCargo] = useState<any>(null);
//   const [order, setOrder] = useState("NombreLower");

//   // const { Cargos } = useSelector<RootState, PositionChargesState>(
//   //   (state) => state.positionChargesReducer
//   // );

//   const handleChangeNombre = (e: any) => {
//     setValueNombre(e.target.value);
//   };

//   // useEffect(() => {
//   //   if (Cargos.length === 0) {
//   //     dispatch(getCargos());
//   //   }
//   // }, [Cargos, dispatch]);

//   return (
//     <CardContent>
//       <Grid container justify="space-between">
//         <Grid item>
//           <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
//             Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
//           </Button>
//         </Grid>
//         <Grid item>
//           <Autocomplete
//             id="orderby"
//             size="small"
//             options={orderByUsers}
//             style={{ width: 170 }}
//             onChange={(event: any, newValue: string | null) => {
//               if (newValue === "Fecha") {
//                 setOrder("FechaCreacion");
//                 changeOrder("FechaCreacion");
//               } else if (newValue === "Cargo") {
//                 changeOrder("Cargo");
//               } else {
//                 setOrder("NombreLower");
//                 changeOrder("NombreLower");
//               }
//             }}
//             renderInput={(params) => (
//               <TextField {...params} label="Ordenar por" variant="outlined" />
//             )}
//           />
//         </Grid>
//       </Grid>
//       <Collapse in={openFilter} timeout="auto" unmountOnExit>
//         <Grid container spacing={1} style={{ marginTop: 20 }}>
//           <Grid item xs={12} sm={2}>
//             <TextField
//               id="outlined-basic"
//               size="small"
//               label="Nombre"
//               variant="outlined"
//               fullWidth
//               value={valueNombre}
//               onChange={handleChangeNombre}
//               onKeyPress={(ev) => {
//                 if (ev.key === "Enter") {
//                   filtersList["NombreLower"] = cleanString(valueNombre);

//                   if (!valueNombre) {
//                     delete filtersList["NombreLower"];
//                   }

//                   if (Object.keys(filtersList).length > 0) {
//                     dispatch(getAreas(order, filtersList));
//                   } else {
//                     dispatch(getAreas());
//                   }

//                   ev.preventDefault();
//                 }
//               }}
//             />
//           </Grid>
//           {/* <Grid item xs={12} sm={2}>
//             <Autocomplete
//               id="Cargo"
//               value={valueCargo}
//               options={Cargos}
//               size="small"
//               fullWidth
//               getOptionLabel={(option) => option.Nombre}
//               filterOptions={(option) =>
//                 option.filter((opt: any) => opt.Activado)
//               }
//               onChange={(event: any, newValue: any | null) => {
//                 setValueCargo(newValue);
//                 if (newValue) {
//                   if (!valueNombre) {
//                     delete filtersList["NombreLower"];
//                   }
//                   filtersList["Cargo"] = newValue.Nombre;
//                   dispatch(getUsersFiltered(filtersList));
//                 } else {
//                   delete filtersList["Cargo"];
//                   if (Object.keys(filtersList).length > 0) {
//                     dispatch(getUsersFiltered(filtersList));
//                   } else {
//                     dispatch(getUsers());
//                   }
//                 }
//               }}
//               renderInput={(params) => (
//                 <TextField {...params} label="Cargo" variant="outlined" />
//               )}
//             />
//           </Grid> */}
//           <Grid item xs={12} sm={2}>
//             <TextField
//               id="creacion-start"
//               label="Fecha Creación: Inicio"
//               size="small"
//               type="datetime-local"
//               value={valueCreacionStart}
//               onChange={(event) => {
//                 setValueCreacionStart(event.target.value);
//                 filtersList["startAt"] = event.target.value;
//               }}
//               fullWidth
//               variant="outlined"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={2}>
//             <TextField
//               id="creacion-start"
//               label="Fecha Creación: Final"
//               size="small"
//               type="datetime-local"
//               value={valueCreacionEnd}
//               onChange={(event) => {
//                 setValueCreacionEnd(event.target.value);
//                 filtersList["endAt"] = event.target.value;
//               }}
//               fullWidth
//               variant="outlined"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={1}>
//             <Box display="flex">
//               <Box mt={1} order={1}>
//                 <Tooltip title="Buscar">
//                   <IconButton
//                     size="small"
//                     aria-label="Filtrar"
//                     onClick={() => {
//                       if (valueCreacionStart && valueCreacionEnd) {
//                         dispatch(getAreas(order, filtersList));
//                       }
//                     }}
//                   >
//                     <Search />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//               <Box mt={1} order={2}>
//                 <Tooltip title="Limpiar">
//                   <IconButton
//                     size="small"
//                     aria-label="Borrar filtro"
//                     onClick={() => {
//                       setValueNombre("");
//                       setValueCargo("");
//                       setValueCreacionStart("");
//                       setValueCreacionEnd("");
//                       setValueFiltersList({});
//                       dispatch(getAreas());
//                     }}
//                   >
//                     <MinusSquare />
//                   </IconButton>
//                 </Tooltip>
//               </Box>
//             </Box>
//           </Grid>
//         </Grid>
//       </Collapse>
//     </CardContent>
//   );
// };

export const Areas = () => (
  <React.Fragment>
    <Helmet title="Usuarios" />
    <Typography variant="h3" gutterBottom display="inline">
      Áreas
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Listado</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
