import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  ButtonGroup,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { IGps } from "../redux/reducers/gpsReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { getOneGps } from "../redux/actions/alertGpsAction";
import { timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const { gpsId } = useParams<any>();
  const dispatch = useDispatch();
  let today = new Date()

  const { selectedGps } = useSelector<RootState, IGps>(
    (state) => state.gpsReducer
  );

  useEffect(() => {
    if (!selectedGps) {
      dispatch(getOneGps(gpsId));
    }
  }, [selectedGps, gpsId]);

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="center">
            <img
              style={{ borderRadius: 20 }}
              src={
                "https://maps.googleapis.com/maps/api/staticmap?" +
                "key=AIzaSyD9HmNtNbieIc0zsZLjErteatS3R65HYJg&size=500x300&style=feature:poi|visibility:off" +
                `&markers=anchor:bottomleft|icon:https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png%7Cshadow:true%7C${selectedGps?.Ubicacion?.latitude},${selectedGps?.Ubicacion?.longitude}`
              }
            />
          </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><b>Nombre</b>:{" "}{selectedGps?.Usuario?.Nombre}</Typography><br/>
            <Typography><b>Apellido</b>:{" "}{selectedGps?.Usuario?.Apellido}</Typography><br/>
            <Typography><b>Coordenadas</b>:{" "}lat{" "}{selectedGps?.Ubicacion?.latitude}{" "}long{" "}{selectedGps?.Ubicacion?.longitude}
            </Typography><br/>
            <Typography><b>Fecha de Alerta</b>:{" "}{timesStampFormattedsimple(selectedGps?.FechaCreacion?.toDate())}</Typography><br/><br/>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const GpsView = () => {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        GPS
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/gpsRoutes">
          Alert GPS List
        </Link>
        <Typography>Detalle GPS</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
