import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { firestore } from "../firebase/firebase";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { spacing, typography } from "@material-ui/system";
import {
  deleteCheckLists,
  getCheckLists,
  getOneChecklist,
  updateComent,
  updateComentCierre,
} from "../redux/actions/checkListActions";
import { ICheckListsState } from "../redux/reducers/checkListReducer";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../theme/useStyles";
import { RootState } from "../redux/reducers/rootReducer";
import { Download, Plus, Trash } from "react-feather";
import { IAuthState } from "../redux/reducers/authReducer";
import { exportToCsv, timesStampFormattedsimple } from "../utils/utils";
import { Alert } from "@material-ui/lab";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { FormState } from "../models/form_state";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { checklistId } = useParams<any>();

  const [openDelete, setOpenDelete] = useState(false);
  const [inputRevision, setInputRevision] = useState("");
  const [inputCierre, setInputCierre] = useState("");
  let condicionesGenerales: any[] = [];
  let condicionesVehiculo: any[] = [];
  let checklistConductor: any[] = [];

  const estadoRevision = "Pendiente de Cierre";
  const estadoCierre = "Cerrado";

  const handleInputRevision = (e: any) => {
    setInputRevision(e.target.value);
  };
  const handleInputCierre = (e: any) => {
    setInputCierre(e.target.value);
  };

  const { selectedChecklists, delete: deleteStatus } = useSelector<
    RootState,
    ICheckListsState
  >((state) => state.checkListReducer);

  const { user } = useSelector<RootState, IAuthState>(
    (state) => state.authReducer
  );

  useEffect(() => {
    if (!selectedChecklists) {
      dispatch(getOneChecklist(checklistId));
    }
  }, [selectedChecklists, checklistId]);

  useEffect(() => {
    condicionesGenerales.push({
      Pregunta1: selectedChecklists?.CondicionesGenerales[0],
    });
    condicionesGenerales.push({
      Pregunta2: selectedChecklists?.CondicionesGenerales[1],
    });
    condicionesGenerales.push({
      Pregunta3: selectedChecklists?.CondicionesGenerales[2],
    });
    condicionesGenerales.push({
      Pregunta4: selectedChecklists?.CondicionesGenerales[3],
    });
    condicionesGenerales.push({
      Pregunta5: selectedChecklists?.CondicionesGenerales[4],
    });
    condicionesGenerales.push({
      Pregunta6: selectedChecklists?.CondicionesGenerales[5],
    });
    condicionesGenerales.push({
      Pregunta7: selectedChecklists?.CondicionesGenerales[6],
    });
    condicionesGenerales.push({
      Pregunta8: selectedChecklists?.CondicionesGenerales[7],
    });
    condicionesGenerales.push({
      Pregunta9: selectedChecklists?.CondicionesGenerales[8],
    });
    condicionesGenerales.push({
      Pregunta10: selectedChecklists?.CondicionesGenerales[9],
    });
    condicionesGenerales.push({
      Pregunta11: selectedChecklists?.CondicionesGenerales[10],
    });
    condicionesGenerales.push({
      Pregunta12: selectedChecklists?.CondicionesGenerales[11],
    });
    condicionesGenerales.push({
      Pregunta13: selectedChecklists?.CondicionesGenerales[12],
    });
    condicionesGenerales.push({
      Pregunta14: selectedChecklists?.CondicionesGenerales[13],
    });
    condicionesGenerales.push({
      Pregunta15: selectedChecklists?.CondicionesGenerales[14],
    });
    condicionesGenerales.push({
      Pregunta16: selectedChecklists?.CondicionesGenerales[15],
    });
    condicionesGenerales.push({
      Pregunta17: selectedChecklists?.CondicionesGenerales[16],
    });
    condicionesGenerales.push({
      Pregunta18: selectedChecklists?.CondicionesGenerales[17],
    });
    condicionesVehiculo.push({
      pregunta1: selectedChecklists?.CondicionesVehiculo[0],
    });
    condicionesVehiculo.push({
      pregunta2: selectedChecklists?.CondicionesVehiculo[1],
    });
    condicionesVehiculo.push({
      pregunta3: selectedChecklists?.CondicionesVehiculo[2],
    });
    condicionesVehiculo.push({
      pregunta4: selectedChecklists?.CondicionesVehiculo[3],
    });
    condicionesVehiculo.push({
      pregunta5: selectedChecklists?.CondicionesVehiculo[4],
    });
    condicionesVehiculo.push({
      pregunta6: selectedChecklists?.CondicionesVehiculo[5],
    });
    condicionesVehiculo.push({
      pregunta7: selectedChecklists?.CondicionesVehiculo[6],
    });
    condicionesVehiculo.push({
      pregunta8: selectedChecklists?.CondicionesVehiculo[7],
    });
    condicionesVehiculo.push({
      pregunta9: selectedChecklists?.CondicionesVehiculo[8],
    });
    condicionesVehiculo.push({
      pregunta10: selectedChecklists?.CondicionesVehiculo[9],
    });
    condicionesVehiculo.push({
      pregunta11: selectedChecklists?.CondicionesVehiculo[10],
    });
    condicionesVehiculo.push({
      pregunta12: selectedChecklists?.CondicionesVehiculo[11],
    });
    condicionesVehiculo.push({
      pregunta13: selectedChecklists?.CondicionesVehiculo[12],
    });
    condicionesVehiculo.push({
      pregunta14: selectedChecklists?.CondicionesVehiculo[13],
    });
    condicionesVehiculo.push({
      pregunta15: selectedChecklists?.CondicionesVehiculo[14],
    });
    condicionesVehiculo.push({
      pregunta16: selectedChecklists?.CondicionesVehiculo[15],
    });
    condicionesVehiculo.push({
      pregunta17: selectedChecklists?.CondicionesVehiculo[16],
    });
    condicionesVehiculo.push({
      pregunta18: selectedChecklists?.CondicionesVehiculo[17],
    });
    checklistConductor.push({
      n1: selectedChecklists?.CheckListConductor.Cuarta,
    });
    checklistConductor.push({
      n2: selectedChecklists?.CheckListConductor.Primera,
    });
    checklistConductor.push({
      n3: selectedChecklists?.CheckListConductor.Segunda,
    });
    checklistConductor.push({
      n4: selectedChecklists?.CheckListConductor.Tercera,
    });
  }, [selectedChecklists]);

  const handleDeleteThis = () => {
    if (checklistId) {
      setOpenDelete(true);
    }
  };

  const handleDeleteCheckList = () => {
    if (checklistId) dispatch(deleteCheckLists(checklistId));
    setOpenDelete(false);
  };

  useEffect(() => {
    if (deleteStatus?.state === FormState.Success) {
      history.push("/ListaDeChequeo");
    }
  }, [deleteStatus?.state]);

  return (
    <>
      <Card mb={2}>
        <CardContent>
          <CardHeader
            action={
              <>
                <Button
                  startIcon={<Plus />}
                  style={{
                    backgroundColor: "#42529b",
                    color: " #fff",
                    marginInlineEnd: 20,
                  }}
                  variant="contained"
                  onClick={() => {
                    exportToCsv(
                      "checklist.csv",

                      [
                        selectedChecklists,
                        ...condicionesGenerales,
                        ...condicionesVehiculo,
                        ...checklistConductor,
                      ],
                      [
                        "NombreConductor",
                        "Vehiculo",
                        "Patente",
                        "ParaUsoEn",
                        "Modelo",
                        "Marca",
                        "Kilometraje",
                        "GeneralesVehiculo",
                        "ClaseLicencia",
                        "CargaCombustible",
                        "Pregunta1",
                        "Pregunta2",
                        "Pregunta3",
                        "Pregunta4",
                        "Pregunta5",
                        "Pregunta6",
                        "Pregunta7",
                        "Pregunta8",
                        "Pregunta9",
                        "Pregunta10",
                        "Pregunta11",
                        "Pregunta12",
                        "Pregunta13",
                        "Pregunta14",
                        "Pregunta15",
                        "Pregunta16",
                        "Pregunta17",
                        "Pregunta18",
                        "pregunta1",
                        "pregunta2",
                        "pregunta3",
                        "pregunta4",
                        "pregunta5",
                        "pregunta6",
                        "pregunta7",
                        "pregunta8",
                        "pregunta9",
                        "pregunta10",
                        "pregunta11",
                        "pregunta12",
                        "pregunta13",
                        "pregunta14",
                        "pregunta15",
                        "pregunta16",
                        "pregunta17",
                        "pregunta18",
                        "n1",
                        "n2",
                        "n3",
                        "n4",
                      ],
                      [
                        "Nombre conductor",
                        "Vehiculo",
                        "Patente",
                        "Para uso en",
                        "Modelo",
                        "Marca",
                        "Kilometraje",
                        "Generales Vehiculo",
                        "Clase Licencia",
                        "Carga Combustible",
                        "Premiso de circulacion vigente",
                        "Certificado de revisión técnica vigente",
                        "Certificado emisión de gases vigente",
                        "Seguro automotriz obligatorio vigente",
                        "Extintor en norma y operativo",
                        "Llave de rueda",
                        "Gata completa",
                        "Neumático de repuesto",
                        "Pértiga / Baliza",
                        "Mantención al dia",
                        "Barra antivuelco Con Certificado",
                        "Conjunto cinturones de seguridad",
                        "Botiquín completo según EO",
                        "3 Triángulos o Conos",
                        "Bocina operativa",
                        "Radio (AM/FM)",
                        "Antena FM",
                        "Marcador de combustible y temperatura",
                        // condiciones vehiculo
                        " Nivel de aceite de motor (Según Marcador)",
                        " Nivel agua del radiador",
                        " Nivel líquido para freno",
                        " Estado de neumático delantero derecho +3mm",
                        " Estado de neumático delantero izquierdo +3mm",
                        " Estado neumático trasero derecho +3mm",
                        " Estado neumático trasero izquierdo +3mm",
                        " Estado neumático de repuesto",
                        " Estado del sistema de luces (freno, viraje, etc.)",
                        " Freno de mano operativo",
                        " Conjunto de vidrios",
                        " Estado de frenos",
                        " Estado de los alza vidrios",
                        " Estado de sistema limpia vidrios y dispersador",
                        " Chequeo ajuste/traba tuercas neumáticos",
                        " Condiciones de limpieza y aseo del vehículo",
                        " Sistema de Dirección y amortiguación",
                        " Sistema de amarre adecuado",
                        "¿Usted a descansado adecuadamente?",
                        "¿Ud. ha consumido alcohol o drogas en horas previas al uso de este vehículo?",
                        "¿Ud. ha consumido algún medicamento que induzca al sueño? (nastizol, calmantes, relajantes, otro)?",
                        "¿Se encuentra en condiciones físicas y mentales apropiadas para el uso de este vehículo?",
                      ]
                    );
                  }}
                >
                  Exportar CSV
                </Button>
                <Tooltip title="Eliminar">
                  {/* <IconButton
                    size="small"
                    
                    onClick={() => {}}
                  >
                    <Trash color="red" />
                  </IconButton> */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Trash color="red" />}
                    onClick={handleDeleteThis}
                  >
                    Eliminar
                  </Button>
                </Tooltip>
              </>
            }
          />
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: "#f4f5f5f8" }}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid xs={12} md={6} item>
              <b>Nombre del conductor:</b>{" "}
              {selectedChecklists?.NombreConductor
                ? selectedChecklists.NombreConductor
                : "No hay"}
            </Grid>
            <Grid xs={12} md={3} item>
              <b>Licencia:</b>{" "}
              {selectedChecklists?.ClaseLicencia
                ? selectedChecklists.ClaseLicencia
                : "No hay"}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Vehiculo:</b>{" "}
              {selectedChecklists?.Vehiculo
                ? selectedChecklists.Vehiculo
                : "No hay"}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Modelo:</b>{" "}
              {selectedChecklists?.Modelo
                ? selectedChecklists.Modelo
                : "No hay"}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Marca:</b>{" "}
              {selectedChecklists?.Marca ? selectedChecklists.Marca : "No hay"}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Patente:</b>{" "}
              {selectedChecklists?.Patente
                ? selectedChecklists.Patente
                : "No hay"}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Fecha:</b>{" "}
              {timesStampFormattedsimple(
                selectedChecklists?.FechaCreacion.toDate()
              )}
            </Grid>
            <Grid xs={12} md={4} item>
              <b>Kilometraje:</b> {selectedChecklists?.Kilometraje}
            </Grid>
            <Grid xs={12} md={6} item>
              <b>Para uso en:</b> {selectedChecklists?.ParaUsoEn}
            </Grid>
            <Grid xs={12} md={6} item>
              <b>Carga Combustible:</b> {selectedChecklists?.CargaCombustible} %
            </Grid>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <b>Comentarios:</b>
              </Grid>
              <Grid item xs={12} sm={12}>
                {selectedChecklists?.Comentarios || ""}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: "#f4f5f5f8" }} mt={4}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">Checklist Conductor</Typography>
              <Typography
                style={{ marginTop: 8, color: "gray", fontWeight: "bold" }}
                variant="h6"
              >
                {" "}
                Antes de completar esta lista de chequeo, responda sinceramente
                las siguientes preguntas:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              ¿Usted a descansado adecuadamente?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                --{" "}
                {selectedChecklists?.CheckListConductor?.Primera === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              ¿Ud. ha consumido alcohol o drogas en horas previas al uso de este
              vehículo?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                --{" "}
                {selectedChecklists?.CheckListConductor?.Segunda === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              ¿Ud. ha consumido algún medicamento que induzca al sueño?
              (nastizol, calmantes, relajantes, otro)
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                --{" "}
                {selectedChecklists?.CheckListConductor?.Tercera === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              ¿Se encuentra en condiciones físicas y mentales apropiadas para el
              uso de este vehículo?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                --{" "}
                {selectedChecklists?.CheckListConductor?.Cuarta === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: "#f4f5f5f8" }} mt={6}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Condiciones Generales</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              1.- Permiso de circulacion vigente:
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[0] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              2.- Certificado de revisión técnica vigente
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[1] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              3.- Certificado emisión de gases vigente:
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[2] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              4.- Seguro automotriz obligatorio vigente
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[3] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              5.- Extintor en Norma y operativo
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[4] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              6.- Llave de rueda
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[5] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              7.- Gata completa
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[6] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              8.- Neumático de repuesto
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[7] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* 9.- Pértiga / Baliza */}
              9.- Radio y antena VHF
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[8] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              10.- Mantención al dia
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[9] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              11.- Barra antivuelco Con Certificado
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[10] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              12.- Conjunto cinturones de seguridad
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[11] === true
                  ? "Si"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              13.- Botiquín completo según EO
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[12] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              14.- 3 Triángulos o Conos
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[13] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              15.- Bocina operativa
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[14] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              16.- Radio (AM/FM)
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[15] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              17.- Antena FM
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[16] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              18.- Marcador de combustible y temperatura
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesGenerales[17] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Condiciones del Vehiculo</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              1.- Nivel de aceite de motor (Según Marcador)
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[0] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              2.- Nivel agua del radiador
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[1] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              3.- Nivel líquido para frenos
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[2] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              4.- Estado de neumático delantero derecho +3mm
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[3] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              5.- Estado de neumático delantero izquierdo +3mm
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[4] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              6.- Estado neumático trasero derecho +3mm
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[5] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              7.- Estado neumático trasero izquierdo +3mm
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[6] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              8.- Estado neumático de repuesto
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[7] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              9.- Estado del Sistema de luces (freno, viraje, etc.)
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[8] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              10.- Freno de manos operativo
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[9] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              11.- Conjunto de vidrios
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[10] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              12.- Estado de frenos
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[11] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              13.- Estado de los alza vidrios
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[12] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              14.- Estado de Sistema limpia vidrios y dispersador
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[13] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              15.- Chequeo ajuste/traba tuercas neumáticos
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[14] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              16.- Condiciones de limpieza y aseo del vehículo
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[15] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              17.- Sistema de Dirección y amortiguación
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[16] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              18.- Sistema de amarre adecuado
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography>
                --{" "}
                {selectedChecklists?.CondicionesVehiculo[17] === true
                  ? "SI"
                  : "NO"}
              </Typography>
            </Grid>
          </Grid>
          <Typography style={{ marginTop: 8 }} variant="h5">
            Generales y del vehiculo
          </Typography>
          <br />
          <Typography>
            Normal :{" "}
            {selectedChecklists?.GeneralesYVehiculo === true ? "Si" : ""}{" "}
          </Typography>
          <Typography>
            Irregular :
            {selectedChecklists?.GeneralesYVehiculo === false ? "Si" : ""}{" "}
          </Typography>
        </CardContent>
      </Card>
      <Card mt={3}>
        <CardContent>
          <Grid container spacing={3}>
            {selectedChecklists?.Estado === "Pendiente de Cierre" ||
            selectedChecklists?.Estado === "Cerrado" ? (
              <Grid item xs={12} sm={6}>
                <Alert severity="info" icon={false}>
                  <b>Tipo de Usuario</b>: {user?.tipoUsuario}
                  <br />
                  <b>Usuario</b>:{" "}
                  {selectedChecklists?.Revision?.UsuarioNombreCompleto}
                  <br />
                  <b>Fecha</b>:{" "}
                  {timesStampFormattedsimple(
                    selectedChecklists?.Revision?.FechaRevision?.toDate()
                  )}
                </Alert>
                <br />
                <Alert icon={false} severity="success">
                  <b>Revisión y Recomendación (Area de Jefatura)</b>:<br />
                  <br /> {selectedChecklists?.Revision?.Comentario}
                </Alert>
                <br />
              </Grid>
            ) : user?.tipoUsuario === "Jefe" ||
              (user?.tipoUsuario === "Admin" &&
                selectedChecklists?.Estado === "Por Evaluar") ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-textarea"
                  placeholder="Revisión y Recomendación"
                  label="Revisión y Recomendación"
                  fullWidth
                  rows="8"
                  value={inputRevision}
                  onChange={handleInputRevision}
                  multiline
                  variant="outlined"
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 5 }}
                  onClick={() => {
                    dispatch(
                      updateComent(
                        { id: checklistId },
                        inputRevision,
                        estadoRevision
                      )
                    );
                    dispatch(getOneChecklist(checklistId));
                  }}
                >
                  Guardar
                </Button>
              </Grid>
            ) : (
              ""
            )}
            {selectedChecklists?.Estado === "Cerrado" ? (
              <Grid item xs={12} sm={6}>
                <Alert icon={false} severity="info">
                  <b>Tipo de Usuario</b>: {user?.tipoUsuario}
                  <br />
                  <b>Usuario</b>:{" "}
                  {selectedChecklists?.Cierre?.UsuarioNombreCompleto}
                  <br />
                  <b>Fecha</b>:{" "}
                  {timesStampFormattedsimple(
                    selectedChecklists?.Cierre?.FechaCierre?.toDate()
                  )}
                </Alert>
                <br />
                <Alert icon={false} severity="success">
                  <b>Revisión y Recomendación (Area De Prevención)</b>:<br />
                  <br /> {selectedChecklists?.Cierre?.ComentarioCierre}
                </Alert>
                <br />
              </Grid>
            ) : selectedChecklists?.Revision?.Comentario !== "" &&
              user?.tipoUsuario === "Admin" &&
              selectedChecklists?.Estado === "Pendiente de Cierre" ? (
              <Grid item xs={12} sm={5}>
                <TextField
                  id="outlined-textarea"
                  placeholder="Revisión y Recomendación"
                  label="Revisión y Recomendación"
                  fullWidth
                  rows="8"
                  value={inputCierre}
                  onChange={handleInputCierre}
                  multiline
                  variant="outlined"
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 5 }}
                  onClick={() => {
                    dispatch(
                      updateComentCierre(
                        { id: checklistId },
                        inputCierre,
                        estadoCierre
                      )
                    );
                    dispatch(getOneChecklist(checklistId));
                  }}
                >
                  Finalizar revision.
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </CardContent>
      </Card>
      <NewConfirmDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        title="¿Desea eliminar la Checklist?"
        onConfirm={handleDeleteCheckList}
        onConfirmText={"Eliminar"}
      />
    </>
  );
};

export const CheckListView = () => (
  <React.Fragment>
    <Helmet title="Detalle Checklist" />
    <Typography variant="h2" gutterBottom display="inline">
      Lista de Chequeo Vehiculo Liviano
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} exact to="/listaDeChequeo">
        Lista de Chequeo
      </Link>
      <Typography>Detalle Lista de Chequeo</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
