import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip as MuiChip,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import {
  Download,
  Edit,
  MinusSquare,
  Plus,
  Search,
  Trash,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { NewEditCharge } from "../components/NewEditCharge";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { filterType } from "../redux/actions/businessActions";
import {
  addCargos,
  deleteCargo,
  editCargo,
  getCargos,
  getCargosFiltered,
} from "../redux/actions/positionsChargesActions";
import { PositionChargesState } from "../redux/reducers/positionChargesReducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip: any = styled(MuiChip)`
  ${spacing};

  background: ${(props: any) => props.activated && green[500]};
  background: ${(props: any) => props.desactivated && red[500]};
  color: ${(props) => props.theme.palette.common.white};
  min-width: 90px;
`;

interface filterProps {
  onFilter: (value: filterType) => void;
}

const ContentCard = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [filtros, setFiltros] = useState<filterType>([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [openNewEdit, setOpenNewEdit] = useState(false);
  const [typeDialog, setTypeDialog] = useState("new");
  const [subjectData, setSubjectData] = useState<any | {}>({ id: "" });

  const handleNewEditAccept = (values: any) => {
    if (typeDialog === "new") {
      dispatch(addCargos(values));
    } else if (typeDialog === "edit") {
      dispatch(editCargo(values, subjectData["id"]));
    }
    setOpenNewEdit(false);
  };

  const handleDeleteAccept = () => {
    dispatch(deleteCargo(subjectData["id"]));
    setOpenDelete(false);
  };

  const { limit, page, handleLimitChange, handlePageChange, setPage } =
    useTable({
      limit: TABLE_LIMIT_DEFAULT,
      onLimitChange: (e, newLimit) => {
        if (newLimit > Cargos.length) {
          dispatch(getCargosFiltered(filtros, newLimit));
        }
      },
      onPageChange: (newPage, oldPage, limit) => {
        if (Cargos.length < totalDocs && newPage > oldPage) {
          // dispatch(getMoreCargos(limit));
        }
      },
    });

  const { Cargos, totalDocs } = useSelector<RootState, PositionChargesState>(
    (state) => state.positionChargesReducer
  );

  useEffect(() => {
    if (setPage && page) setPage(0);
    dispatch(getCargosFiltered(filtros, limit));
  }, [filtros]);

  const handleFilter = (filter: filterType) => {
    if (setPage && page) setPage(0);
    setFiltros(filter);
  };

  const handleExport = () => {
    exportToCsv(
      "cargos.csv",
      Cargos,
      [
        "Nombre",
        "Activado",
        "Descripcion",
        "FechaCreacion",
        "FechaModificacion",
      ],
      [
        "Nombre",
        "Activado",
        "Descripcion",
        "Fecha Creacion",
        "Fecha Modificacion",
      ]
    );
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#42529b",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={handleExport}
            >
              Exportar CSV
            </Button>
            <Button
              startIcon={<Plus />}
              style={{ backgroundColor: "#42529b", color: "#fff" }}
              variant="contained"
              onClick={() => {
                setOpenNewEdit(true);
                setTypeDialog("new");
                setSubjectData({});
              }}
            >
              Nuevo Cargo
            </Button>
          </>
        }
      />
      <Card mb={6}>
        <FilterSection onFilter={handleFilter} />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Fecha de Creación</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Cargos ? (
                  Cargos.slice(page * limit, page * limit + limit).map(
                    (data) => (
                      <Fade key={data?.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>{data?.Nombre}</TableCell>
                          <TableCell>
                            {data?.FechaCreacion
                              ? timesStampFormattedsimple(
                                  data?.FechaCreacion.toDate()
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            {data?.Activado ? (
                              <Chip
                                size="small"
                                label="Activado"
                                activated="true"
                              />
                            ) : (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Desactivado"
                                desactivated="true"
                              />
                            )}
                          </TableCell>
                          <TableCell>{data?.Descripcion}</TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setOpenNewEdit(true);
                                    setTypeDialog("edit");
                                    setSubjectData(data);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSubjectData(data);
                                    setOpenDelete(true);
                                  }}
                                >
                                  <Trash color="red" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    )
                  )
                ) : (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
        <NewConfirmDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          title="¿Desea eliminar el Cargo?"
          onConfirm={handleDeleteAccept}
          onConfirmText={"Eliminar"}
        />
      </Card>
      <NewEditCharge
        type={typeDialog}
        data={subjectData}
        open={openNewEdit}
        onClose={() => setOpenNewEdit(false)}
        onAccept={(values) => handleNewEditAccept(values)}
      />
    </>
  );
};

const FilterSection: React.FC<filterProps> = ({ onFilter }) => {
  // const [openFilter, setOpenFilters] = useState(false);
  //
  const [nombre, setNombre] = useState("");

  const handleChangeNombre = (e: any) => {
    setNombre(e.target.value);
  };

  const handleFilter = () => {
    const filter: filterType = [];

    if (nombre)
      filter.push({
        field: "Nombre_lower",
        operator: "==",
        value: cleanString(nombre),
      });

    onFilter(filter);
  };

  const handleClean = () => {
    setNombre("");
    onFilter([]);
  };

  return (
    <CardContent>
      {/* <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid> */}
      {/* <Collapse in={openFilter} timeout="auto" unmountOnExit> */}
      <Grid container spacing={1} style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="outlined-basic"
            size="small"
            label="Nombre"
            variant="outlined"
            fullWidth
            autoComplete="off"
            value={nombre}
            onChange={handleChangeNombre}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleFilter();
                ev.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Box display="flex">
            <Box mt={1} order={1}>
              <Tooltip title="Buscar">
                <IconButton
                  size="small"
                  aria-label="Filtrar"
                  onClick={handleFilter}
                >
                  <Search />
                </IconButton>
              </Tooltip>
            </Box>
            <Box mt={1} order={2}>
              <Tooltip title="Limpiar">
                <IconButton
                  size="small"
                  aria-label="Borrar filtro"
                  onClick={handleClean}
                >
                  <MinusSquare />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Collapse> */}
    </CardContent>
  );
};

export const Cargos = () => (
  <React.Fragment>
    <Helmet title="Cargos" />
    <Typography variant="h3" gutterBottom display="inline">
      Cargos
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Listado</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
