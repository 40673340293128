import * as types from "../../constants";
import { Action } from "../../models/action";

export interface InformationState {
  basicInfo: {
    Nombre: string;
    Apellido: string;
    Area: string;
    InduccionCompleta: boolean;
    Aprobado: boolean;
    Avatar?: any;
  } | null;
  documentsInfo: any;
}

const initialState = {
  basicInfo: null,
  documentsInfo: null,
} as InformationState;

export const informationReducer = (
  state = initialState,
  action: Action
): InformationState => {
  switch (action.type) {
    case types.SET_INFO_USER_VIEW:
      return {
        ...state,
        basicInfo: action.payload.basicInfo,
        documentsInfo: action.payload.documentsInfo,
      };

    default:
      return state;
  }
};
