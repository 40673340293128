// import firebase from "firebase";
import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString, exportToCsv } from "../../utils/utils";
import { filterType, sortQueries } from "./businessActions";
import { openSnack } from "./uiActions";

const collectionNames = {
  bsns: "RegistroActividadesBSNS",
  aclara: "RegistroActividades",
};

const collName = (user: any) => {
  if (user?.business) return collectionNames["bsns"];
  return collectionNames["aclara"];
};

export const exportActividades = (filter: filterType = []): AppThunk => {
  return async (dispatch, getState) => {
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaActividad", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).get();

      const respActividades: any[] = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      const datosActividades = respActividades.map((e) => ({
        Relatores: String(e.Relatores.map((item: any) => item.Nombre)),
        Participantes: String(e.Participantes.map((item: any) => item.Nombre)),
        Comentarios: JSON.stringify(
          e?.Comentarios?.map((item: any) => ({
            Comentario: item.Comentario,
            Usuario: item.NombreUsuario,
          }))
        ),
        Area: e.Area,
        Competencia: e.Competencia,
        TipoDeActividad: e.TipoDeActividad,
        Empresa: e.Empresa,
        Fecha: e.Fecha,
        Lugar: e.Lugar,
        NombreActividad: e.NombreActividad,
        Observaciones: e.Observaciones,
        Relator: e.Relator,
        Tema: e.Tema,
      }));

      exportToCsv(
        "actividades.csv",
        datosActividades,
        [
          "Area",
          "Competencia",
          "TipoDeActividad",
          "Empresa",
          "Fecha",
          "Lugar",
          "NombreActividad",
          "Observaciones",
          "Relator",
          "Tema",
          "Relatores",
          "Participantes",
          "Comentarios",
        ],
        [
          "Area",
          "Competencia",
          "Tipo de actividad",
          "Empresa",
          "Fecha",
          "Lugar",
          "Nombre actividad",
          "Observaciones",
          "Relator",
          "Tema",
          "Relatores",
          "Participantes",
          "Comentarios",
        ]
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getActividades = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ACTIVIDADES_GET_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaActividad", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter).limit(limit).get();
      const response2 = await sortQueries(query, filter).get();

      const respActividades = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.ACTIVIDADES_GET_SUCCESS,
        payload: {
          actividades: respActividades,
          totalDocs: response2.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: types.ACTIVIDADES_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las actividades",
      });
    }
  };
};

export const getOneActividad = (id: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ACTIVIDADES_GET_ONE_IS_SUBMMITING,
    });
    const { user } = getState().authReducer;

    try {
      const response = await firestore.collection(collName(user)).doc(id).get();

      dispatch({
        type: types.ACTIVIDADES_GET_ONE_SUCCES,
        payload: response.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.ACTIVIDADES_GET_ONE_IS_FAILURE,
        payload: "Ha ocurrido un error obteniendo la actividad",
      });
    }
  };
};

export const getMoreActividades = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  filter: filterType = []
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ACTIVIDADES_GET_SUBMMITING,
    });

    // const lastDoc = getState().actividadReducer.lastDoc || "";
    const { totalDocs, actividades, lastDoc } = getState().actividadReducer;
    const { user } = getState().authReducer;

    try {
      let query = firestore
        .collection(collName(user))
        .orderBy("FechaActividad", "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      const response = await sortQueries(query, filter)
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestaActividades = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ACTIVIDADES_GET_SUCCESS,
        payload: {
          actividades: actividades.concat(respuestaActividades),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.ACTIVIDADES_GET_FAILURE,
        payload: "Ha ocurrido un error obteniendo listado de actividades",
      });
    }
  };
};

export const addComent = (comentario: string, id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ACTIVIDADES_ADD_COMENT_IS_SUBMMITING,
    });
    const { user } = getState().authReducer;
    try {
      await firestore
        .collection(collName(user))
        .doc(id)
        .update({
          Comentarios: firebase.firestore.FieldValue.arrayUnion({
            Comentario: comentario,
            TipoUsuario: user.tipoUsuario,
            NombreUsuario: user.name,
            FirebaseId: user.id,
          }),
        });
      dispatch(
        openSnack("Comentario agregado exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.ACTIVIDADES_ADD_COMENT_IS_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
};

export const getActividadesFiltered = (
  filtro: any = {},
  order: string = "FechaCreacion",
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const { user } = getState().authReducer;
    try {
      let query = firestore.collection(collName(user)).orderBy(order, "desc");

      if (user?.business) {
        query = query.where("Empresa", "==", user.business);
      }

      Object.keys(filtro).forEach((key) => {
        let value = filtro[key];
        if (key === "endAt") {
          const miliDate = Date.parse(value);
          query = query.startAt(new Date(miliDate));
        } else if (key === "startAt") {
          const miliDate = Date.parse(value);
          query = query.endAt(new Date(miliDate));
        } else {
          query = query.where(key, "==", value);
        }
      });

      const response = await query.get();
      const actividadesList = response.docs.map((x: any) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.ACTIVIDADES_GET_SUCCESS,
        payload: {
          actividades: actividadesList,
          totalDocs: response.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};

export const setSelectedActividad = (actividad: any): Action => ({
  type: types.ACTIVIDADES_SET_SELECTED,
  payload: actividad,
});
const isLoading = (isloading: boolean): Action => ({
  type: types.ACTIVIDADES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.ACTIVIDADES_FAILURE,
  payload: error,
});
